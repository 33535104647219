import React from "react";
import { connect } from "react-redux";
import PageTitle from "components/common/PageTitle";
import Feed from "components/widgets/feed/Feed";
import IntlMessages from "util/intlMessages";
import DefaultTable from "components/widgets/defaultTable/DefaultTable";

const Dashboard = (props) => {
  return (
    <div className="page-padding">
      <PageTitle title="dashboard.title" />

      <div className="plr-15">
        <div className="row">
          <div className="col-md-8 mtb-10">
            <div className="row">
              <div className="col-12 mtb-10">
                <DefaultTable
                  id="nodes"
                  actions={<button className="c-btn c-primary" onClick={() => props.history.push('/blockchains')}>
                    <IntlMessages id="dashboard.my_networks" />
                  </button>}
                  title={<IntlMessages id="dashboard.my_networks" />}
                  data={{
                    headerFields: [],
                    rowData: [],
                  }}
                  emptyWarn={<div className="text-center grey--text pt-4 pb-4">
                    <h1><IntlMessages id="dashboard.sell_text1" /></h1>
                    <p><IntlMessages id="dashboard.sell_text2" /></p>
                    <p><IntlMessages id="dashboard.sell_text3" values={{
                      community: <a href="/community"><IntlMessages id="dashboard.sell_here" /></a>,
                      support: <a href="/support"><IntlMessages id="dashboard.sell_support" /></a>,
                    }} /></p>
                  </div>} />
              </div>
            </div>

            <div className="row">
              <div className="col-12 mtb-10">
                <DefaultTable
                  id="web3"
                  title={<IntlMessages id="dashboard.my_contracts" />}
                  actions={<button className="c-btn c-primary" onClick={() => props.history.push('/ide')}>
                    <IntlMessages id="dashboard.sell_text5" />
                  </button>}
                  data={{
                    headerFields: [],
                    rowData: [],
                  }}
                  emptyWarn={<div className="text-center grey--text pt-4 pb-4">
                    <h1><IntlMessages id="dashboard.sell_text4" /></h1>
                    <p><IntlMessages id="dashboard.sell_text6" /></p>
                    <p><IntlMessages id="dashboard.sell_text7" values={{
                      community: <a href="/community"><IntlMessages id="dashboard.sell_here" /></a>,
                      ide: <a href="/ide"><IntlMessages id="dashboard.sell_here" /></a>,
                    }} /></p>
                  </div>} />
              </div>
            </div>

            <div className="row">
              <div className="col-12 mtb-10">
                <DefaultTable
                  id="traceability"
                  title={<IntlMessages id="dashboard.traceability" />}
                  actions={<button className="c-btn c-primary" onClick={() => props.history.push('/traceability')}>
                    <IntlMessages id="dashboard.sell_text8" />
                  </button>}
                  data={{
                    headerFields: [],
                    rowData: [],
                  }}
                  emptyWarn={<div className="text-center grey--text pt-4 pb-4">
                    <h1><IntlMessages id="dashboard.sell_text9" /></h1>
                    <p><IntlMessages id="dashboard.sell_text10" /></p>
                    <p><IntlMessages id="dashboard.sell_text11" /></p>
                    <p><IntlMessages id="dashboard.sell_text12" /></p>
                    <p><IntlMessages id="dashboard.sell_text3" values={{
                      community: <a href="/community"><IntlMessages id="dashboard.sell_here" /></a>,
                      support: <a href="/support"><IntlMessages id="dashboard.sell_support" /></a>,
                    }} /></p>
                  </div>} />
              </div>
            </div>

            <div className="row">
              <div className="col-12 mtb-10">
                <DefaultTable
                  id="tokens"
                  title="Tokens"
                  actions={<button className="c-btn c-primary" onClick={() => props.history.push('/tokens')}>
                    <IntlMessages id="dashboard.sell_text14" />
                  </button>}
                  data={{
                    headerFields: [],
                    rowData: [],
                  }}
                  emptyWarn={<div className="text-center grey--text pt-4 pb-4">
                    <h1><IntlMessages id="dashboard.sell_text15" /></h1>
                    <p><IntlMessages id="dashboard.sell_text16" /></p>
                    <p><IntlMessages id="dashboard.sell_text17" /></p>
                    <p><IntlMessages id="dashboard.sell_text3" values={{
                      community: <a href="/community"><IntlMessages id="dashboard.sell_here" /></a>,
                      support: <a href="/support"><IntlMessages id="dashboard.sell_support" /></a>,
                    }} /></p>
                  </div>} />
              </div>
            </div>
          </div>
          <div className="col-md-4 mtb-10">
            <div>
              <Feed />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default connect(mapStateToProps, null)(Dashboard);
