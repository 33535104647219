import React, { useState, useRef } from "react";
import PageTitle from "components/common/PageTitle";
import IntlMessages from "util/intlMessages";
import { BywiseHelper, TxType } from "@bywise/web3";
import Session from "util/Session";
import { useLocation } from "react-router-dom";
import BigNumber from "bignumber.js";
import PublishTransaction from "components/transaction/PublishTransaction";
import { toast } from "react-toastify";

const buttonBack = {
  backgroundColor: '#563c91',
  color: 'white'
};

const Send = (props) => {
  const [address, setAddress] = useState("");
  const [amount, setAmount] = useState("");
  const [error, setError] = useState({});
  const publishTransaction = useRef(null);

  const { search } = useLocation();
  const parans = new URLSearchParams(search);
  let tokenSymbol = 'BWS';
  let contractAddress = '';
  let decimals = 0;
  if (parans.get('symbol')) {
    tokenSymbol = parans.get('symbol');
  }
  if (parans.get('address')) {
    contractAddress = parans.get('address');
  }
  if (parans.get('decimals')) {
    decimals = parseInt(parans.get('decimals'));
  }

  const isValid = () => {
    if (!BywiseHelper.isValidAddress(address)) {
      setError({
        address: <IntlMessages id="invalid_address" />
      });
      return false;
    }
    if (!BywiseHelper.isValidAmount(amount)) {
      setError({
        amount: <IntlMessages id="invalid_amount" />
      });
      return false;
    }
    setError({});
    return true;
  };

  const handleSend = async (e) => {
    e.preventDefault();
    if (isValid()) {

      const web3 = await Session.getWeb3();
      const wallet = Session.getWallet();
      const infoChain = Session.getInfoChain();

      const hasMarket = false;
      const market = 'BWS1MCc793dE4370a72c80C6dfF384cb52125C86B96632a7c';

      try {
        let tx;
        if (contractAddress) {
          const weiAmount = new BigNumber(amount).multipliedBy(new BigNumber(10 ** decimals)).toFixed(0);
          if (hasMarket) {
            tx = web3.transactions.buildSimpleTx(
              wallet,
              infoChain.chain,
              [contractAddress, market],
              ['0', '0'],
              TxType.TX_CONTRACT_EXE,
              [
                {
                  method: 'approve',
                  inputs: [market, weiAmount],
                },
                {
                  method: 'transfer',
                  inputs: [contractAddress, address, weiAmount],
                },
              ]
            )
          } else {
            tx = await web3.transactions.buildSimpleTx(wallet, infoChain.chain, contractAddress, '0', TxType.TX_CONTRACT_EXE, [
              {
                method: 'transfer',
                inputs: [address, weiAmount],
              }
            ]);
          }
        } else {
          tx = await web3.transactions.buildSimpleTx(wallet, infoChain.chain, address, amount, TxType.TX_NONE);
        }
        publishTransaction.current.publish(tx, {
          type: 'transfer',
          amount: amount,
          symbol: tokenSymbol,
          to: address,
        }, ((tx, output) => {
          props.history.goBack();
        }))
      } catch (err) {
        toast.error(err.message);
      }
    }
  }

  const Error = props => {
    const field1 = props.field;
    if (error[field1]) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {error[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  return (
    <div className="page-padding">
      <PageTitle title="send"
        breadCrumb={[
          {
            name: "wallet.title"
          },
          {
            name: "send"
          }
        ]} />

      <div className="plr-15">
        <div className="roe-card-style">
          <div className="roe-card-header">
            <span className="hash"># </span>
            <span><IntlMessages id="send" /> {tokenSymbol}</span>
          </div>
          <div className="roe-card-body">
            <form>
              <div className="form-group row">
                <label className="col-sm-4 col-form-label">
                  <IntlMessages id="address" />
                </label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    className="form-control react-form-input"
                    value={address}
                    onChange={e => setAddress(e.target.value)}
                  />
                  <Error field="address" />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-4 col-form-label">
                  <IntlMessages id="amount" />
                </label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    className="form-control react-form-input"
                    value={amount}
                    onChange={e => setAmount(e.target.value)}
                  />
                  <Error field="amount" />
                </div>
              </div>

              <button
                style={buttonBack}
                type="submit"
                className="btn form-button"
                onClick={handleSend}
              >
                <IntlMessages id="send" />
              </button>
            </form>
          </div>
        </div>
      </div>
      <PublishTransaction ref={publishTransaction} />
    </div>
  );
}

export default Send;
