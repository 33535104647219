import React from "react";
import { connect } from "react-redux";
import FormNew from "components/forms/FormNew";
import Api from "api/api";
import { toast } from "react-toastify";

const NewProject = (props) => {
  const service = 'project.title';

  const form = {
    title: 'project.new',
    description: '',
    saveBTN: async (inputsForm) => {
      const req = await Api.post(`projects`, {
        name: inputsForm.name,
        description: inputsForm.description,
      });
      if (!req.error) {
        props.history.push('/projects')
        toast.success(`Success`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    },
    form: [
      {
        title: 'project.name',
        description: '',
        validation: (value = '') => value.trim().length === 0 ? 'invalid_field' : '',
        type: 'text',
        id: 'name'
      },
      {
        title: 'project.description',
        description: '',
        validation: (value = '') => value.trim().length === 0 ? 'invalid_field' : '',
        type: 'textarea',
        id: 'description'
      },
    ]
  }

  return (<FormNew service={service} form={form} />);
}

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default connect(mapStateToProps, null)(NewProject);
