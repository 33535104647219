import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  Row,
  Col,
  Table,
  Spinner,
} from "reactstrap";
import classnames from "classnames";
import IntlMessages from "util/intlMessages";
import SweetAlert from 'react-bootstrap-sweetalert'
import Editor from "@monaco-editor/react";
import Session from "util/Session";
import enviroment from "../assets/samples/enviroment.json";
import { BywiseHelper, TxType } from "@bywise/web3";
import Contract from "components/common/Contract";
import Loading from "components/common/Loading";
import { withRouter } from "react-router-dom";

var simulateContext = enviroment;

const getBalances = () => {
  return simulateContext.wallets.map(addr => ({
    address: addr,
    balance: simulateContext.balances[addr]
  }))
}

const getContracts = () => {
  const constracts = [];
  Object.keys(simulateContext.contractAddress).forEach(addr => {
    constracts.push({
      address: addr,
      balance: simulateContext.balances[addr],
      abi: simulateContext.contractAddress[addr].abi
    })
  })
  return constracts;
}

const tabHeight = 43;

const IDE = (props) => {
  const [activeTab, setActiveTab] = useState("1");
  const [loading, setLoading] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [errorMessageAlert, setErrorMessageAlert] = useState(null);
  const [errorAlert, setErrorAlert] = useState('');
  const [successAlert, setSuccessAlert] = useState('');
  const [successSendAlert, setSuccessSendAlert] = useState('');
  const [importLibs, setImportLibs] = useState('');
  const [logsAlert, setLogsAlert] = useState(null);
  const [contract, setContract] = useState(null);
  const [address, setAddress] = useState(simulateContext.wallets[0]);
  const [wallets, setWallets] = useState(getBalances());
  const [contracts, setContracts] = useState(getContracts());
  const [amount, setAmount] = useState('0');
  const [height, setHeight] = useState(0);
  const ref = useRef(null);
  const [code, setCode] = useState(Session.getLastCode());

  const [contractDep, setContractDep] = useState(null);
  const [addressDep, setAddressDep] = useState('');
  const [deployList, setDeployList] = useState([]);

  const fetchLibs = useCallback(async () => {
    const req = await fetch('assets/bywise-imports.d.ts');
    const text = await req.text();
    setImportLibs(text);
  }, [])

  useEffect(() => {
    setHeight(ref.current.clientHeight)
    fetchLibs();
  }, [fetchLibs, height])

  const toggle = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const simulate = async () => {
    setLoadingBtn(true);
    if (!BywiseHelper.isValidAmount(amount)) {
      setErrorAlert('invalid amount');
      setLoading(false);
      return;
    }
    Session.setLastCode(code);
    const contractAddress = BywiseHelper.getBWSAddressContract();
    const web3 = await Session.getWeb3();
    const result = await web3.contracts.simulateContract({
      from: address,
      amount: parseInt(amount),
      tag: '',
      code: code,
      contractAddress: contractAddress,
      env: simulateContext
    })

    console.log(result)

    if (result.error) {
      if (result.stack) {
        let stack = <>
          <p>{result.stack.name}<br /></p>
          {typeof result.stack.stack === 'string' && result.stack.stack.split('\n').map((e, i) => <p key={`stack error ${i}`}>{e}<br /></p>)}
        </>;
        setErrorMessageAlert(stack);
      }
      setErrorAlert(result.error);
    } else {
      simulateContext = result.env;
      const contract = {
        balance: amount,
        address: contractAddress,
        abi: result.output.abi,
      }
      if (result.logs && result.logs.length > 0) {
        setLogsAlert(result.logs.map((e, i) => <p key={`log ${i}`}>{e}<br /></p>));
      }
      setContract(contract)
      setContracts(getContracts());
      setWallets(getBalances());
    }
    setLoadingBtn(false);
  }

  const send = async (contractAddress, name, ...values) => {
    const web3 = await Session.getWeb3();
    const result = await web3.contracts.simulateContract({
      from: address,
      contractAddress: contractAddress,
      amount: parseInt(amount),
      tag: '',
      method: name,
      inputs: values,
      env: simulateContext
    })

    console.log(result)

    if (result.logs && result.logs.length > 0) {
      setLogsAlert(result.logs.map((e, i) => <p key={`log ${i}`}>{e}<br /></p>));
    }
    if (result.error) {
      setErrorAlert(result.error);
    } else {
      simulateContext = result.env;
      if (result.output) {
        setSuccessAlert(JSON.stringify(result.output));
      }
      setContracts(getContracts());
      setWallets(getBalances());
    }
  }

  const read = async (contractAddress, name, ...values) => {
    const web3 = await Session.getWeb3();
    const result = await web3.contracts.simulateContract({
      from: address,
      contractAddress: contractAddress,
      amount: parseInt(amount),
      tag: '',
      method: name,
      inputs: values,
      env: simulateContext
    })

    console.log(result)

    if (result.logs && result.logs.length > 0) {
      setLogsAlert(result.logs.map((e, i) => <p key={`log ${i}`}>{e}<br /></p>));
    }
    if (result.error) {
      setErrorAlert(result.error);
    } else if (result.output) {
      setSuccessAlert(JSON.stringify(result.output));
    }
  }

  const deploy = async () => {
    setLoading(true);
    try {
      if (!BywiseHelper.isValidAmount(amount)) {
        setErrorAlert('invalid amount');
        setLoading(false);
        return;
      }

      Session.setLastCode(code);
      const web3 = await Session.getWeb3();
      const infoChain = await Session.getInfoChain();
      const wallet = await Session.getWallet();
      const contractAddress = BywiseHelper.getBWSAddressContract();

      const tx = await web3.transactions.buildSimpleTx(
        wallet,
        infoChain.chain,
        contractAddress,
        amount,
        TxType.TX_CONTRACT,
        {
          contractAddress: contractAddress,
          code: code
        }
      );
      const error = await web3.transactions.sendTransaction(tx);
      if (error) {
        setLoading(false);
        setErrorAlert(error);
        return;
      }
      const confirmedTx = await web3.transactions.waitConfirmation(tx.hash, 100000);
      if (confirmedTx.status !== 'mined' && confirmedTx.status !== 'confirmed') {
        setLoading(false);
        setErrorAlert(confirmedTx.output.error ? confirmedTx.output.error : 'transaction not confirmed');
        return;
      }
      const abi = confirmedTx.output.output.abi;

      const contract = {
        address: contractAddress,
        abi: abi,
      }
      if (confirmedTx.output.logs && confirmedTx.output.logs.length > 0) {
        setLogsAlert(confirmedTx.output.logs.map((e, i) => <p key={`log ${i}`}>{e}<br /></p>));
      }
      deployList.push(contractAddress);
      setDeployList(deployList);
      setContractDep(contract);
      setSuccessSendAlert(confirmedTx.hash);
    } catch (err) {
      setErrorAlert(err.message);
    }
    setLoading(false);
  }

  const importContract = async () => {
    setLoading(true);
    try {
      const web3 = await Session.getWeb3();
      const infoChain = await Session.getInfoChain();
      const contractOutput = await web3.contracts.getContractByAddress(infoChain.chain, addressDep);
      if (!contractOutput) {
        setErrorAlert('Contract not found');
        setLoading(false);
        return;
      }
      const contract = {
        address: contractOutput.output.contractAddress,
        abi: contractOutput.output.abi,
      }
      deployList.push(contractOutput.output.contractAddress);
      setDeployList(deployList);
      setContractDep(contract);
    } catch (err) {
      setErrorAlert(err.message);
    }
    setLoading(false);
  }

  const deploySend = async (contractAddress, name, ...values) => {
    setLoading(true);
    try {
      if (!BywiseHelper.isValidAmount(amount)) {
        setErrorAlert('invalid amount');
        setLoading(false);
        return;
      }
      const web3 = await Session.getWeb3();
      const infoChain = await Session.getInfoChain();
      const wallet = await Session.getWallet();

      const tx = await web3.transactions.buildSimpleTx(
        wallet,
        infoChain.chain,
        contractAddress,
        amount,
        TxType.TX_CONTRACT_EXE,
        [{
          method: name,
          inputs: values,
        }]
      );
      const error = await web3.transactions.sendTransaction(tx);
      console.log('error', error)
      if (error) {
        setErrorAlert(error);
        setLoading(false);
        return;
      }
      const confirmedTx = await web3.transactions.waitConfirmation(tx.hash, 100000);
      if (confirmedTx.status !== 'mined' && confirmedTx.status !== 'confirmed') {
        setErrorAlert(confirmedTx.output.error ? confirmedTx.output.error : 'transaction not confirmed');
        setLoading(false);
        return;
      }
      if (confirmedTx.output.logs && confirmedTx.output.logs.length > 0) {
        setLogsAlert(confirmedTx.output.logs.map((e, i) => <p key={`log ${i}`}>{e}<br /></p>));
      }
      setSuccessSendAlert(confirmedTx.hash);
    } catch (err) {
      setErrorAlert(err.message);
    }
    setLoading(false);
  }

  const deployRead = async (contractAddress, name, ...values) => {
    setLoading(true);
    try {
      if (!BywiseHelper.isValidAmount(amount)) {
        setErrorAlert('invalid amount');
        setLoading(false);
        return;
      }
      const web3 = await Session.getWeb3();
      const infoChain = await Session.getInfoChain();

      const output = await web3.contracts.readContract(
        infoChain.chain,
        contractAddress,
        name,
        values,
      );
      if (!output) {
        setErrorAlert('failed send');
        setLoading(false);
        return;
      }
      if (output.logs && output.logs.length > 0) {
        setLogsAlert(output.logs.map((e, i) => <p key={`log ${i}`}>{e}<br /></p>));
      }
      setSuccessAlert(JSON.stringify(output.output));
    } catch (err) {
      setErrorAlert(err.message);
    }
    setLoading(false);
  }

  return (
    <div ref={ref} style={{ height: '100%' }}>
      <SweetAlert
        show={logsAlert !== null}
        title="Log"
        onConfirm={() => setLogsAlert(null)}
        confirmBtnCssClass="sweet-alert-confirm-button"
      >{logsAlert}</SweetAlert>
      <SweetAlert
        show={successAlert !== ''}
        title=""
        onConfirm={() => setSuccessAlert('')}
        confirmBtnCssClass="sweet-alert-confirm-button"
      >{successAlert}</SweetAlert>
      <SweetAlert
        show={successSendAlert !== ''}
        title="Success"
        onConfirm={() => setSuccessSendAlert('')}
        confirmBtnCssClass="sweet-alert-confirm-button"
      ><strong>TxId: </strong><a href={`${Session.getInfoChain().explorer}/tx/${successSendAlert}`} target="_blank" rel="noopener noreferrer">{successSendAlert}</a></SweetAlert>
      <SweetAlert
        title={errorAlert}
        onConfirm={() => { setErrorAlert(''); setErrorMessageAlert('') }}
        show={errorAlert !== ''}
        confirmBtnCssClass="sweet-alert-confirm-button"
      >{errorMessageAlert}</SweetAlert>
      <Nav style={{ height: tabHeight - 1 }} tabs>
        <NavItem>
          <NavLink className={classnames({ active: activeTab === "1" }, "doc-title")} onClick={() => toggle("1")} >
            Editor
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className={classnames({ active: activeTab === "2" }, "doc-title")} onClick={() => toggle("2")} >
            Enviroment
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className={classnames({ active: activeTab === "3" }, "doc-title")} onClick={() => toggle("3")} >
            Deploy
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab} style={{ height: height - tabHeight }}>
        <TabPane tabId="1" style={{ height: height - tabHeight }}>
          {importLibs !== '' && <Editor
            height={height - tabHeight}
            theme="vs-dark"
            defaultLanguage="javascript"
            onChange={s => setCode(s)}
            defaultValue={code}
            onMount={(editor, monaco) => {
              const libUri = 'ts:filename/bywise.d.ts';
              monaco.languages.typescript.javascriptDefaults.addExtraLib(importLibs, libUri);
              monaco.editor.createModel(importLibs, 'typescript', monaco.Uri.parse(libUri));
            }}
          />}
        </TabPane>
        <TabPane tabId="2" className="page-padding">
          <Row>
            <Col sm>
              <Card body className="mb-3">
                <CardTitle>Compiler</CardTitle>
                <form>
                  <div className="form-group">
                    <label>
                      <IntlMessages id="address" />
                    </label>
                    <select
                      type="select"
                      className="form-control react-form-input"
                      value={address}
                      onChange={e => setAddress(e.target.value)}
                    >
                      {wallets.map(wallet => <option key={wallet.address}>{wallet.address}</option>)}
                    </select>
                  </div>
                  <div className="form-group">
                    <label>
                      <IntlMessages id="amount" />
                    </label>
                    <input
                      type="text"
                      className="form-control react-form-input"
                      value={amount}
                      onChange={e => setAmount(e.target.value)}
                    />
                  </div>
                </form>
                <Button className="c-primary mt-3" disabled={loadingBtn} onClick={simulate}>
                  {loadingBtn && <Spinner color="primary" />}
                  {!loadingBtn && 'Simulate'}
                </Button>
              </Card>
              <Card body className="mb-3">
                <CardTitle>Contracts</CardTitle>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>BWS</th>
                      <th>Address</th>
                    </tr>
                  </thead>
                  <tbody>
                    {contracts.map(contract => <tr key={contract.address} className="pointer" onClick={() => setContract(contract)}>
                      <td>{contract.balance}</td>
                      <td>{contract.address}</td>
                    </tr>)}
                  </tbody>
                </Table>
              </Card>
              <Card body className="mb-3">
                <CardTitle>Balances</CardTitle>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>BWS</th>
                      <th>Address</th>
                    </tr>
                  </thead>
                  <tbody>
                    {wallets.map(wallet => <tr key={wallet.address}>
                      <td>{wallet.balance}</td>
                      <td>{wallet.address}</td>
                    </tr>)}
                  </tbody>
                </Table>
              </Card>
            </Col>
            <Col sm>{contract && <Contract address={contract.address} abi={contract.abi} send={send} read={read} />}</Col>
          </Row>
        </TabPane>
        <TabPane tabId="3" className="page-padding">
          <Loading />
          <Row>
            <Col sm>
              <Card body className="mb-3">
                <form>
                  <div className="form-group">
                    <label>
                      <IntlMessages id="amount" />
                    </label>
                    <input
                      type="number"
                      className="form-control react-form-input"
                      value={amount}
                      onChange={e => setAmount(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <div className="pretty p-svg p-curve">
                      <input
                        type="checkbox"
                        checked={false}
                        onChange={() => props.history.push('/nodes')} />
                      <div className="state p-success">
                        {/* <!-- svg path --> */}
                        <svg className="svg svg-icon" viewBox="0 0 20 20">
                          <path
                            d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                            style={{ stroke: "white", fill: "white" }}
                          ></path>
                        </svg>
                        <label><IntlMessages id="ide.hide" /></label>
                      </div>
                    </div>
                    <p>
                      <label>
                        <small className="c-text-alternate"><IntlMessages id="ide.hide_desc" /></small>
                      </label>
                    </p>
                  </div>
                </form>
                <CardTitle>Deploy New Contract</CardTitle>
                <Button className="c-primary mt-3 mb-2" onClick={deploy} disabled={loading}>
                  {loading && <Spinner color="primary" />}
                  {!loading && <span>Send</span>}
                </Button>
                {deployList.map((addr, i) => <p key={`${addr}-${i}`}>Contract: {addr}</p>)}
              </Card>
            </Col>
            <Col sm>
              <Card body className="mb-3">
                <CardTitle>Import Contract</CardTitle>
                <form>
                  <div className="form-group">
                    <label>
                      <IntlMessages id="address" />
                    </label>
                    <input
                      type="text"
                      className="form-control react-form-input"
                      value={addressDep}
                      onChange={e => setAddressDep(e.target.value)}
                    />
                  </div>
                </form>
                <Button className="c-primary mt-3 mb-2" onClick={importContract} disabled={loading}>
                  {loading && <Spinner color="primary" />}
                  {!loading && <span>Import</span>}
                </Button>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>{contractDep && <Contract address={contractDep.address} abi={contractDep.abi} send={deploySend} read={deployRead} />}</Col>
          </Row>
        </TabPane>
      </TabContent>
    </div>
  )
}

export default withRouter(IDE);
