import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import IntlMessages from "util/intlMessages";
import { withRouter } from "react-router-dom";

class ButtonAPI extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            visible: false
        }
    }

    cancel = (event) => {
        if (event) {
            event.preventDefault();
        }
        this.setState({
            loading: false,
            visible: false
        });
    }

    show = async (e) => {
        e.preventDefault();
        await this.setState({ visible: true, loading: false });
    }

    render() {
        return (
            <>
                <Button className="c-warning ml-5 mr-5" onClick={this.show}>
                    <i className="fas fa-rocket"></i>
                    {" "}
                    <IntlMessages id="api.title" />
                </Button>
                <Modal
                    size="lg"
                    isOpen={this.state.visible}
                    toggle={this.cancel}
                >
                    <ModalHeader toggle={this.cancel}>
                        <IntlMessages id="api.title" />
                    </ModalHeader>
                    <ModalBody>
                        <p>
                            <IntlMessages id="api.text" />
                        </p>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="c-secondary" onClick={this.cancel}>
                            <IntlMessages id="cancel" />
                        </Button>
                        {" "}
                        <Button className="c-primary" onClick={() => this.props.history.push('/nodes')}>
                            OK
                        </Button>
                    </ModalFooter>
                </Modal>
            </>
        );
    }
}

export default withRouter(ButtonAPI);
