import React from "react";
import { injectIntl } from "react-intl";
import IntlMessages from "util/intlMessages";
import { getPlanInfo } from "helper/methods";

const Plan = ({ plan, onClick, selected }) => {
    const planInfo = getPlanInfo(plan)
    return (<div className="col-sm mb-4">
        <div onClick={() => onClick(plan)} className={"card roe-shadow-2 fill-height btn" + (plan === selected ? " c-primary" : " no-border c-outline-info")}>
            <div className="card-body">
                <h4 className="card-title text-center header">
                    {planInfo.title}
                </h4>
                <div className="text-center mb-2">
                    <IntlMessages id="blockchains.nodePlanCost" /> <strong>{planInfo.cost} BWS</strong>
                </div>
                <div className="text-left">
                    <li>{planInfo.cpu} vCPU</li>
                    <li>{planInfo.ram} <IntlMessages id="blockchains.nodePlanRAM" /></li>
                    <li>{planInfo.hd} <IntlMessages id="blockchains.nodePlanMemory" /></li>
                    <li>{planInfo.tps} <IntlMessages id="blockchains.nodePlanTPS" /></li>
                    <li>Linux</li>
                </div>
            </div>
        </div>
    </div>);
}

export default injectIntl(Plan);
