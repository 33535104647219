import React, { useState } from "react";
import { injectIntl } from "react-intl";
import { loginBack, bywiseIcon } from "helper/constant";
import { connect } from "react-redux";
import { compose } from "redux";
import AuthActions from "redux/auth/actions";
import { withRouter } from "react-router-dom";
import IntlMessages from "util/intlMessages";
import Api from "api/api";
import { toast } from 'react-toastify';
import config, { getCurrentLanguage, setCurrentLanguage } from "settings/languageConfig";
import Auth from "api/auth";

const { login } = AuthActions;

const loginContainer = {
  backgroundImage: `url(${loginBack})`,
  backgroundPosition: "center center",
  backgroundSize: "cover",
  position: "fixed",
  overflow: "auto",
  top: 0,
  bottom: 0
};

const Signup = props => {
  const intl = props.intl;
  const [tab, setTab] = useState(0);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [emailCode, setEmailCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectLang, setSelectLang] = useState(false);

  const cupom = "";
  const [company, setCompany] = useState("");
  const [phone, setPhone] = useState("");
  const [selectTypeInsdutry, setSelectTypeInsdutry] = useState("");
  const [numberOfEmployees, setNumberOfEmployees] = useState("");
  const [jobPosition, setJobPosition] = useState("");
  const [reasonForUsing, setReasonForUsing] = useState("");

  const [agree, setAgree] = useState(false);
  const [error, setError] = useState({});

  const setLang = async (locale) => {
    setCurrentLanguage(locale);
    window.location.reload(false);
  }

  const isValid = () => {
    if (!emailCode.match(/[0-9]{6}/) && tab === 1) {
      setError({
        emailCode: <IntlMessages id="signup.invalid_email_code" />
      });
      return false;
    }
    if (!email.match(/.+@.+\..+/)) {
      setError({
        email: <IntlMessages id="signup.invalid_email" />
      });
      return false;
    }
    if (password.length < 8) {
      setError({
        password: <IntlMessages id="signup.password_too_short" />
      });
      return false;
    }
    if (password !== password2) {
      setError({
        password2: <IntlMessages id="signup.password_dont_match" />
      });
      return false;
    }
    if (!agree) {
      setError({
        agree: <IntlMessages id="signup.need_agree" />
      });
      return false;
    }
    setError({});
    return true;
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    setLoading(true);
    let req;
    if (isValid()) {
      if (tab === 0) {
        req = await Api.post(`auth/signup`, {
          email: email,
          password: password
        });
        if (!req.error) {
          setTab(1);
        }
      } else if (tab === 1) {
        req = await Api.post(`auth/email/confirm`, {
          sentTo: email,
          code: emailCode
        }, false);
        if (!req.error) {
          setTab(2);
        } else {
          setError({
            emailCode: <IntlMessages id="signup.invalid_email_code" />
          });
        }
      } else {
        if (cupom) {
          req = await Api.get(`sales/cupom/${cupom}`);
          if (!req.error) {
            if (req.data.limit <= 0) {
              setError({
                cupom: <IntlMessages id="signup.invalid_cupom" />
              });
              setLoading(false);
              return;
            }
          }
        }
        req = await Api.post(`auth/signin`, {
          email: email,
          password: password
        });
        if (!req.error) {
          Auth.saveToken(req.data.token);
          await Api.post(`auth/signup/form`, {
            value: JSON.stringify({
              company,
              phone,
              selectTypeInsdutry,
              numberOfEmployees,
              jobPosition,
              reasonForUsing,
              cupom,
            }),
          });
          Auth.logout(false);
          toast.success(intl.formatMessage({ id: `signup.success` }), {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          props.history.push("/signin");
          return;
        }
      }
    }
    setLoading(false);
  };

  const Error = props => {
    const field1 = props.field;
    if (error[field1]) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {error[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  return (
    <div className="container-fluid" style={loginContainer}>
      <div
        className="page-home-lang-button"
        onClick={() => setSelectLang(true)}
      >
        {!selectLang && <img src={getCurrentLanguage().icon} width={40} height={40} alt='language icon' />}
        {selectLang && config.options.map(lang => <div
          key={lang.languageId}
          className="page-home-lang-list"
          onClick={() => setLang(lang.locale)}
        >
          <div className="d-flex">
            <img src={lang.icon} width={40} height={40} alt={lang.text} />
          </div>
        </div>)}
      </div>
      <div style={{
        marginTop: 60
      }}>
        {tab === 0 && (
          <>
            <div className="form-container">
              <div className="login-icon">
                <img src={bywiseIcon} alt="icon" height="100px" />
              </div>
              <div className="login-title mb-2">
                <IntlMessages id="signup.title" />
              </div>
              <form className="pa-24">
                <div className="form-group">
                  <label>
                    <IntlMessages id="signup.email" />
                  </label>
                  <input
                    type="email"
                    className="form-control react-form-input"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                  />
                  <Error field="email" />
                </div>

                <div className="form-group">
                  <label>
                    <IntlMessages id="signup.password" />
                  </label>
                  <input
                    type="password"
                    className="form-control react-form-input"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                  />
                  <Error field="password" />
                </div>

                <div className="form-group">
                  <label>
                    <IntlMessages id="signup.rewrite_password" />
                  </label>
                  <input
                    type="password"
                    className="form-control react-form-input"
                    value={password2}
                    onChange={e => setPassword2(e.target.value)}
                  />
                  <Error field="password2" />
                </div>

                <div className="form-check text-center mtb-16">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="exampleCheck1"
                    checked={agree}
                    onChange={() => setAgree(!agree)}
                  />
                  <label
                    className="form-check-label register-privacy-text"
                    htmlFor="exampleCheck1"
                  >
                    <IntlMessages id="signup.termsDesc" values={{
                      termsOfService: <a href="/terms-of-service"><IntlMessages id="signup.termsOfService" /></a>,
                      privacyPolicy: <a href="/privacy-policy"><IntlMessages id="signup.privacyPolicy" /></a>,
                    }} />
                  </label>
                </div>
                <Error field="agree" />

                <button className="btn form-button" onClick={handleRegister}>
                  <IntlMessages id="next" />
                </button>

                <div
                  className="text-center link-label"
                  onClick={() => props.history.push("/signin")}
                >
                  <IntlMessages id="signup.signin" />
                </div>
              </form>
            </div>
          </>
        )}
        {tab === 1 && (
          <>
            <div className="form-container">
              <form className="pa-24">
                <button
                  type="submit"
                  className="btn form-back p-3"
                  onClick={() => setTab(0)}
                >
                  <i className="fas fa-arrow-left"></i>
                </button>
                <div className="login-title mb-4">
                  <IntlMessages id="signup.email_confirm" />
                </div>

                <div className="form-info-text mt-16">
                  <IntlMessages id="signup.email_confirm_text" />
                </div>
                <div className="form-info-text mt-16">
                  <IntlMessages id="signup.email_confirm_text2" />
                </div>

                <div className="form-group mt-16">
                  <label>
                    <IntlMessages id="signup.code" />
                  </label>
                  <input
                    type="text"
                    className="form-control react-form-input"
                    value={emailCode}
                    placeholder="000000"
                    onChange={e => setEmailCode(e.target.value.substring(0, 6))}
                  />
                  <Error field="emailCode" />
                </div>

                <button className="btn form-button" onClick={handleRegister}>
                  <IntlMessages id="next" />
                </button>
              </form>
            </div>
          </>
        )}
        {tab === 2 && (
          <>
            <div className="form-container">
              <form className="pa-24">
                <button
                  type="submit"
                  className="btn form-back p-3"
                  onClick={() => setTab(1)}
                >
                  <i className="fas fa-arrow-left"></i>
                </button>

                <div className="login-title mb-4">
                  <IntlMessages id="signup.about" />
                </div>

                <div className="form-info-text mt-16">
                  <IntlMessages id="signup.about_text" />
                </div>

                <div className="form-group mt-16">
                  <label>
                    <IntlMessages id="signup.company" />
                  </label>
                  <input
                    type="text"
                    className="form-control react-form-input"
                    value={company}
                    onChange={e => setCompany(e.target.value)}
                  />
                  <Error field="company" />
                </div>

                <div className="form-group mt-16">
                  <label>
                    <IntlMessages id="signup.phone" />
                  </label>
                  <input
                    type="text"
                    className="form-control react-form-input"
                    value={phone}
                    onChange={e => setPhone(e.target.value)}
                  />
                  <Error field="company" />
                </div>

                <div className="form-group mt-16">
                  <label>
                    <IntlMessages id="signup.selectTypeInsdutry" />
                  </label>

                  <select
                    className="form-control react-form-input"
                    value={selectTypeInsdutry}
                    onChange={e => setSelectTypeInsdutry(e.target.value)}
                  >
                    <option value={""}></option>
                    {new Array(16).fill(1).map((v, i) => {
                      const key = intl.formatMessage({ id: `signup.optionTypeInsdutry${i + 1}` });
                      return (<option
                        key={`optionTypeInsdutry${i}`}
                        value={key}>
                        {key}
                      </option>)
                    })}
                  </select>
                  <Error field="selectTypeInsdutry" />
                </div>

                <div className="form-group mt-16">
                  <label>
                    <IntlMessages id="signup.numberOfEmployees" />
                  </label>

                  <select
                    className="form-control react-form-input"
                    value={numberOfEmployees}
                    onChange={e => setNumberOfEmployees(e.target.value)}
                  >
                    <option value={""}></option>
                    {new Array(5).fill(1).map((v, i) => {
                      const key = intl.formatMessage({ id: `signup.numberOfEmployees${i + 1}` });
                      return (<option
                        key={`numberOfEmployees${i}`}
                        value={key}>
                        {key}
                      </option>)
                    })}
                  </select>
                  <Error field="numberOfEmployees" />
                </div>

                <div className="form-group mt-16">
                  <label>
                    <IntlMessages id="signup.jobPosition" />
                  </label>

                  <select
                    className="form-control react-form-input"
                    value={jobPosition}
                    onChange={e => setJobPosition(e.target.value)}
                  >
                    <option value={""}></option>
                    {new Array(9).fill(1).map((v, i) => {
                      const key = intl.formatMessage({ id: `signup.jobPosition${i + 1}` });
                      return (<option
                        key={`jobPosition${i}`}
                        value={key}>
                        {key}
                      </option>)
                    })}
                  </select>
                  <Error field="jobPosition" />
                </div>

                <div className="form-group mt-16">
                  <label>
                    <IntlMessages id="signup.reasonForUsing" />
                  </label>

                  <select
                    className="form-control react-form-input"
                    value={reasonForUsing}
                    onChange={e => setReasonForUsing(e.target.value)}
                  >
                    <option value={""}></option>
                    {new Array(13).fill(1).map((v, i) => {
                      const key = intl.formatMessage({ id: `signup.reasonForUsing${i + 1}` });
                      return (<option
                        key={`reasonForUsing${i}`}
                        value={key}>
                        {key}
                      </option>)
                    })}
                  </select>
                  <Error field="reasonForUsing" />
                </div>

                <button className="btn form-button" disabled={loading} onClick={handleRegister}>
                  <IntlMessages id="save" />
                </button>
              </form>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default compose(
  injectIntl,
  withRouter,
  connect(null, { login })
)(Signup);
