import Enlang from "./entries/en-US";
import PTLang from "./entries/pt-BR";
import EsLang from "./entries/es-ES";
import { addLocaleData } from "react-intl";

const AppLocale = {
  pt: PTLang,
  en: Enlang,
  es: EsLang
};
addLocaleData(AppLocale.pt.data);
addLocaleData(AppLocale.en.data);
addLocaleData(AppLocale.es.data);

export default AppLocale;
