import Layout from "layouts/DashboardLayout.jsx";
import {
  Buy,
  Recover,
  Error400,
  Error500,
  Signup,
  Signin,
} from "./../views/pages/index";
import ConfirmTransaction from "views/provider/ConfirmTransaction";
import ProviderSignin from "views/provider/ProviderSignin";
import Terms from "views/pages/docs/Terms";
import AUP from "views/pages/docs/AUP";
import Privacy from "views/pages/docs/Privacy";

const indexRoutes = [
  { path: "/recover", component: Recover },
  { path: "/signin", component: Signin },
  { path: "/signup", component: Signup },
  { path: "/error400", component: Error400 },
  { path: "/error500", component: Error500 },
  { path: "/pricing", component: Buy },
  { path: "/aup", component: AUP },
  { path: "/terms-of-service", component: Terms },
  { path: "/privacy-policy", component: Privacy },
  { path: "/connect", component: ProviderSignin },
  { path: "/send_transaction", component: ConfirmTransaction },
  { path: "/", component: Layout }
];

export default indexRoutes;
