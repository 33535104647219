import PageTitle from "components/common/PageTitle";
import React from "react";
import { Col, Row } from "reactstrap";
import IntlMessages from "util/intlMessages";

const Building = props => {

  return (
    <div className="page-padding">
      <PageTitle title="building.title" />
      <div className="plr-15">
        <Row>
          <Col><div className="roe-card-style">
            <div className="roe-card-header">
            </div>
            <div className="roe-card-body text-center">
              <div className="gear-coming-soon mb-2">
                <i className="fas fa-cog fa-spin"></i>
              </div>
              <h1>
                <IntlMessages id="building.title" />
              </h1>
              <h5 className="c-text-alternate mt-2">
                <IntlMessages id="building.desc" />
              </h5>
            </div>
          </div></Col>
        </Row>
      </div>
    </div>
  );
};

export default Building;
