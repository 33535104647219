import { Wallet, Web3 } from "@bywise/web3";
import Auth from "api/auth";

var web3 = new Web3();
var isLoadWeb3 = false;

const getLastCode = () => {
  let code = localStorage.getItem('ide_code');
  if (!code) {
    code = '';
  }
  return code;
}
const setLastCode = (code) => {
  localStorage.setItem('ide_code', code);
}

const getDefaultChain = () => {
  return process.env.REACT_APP_CHAIN ? process.env.REACT_APP_CHAIN : 'mainnet';
}

const getInfoChain = () => {
  let chain = localStorage.getItem('info-chain');
  if (!chain) {
    const info = Auth.getInfo();
    for (let i = 0; i < info.chains.length; i++) {
      const infoChain = info.chains[i];
      if (infoChain.chain === (process.env.REACT_APP_CHAIN ? process.env.REACT_APP_CHAIN : 'mainnet')) {
        return infoChain;
      }
    }
    return info.chains[0];
  } else {
    return JSON.parse(chain);
  }
}

const setInfoChain = (chain) => {
  isLoadWeb3 = false;
  localStorage.removeItem('web3Nodes');
  localStorage.setItem('info-chain', JSON.stringify(chain));
}

const getWeb3 = async () => {
  try {
    if (!isLoadWeb3) {
      const chain = getInfoChain();
      web3 = new Web3({
        initialNodes: chain.nodes,
      });
      let web3Nodes = localStorage.getItem('web3Nodes');
      if (web3Nodes) {
        await web3.network.importConnections(JSON.parse(web3Nodes));
        await web3.network.tryConnection();
      } else {
        await web3.network.tryConnection();
      }
      localStorage.setItem('web3Nodes', JSON.stringify(web3.network.exportConnections()));
      isLoadWeb3 = true;
    }
    return web3;
  } catch (err) {
    console.log('Web3 connection failed');
    throw err;
  }
}

const getWallet = () => {
  let seed = localStorage.getItem('info-wallet');
  if (!seed) {
    const info = Auth.getInfo();
    info.addresses.forEach(addr => {
      if (addr.id === info.user.addressId) {
        seed = addr.seed;
      }
    })
  }
  return new Wallet({ seed });
}
const setWallet = (seed) => {
  localStorage.setItem('info-wallet', seed);
}
const clear = () => {
  isLoadWeb3 = false;
  localStorage.removeItem('info-wallet');
  localStorage.removeItem('info-chain');
  localStorage.removeItem('web3Nodes');
}

const Session = {
  getLastCode,
  setLastCode,
  getWallet,
  setWallet,
  getDefaultChain,
  getInfoChain,
  setInfoChain,
  getWeb3,
  clear,
};

export default Session;
