import React from "react";
import { injectIntl } from "react-intl";
import Loading from "components/common/Loading";
import IntlMessages from "util/intlMessages";
import PageTitle from "components/common/PageTitle";
import { toast } from "react-toastify";
import IPFSIcon from "components/files/IPFSIcon";
import DataTable from "react-data-table-component";
import Api from "api/api";
import BlockchainStatus from "components/blockchainStatus/BlockchainStatus";
import { bywiseIcon } from "helper/constant";
import { Button } from "reactstrap";

const columns = [
  {
    name: 'Node',
    selector: row => <a href={`${row.url}`} target="_blank" rel="noopener noreferrer">{row.url}</a>,
    grow: 2,
    sortable: true,
  },
  {
    name: 'Status',
    selector: row => <BlockchainStatus status={row.status} />,
    sortable: true,
  },
  {
    name: 'Expire',
    selector: row => (new Date(row.expire)).toLocaleDateString(),
    sortable: true,
  },
  {
    name: 'See',
    selector: row => row.button,
  },
];

class BlockchainsItem extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      chainId: '',
      name: '',
      explorer: '',
      nodesAmount: '',
      status: '',
      nodes: [],
    }
  }

  componentDidMount = () => {
    this.updateContract();
  }

  updateContract = async () => {
    this.setState({ loading: true });

    try {
      const chainId = this.props.match.params.id;

      let req = await Api.get(`blockchains`);
      if (req.error) {
        return;
      }
      let chain = null;
      for (let i = 0; i < req.data.length; i++) {
        const chainObj = req.data[i];
        if (chainObj.chain === chainId) {
          chain = chainObj;
        }
      }
      if (!chain) {
        toast.error('Chain not found');
        return;
      }

      const name = chain.name;
      const explorer = chain.explorer;
      const nodesAmount = chain.nodesAmount;
      const status = chain.status;

      req = await Api.get(`nodes/chain/${chainId}`);
      if (req.error) {
        return;
      }
      let nodes = req.data;
      for (let i = 0; i < nodes.length; i++) {
        const node = nodes[i];
        node.button = <Button onClick={() => {
          this.props.history.push(`/nodes/item/${node.id}`)
        }}><IntlMessages id="see" /></Button>
      }

      await this.setState({
        chainId,
        name,
        explorer,
        nodesAmount,
        status,
        nodes,
        loading: false
      });

      return;

    } catch (err) {
      toast.error(err.message);
      return;
    }
  }

  render() {
    if (this.state.loading) {
      return <Loading loading={true} />
    }
    return (<div className="page-padding">
      <PageTitle title={"blockchains.title"} />

      <div className="plr-15">
        <div className="roe-card-style">
          <div className="roe-card-header">
            <span className="hash"># </span>
            <span><IntlMessages id="blockchains.title" /></span>
          </div>
          <div className="roe-card-body">
            <div className="form-group row">
              <div className="col-sm-4">
                <IPFSIcon className="img-fluid" icon={bywiseIcon} />
              </div>
              <div className="col">
                <label className="col col-form-label hidden-overflow">
                  {"Name: " + this.state.name}
                </label>
                <br />
                <label className="col col-form-label hidden-overflow">
                  {"Chain ID: " + this.state.chainId}
                </label>
                <br />
                <label className="col col-form-label hidden-overflow">
                  {"Status: "}
                  <BlockchainStatus status={this.state.status} />
                </label>
                <br />
                <label className="col col-form-label hidden-overflow">
                  {"Nodes Amount: " + this.state.nodesAmount}
                </label>
                <br />
                <label className="col col-form-label hidden-overflow">
                  {"Explorer: "}
                  <a href={`${this.state.explorer}`} target="_blank" rel="noopener noreferrer">
                    {`${this.state.explorer}`}
                  </a>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="plr-15 mt-4">
        <div className="roe-card-style">
          <div className="roe-card-header flex-x align-center">
            <div className="flex-1 fs-18 header">

              <span className="hash"># </span>
              <IntlMessages id="dashboard.my_nodes" />
            </div>
            <button className="c-btn c-primary" onClick={() => this.props.history.push('/nodes/new')}><IntlMessages id="dashboard.new_node" /></button>
          </div>
          <div className="roe-card-body">
            <div className="form-group row">
              <div className="col">
                <DataTable
                  columns={columns}
                  data={this.state.nodes}
                  defaultSortFieldId={1}
                  pagination
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>);
  }
}

export default injectIntl(BlockchainsItem);