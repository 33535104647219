import React from "react";
import moment from "moment";

export const getUptime = (created) => {
  let uptime = (new Date().getTime()) / 1000 - created;
  let label = '';
  if (uptime < 60) {
    label = 'seconds';
  } else if (uptime < 60 * 60) {
    uptime = uptime / (60);
    label = 'minutes';
  } else if (uptime < 60 * 60 * 24) {
    uptime = uptime / (60 * 60);
    label = 'hours';
  } else {
    uptime = uptime / (60 * 60 * 24);
    label = 'days';
  }
  return `${Math.floor(uptime)} ${label}`;
}

export const randomUUID = () => {
  return (
    Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1) +
    Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1)
  );
};

export const calcIPFSPrice = (expire, size) => {
  const sizeMB = Math.floor(parseFloat(size) / (1024 * 1024)) + 1;
  return (parseFloat(expire) * sizeMB * 0.7).toFixed(1);
}

export const getRegisterChainPrice = (plan) => {
  return 400;
}

export const getPlanInfo = (plan) => {
  const info = {
    cpu: "null",
    ram: "null",
    hd: "null",
    tps: 0,
    cost: 0,
    title: "null",
  }
  if(plan === 1) {
    info.cpu = '1';
    info.ram = '1 GB';
    info.hd = '10 GB';
    info.tps = 0.1;
    info.cost = 80;
    info.title = "Basic";
  } else if(plan === 2) {
    info.cpu = '1';
    info.ram = '2 GB';
    info.hd = '10 GB';
    info.tps = 0.5;
    info.cost = 140;
    info.title = "Intermediary";
  } else {
    info.cpu = '2';
    info.ram = '4 GB';
    info.hd = '20 GB';
    info.tps = 2;
    info.cost = 240;
    info.title = "Advanced";
  }
  return info;
}

export const convertBWSToUSD = function sleep(bws) {
  return <small className="ml-3 c-text-alternate"> ~{(parseFloat(bws) * 0.2062).toFixed(2)} USD</small>
}

export const sleep = async function sleep(ms) {
  await new Promise((resolve) => {
    setTimeout(resolve, ms + 10);
  });
}

export const subtaskTicket = () => {
  return (
    "#" +
    Math.floor((1 + Math.random()) * 0x1000)
      .toString(16)
      .substring(1) +
    Math.floor((1 + Math.random()) * 0x1000)
      .toString(16)
      .substring(1)
  );
};

export const dateFormat = date => {
  return moment(date).format("MMMM Do, h:mm a");
};

export const onlyDate = date => {
  return moment(date).format("MMM Do");
};

export const datesWithYear = date => {
  return moment(date).format("MMM Do YYYY");
};
