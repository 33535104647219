import React, { useRef, useState } from "react";
import { injectIntl } from "react-intl";
import Api from "api/api";
import { toast } from "react-toastify";
import FormNew from "components/forms/FormNew";
import Session from "util/Session";
import IntlMessages from "util/intlMessages";
import { Table } from "reactstrap";
import { convertBWSToUSD, getPlanInfo, getRegisterChainPrice } from "helper/methods";
import { BywiseHelper, TxType } from "@bywise/web3";
import PublishTransaction from "components/transaction/PublishTransaction";
import Plan from "components/blockchain/Plan";

const BlockchainsNew = (props) => {
    const [plan, setPlan] = useState(2);
    const [nodeAmount, setNodeAmount] = useState(2);
    const publishTransaction = useRef(null);

    const price = getRegisterChainPrice();

    const service = 'dashboard.my_networks';

    const calcTPS = () => {
        let tps = getPlanInfo(plan).tps * nodeAmount;
        if (tps < 1) {
            let tpm = parseFloat((tps * 60).toPrecision(5));
            tps = parseFloat(tps.toPrecision(5));
            return <td>
                <span>{tps} <IntlMessages id="blockchains.totalTPSDesc" /></span>
                {" | "}
                <strong>{tpm} <IntlMessages id="blockchains.totalTPMDesc" /></strong>
            </td>
        } else {
            tps = parseFloat(tps.toPrecision(5));
            return <td>{tps} <IntlMessages id="blockchains.totalTPSDesc" /></td>
        }
    }

    const form = {
        title: 'dashboard.new_networks',
        description: '',
        saveBTN: async (inputsForm) => {
            let req = await Api.post(`blockchains/check`, {
                value: inputsForm.chain,
            });
            if (req.error) {
                toast.error(`Error`);
                return;
            }
            if (req.data.value) {
                toast.error(`This "Chain ID" already exists`);
                return;
            }

            const planInfo = getPlanInfo(plan)

            const total = (price + planInfo.cost * nodeAmount).toString();

            const web3 = await Session.getWeb3();
            const infoChain = Session.getInfoChain();
            const wallet = Session.getWallet();

            const tx = await web3.transactions.buildSimpleTx(
                wallet,
                infoChain.chain,
                BywiseHelper.ZERO_ADDRESS,
                total,
                TxType.TX_JSON,
                {
                    chain: inputsForm.chain,
                }
            );

            publishTransaction.current.publish(tx, {
                type: 'pay',
                amount: total,
                service: props.intl.formatMessage({ id: `dashboard.new_networks` }),
            }, (async (tx, output) => {
                await Api.post(`blockchains`, {
                    txId: tx.hash,
                    chain: inputsForm.chain,
                    name: inputsForm.name,
                    nodesPlan: plan,
                    nodesAmount: nodeAmount,
                    info: JSON.stringify(inputsForm),
                });

                if (req.error) {
                    toast.error(req.error)
                    return;
                }
                toast.success(`Success`);
                props.history.goBack();
            }))
        },
        form: [
            {
                title: 'blockchains.name',
                description: '',
                setValue: (value = '') => {
                    if (value.length > 100) {
                        return value.substring(0, 100);
                    }
                    return value;
                },
                validation: (value = '') => value.trim().length === 0 ? 'invalid_field' : '',
                type: 'text',
                id: 'name'
            },
            {
                title: 'blockchains.chain',
                description: '',
                setValue: (value = '') => {
                    value = value.toUpperCase().trim();
                    if (value.length > 10) {
                        return value.substring(0, 10);
                    }
                    return value;
                },
                validation: (value = '') => value.trim().length === 0 ? 'invalid_field' : '',
                type: 'text',
                id: 'chain'
            },
            {
                title: 'blockchains.symbol',
                description: '',
                setValue: (value = '') => {
                    value = value.toUpperCase().trim();
                    if (value.length > 10) {
                        return value.substring(0, 10);
                    }
                    return value;
                },
                validation: (value = '') => value.trim().length === 0 ? 'invalid_field' : '',
                type: 'text',
                id: 'symbol'
            },
            {
                title: 'blockchains.icon',
                description: '',
                isPublic: true,
                validation: (value = '') => '',
                type: 'file',
                id: 'icon',
            },
            {
                title: 'blockchains.type',
                description: '',
                validation: (value = '') => '',
                itens: [
                    { value: "1", label: props.intl.formatMessage({ id: `blockchains.type_1` }) },
                    { value: "2", label: props.intl.formatMessage({ id: `blockchains.type_2` }) },
                    { value: "3", label: props.intl.formatMessage({ id: `blockchains.type_3` }) },
                ],
                type: 'select',
                id: 'type',
            },
            {
                title: 'blockchains.initialAmount',
                description: '',
                validation: (value = '') => {
                    if (value.trim().length === 0) {
                        return 'invalid_field';
                    }
                    return '';
                },
                type: 'number',
                id: 'initialAmount'
            },
            {
                title: 'blockchains.fees',
                description: 'blockchains.feesDesc',
                validation: (value = '') => '',
                type: 'checkbox',
                id: 'fees'
            },
            {
                type: 'custom',
                child: <div className="mt-3 mb-3">
                    <h5><IntlMessages id="blockchains.info" />
                        <strong>{price + " BWS"}</strong>
                        {convertBWSToUSD(price)}
                    </h5>
                    <h6><IntlMessages id="blockchains.info2" /></h6>
                    <hr />
                </div>,
            },
            {
                type: 'custom',
                child: <div>
                    <div className="form-group row">
                        <label className="col-sm-4 col-form-label">
                            <IntlMessages id="blockchains.nodeAmount" />
                            {`: `}
                            <strong>{nodeAmount}</strong>
                        </label>
                        <div className="col-sm-8">
                            <div className="input-group">
                                <input
                                    type="range"
                                    min="1"
                                    max="10"
                                    className="form-control react-form-input"
                                    value={nodeAmount}
                                    onChange={(e) => setNodeAmount(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <strong><IntlMessages id="blockchains.nodePlan" /></strong>
                        </div>
                    </div>
                    <div className="row pt-3 pb-3">
                        <Plan selected={plan} onClick={setPlan} plan={1} />
                        <Plan selected={plan} onClick={setPlan} plan={2} />
                        <Plan selected={plan} onClick={setPlan} plan={3} />
                    </div>
                    <hr />
                    <h5 className="mb-4">
                        <IntlMessages id="blockchains.resume" />
                    </h5>
                    <Table striped responsive>
                        <tbody>
                            <tr>
                                <td><IntlMessages id="blockchains.totalTPS" /></td>
                                {calcTPS()}
                            </tr>
                            <tr>
                                <td><IntlMessages id="blockchains.totalNodes" /></td>
                                <td>
                                    {getPlanInfo(plan).cost * nodeAmount}
                                    {" BWS "}
                                    <IntlMessages id="blockchains.perMonth" />
                                    {" "}
                                    {convertBWSToUSD(getPlanInfo(plan).cost * nodeAmount)}
                                </td>
                            </tr>
                            <tr>
                                <td><IntlMessages id="blockchains.totalRegistro" /></td>
                                <td>
                                    {price}
                                    {" BWS "}
                                    <IntlMessages id="blockchains.perMonth" />
                                    {" "}
                                    {convertBWSToUSD(price)}
                                </td>
                            </tr>
                            <tr>
                                <td><strong><IntlMessages id="blockchains.total" /></strong></td>
                                <td>
                                    <strong>
                                        {price + getPlanInfo(plan).cost * nodeAmount}
                                        {" BWS "}
                                        <IntlMessages id="blockchains.perMonth" />
                                    </strong>
                                    {" "}
                                    {convertBWSToUSD(price + getPlanInfo(plan).cost * nodeAmount)}
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </div>,
            },
        ]
    }

    return (<>
        <FormNew service={service} form={form} />
        <PublishTransaction ref={publishTransaction} />
    </>);
}

export default injectIntl(BlockchainsNew);
