import React from "react";
import PageTitle from "components/common/PageTitle";
import IntlMessages from "util/intlMessages";
import { Button, Col, Row } from "reactstrap";
import DataTable from 'react-data-table-component';
import Api from "api/api";
import { injectIntl } from "react-intl";

const columns = [
  {
    name: 'Name',
    selector: row => row.name,
    sortable: true,
  },
  {
    name: 'Created',
    selector: row => (new Date(row.created)).toLocaleDateString(),
    sortable: true,
  },
  {
    name: 'Action',
    selector: row => row.button,
    sortable: false,
  },
];

class ProjectsList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      filter: '',
      values: [],
      filteredValues: []
    }
  }

  componentDidMount = () => {
    this.updateTable();
  }

  updateTable = async () => {
    await this.setState({ loading: true });
    const req = await Api.get(`projects`);
    if (req.error) {
      return;
    }
    let rows = req.data;
    let values = [];
    rows = rows.sort((a, b) => (new Date(b.created).getTime()) - (new Date(a.created).getTime()))
    rows.forEach((row, i) => {
      values.push({
        id: i,
        name: row.name,
        created: row.created,
        button: <Button onClick={() => {
          this.props.history.push(`/projects/item/${row.id}`)
        }}><IntlMessages id="see" /></Button>
      });
    });
    await this.setState({ loading: false, values, filteredValues: values.map(v => v) });
  }

  updateSearch = (event) => {
    let filter = event.target.value.trim().toLowerCase();

    let filteredValues = [];

    this.state.values.forEach(row => {
      let add = false;
      if (filter.length === 0 ||
        row.name.toLowerCase().includes(filter)) {
        add = true;
      }
      if (add) {
        filteredValues.push(row);
      }
    })

    this.setState({ filter, filteredValues });
  }

  customSort = (rows, selector, direction) => {
    return rows.sort((rowA, rowB) => {
      const aField = selector(rowA, true, true)
      const bField = selector(rowB, true, true)
      let comparison = 0;
      if (aField > bField) {
        comparison = 1;
      } else if (aField < bField) {
        comparison = -1;
      }
      return direction === 'desc' ? comparison * -1 : comparison;
    });
  };

  render() {
    return (
      <div className="page-padding">
        <PageTitle title="project.title" />
        <div className="plr-15">
          <Row>
            <Col><div className="roe-card-style">
              <div className="roe-card-header flex-x align-center">
                <div className="flex-1 fs-18 header">

                  <span className="hash"># </span>
                  <IntlMessages id="project.title" />
                </div>
                <button className="c-btn c-primary" onClick={() => this.props.history.push('/projects/new')}><IntlMessages id="project.new" /></button>
              </div>
              <div className="roe-card-body">
                <div className="form-group row">
                  <div className="col-sm-6"></div>
                  <div className="col-sm-6">
                    <div className="input-group">
                      <input
                        className="form-control"
                        type="text"
                        placeholder={this.props.intl.formatMessage({ id: `search` })}
                        value={this.state.filter}
                        onChange={this.updateSearch} />
                      <div className="input-group-append">
                        <span className="input-group-text">
                          <i className="fas fa-search"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <DataTable
                  columns={columns}
                  data={this.state.filteredValues}
                  defaultSortFieldId={1}
                  sortFunction={this.customSort}
                  pagination
                />
              </div>
            </div></Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default injectIntl(ProjectsList);
