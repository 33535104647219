import Dashboard from "views/Dashboard";
import IDE from "views/IDE";
import Send from "views/Send";
import SendNFT from "views/SendNFT";
import Support from "views/Support";
import Wallet from "views/Wallet";
import Community from "views/Community";
import Files from "views/files/list";
import Projects from "views/projects/list";
import ProjectNew from "views/projects/new";
import ProjectItem from "views/projects/item";
import TraceabilityList from "views/traceability/list";
import TraceabilityNew from "views/traceability/new";
import TraceabilityItem from "views/traceability/item";
import TokensList from "views/tokens/list";
import TokensNew from "views/tokens/new";
import TokensItem from "views/tokens/item";
import NFTList from "views/nft/list";
import NFTNew from "views/nft/new";
import NFTItem from "views/nft/item";
import BlockchainList from "views/blockchains/list";
import BlockchainNew from "views/blockchains/new";
import BlockchainItem from "views/blockchains/item";
import Building from "views/Building";
import NodesList from "views/nodes/list";
import NodesNew from "views/nodes/new";
import NodesItem from "views/nodes/item";

const dashboardRoutes = [
    { path: "/dashboard", component: Dashboard },
    { path: "/projects", component: Projects },
    { path: "/projects/new", component: ProjectNew },
    { path: "/projects/item/:id", component: ProjectItem },
    { path: "/traceability", component: TraceabilityList },
    { path: "/traceability/new", component: TraceabilityNew },
    { path: "/traceability/item/:id", component: TraceabilityItem },
    { path: "/tokens", component: TokensList },
    { path: "/tokens/new", component: TokensNew },
    { path: "/tokens/item/:id", component: TokensItem },
    { path: "/nfts", component: NFTList },
    { path: "/nfts/new", component: NFTNew },
    { path: "/nfts/item/:id", component: NFTItem },
    { path: "/blockchains", component: BlockchainList },
    { path: "/blockchains/new", component: BlockchainNew },
    { path: "/blockchains/item/:id", component: BlockchainItem },
    { path: "/nodes", component: NodesList },
    { path: "/nodes/new", component: NodesNew },
    { path: "/nodes/item/:id", component: NodesItem },
    { path: "/crosschain", component: Building },
    { path: "/dex", component: Building },
    { path: "/marketplace", component: Building },
    { path: "/community_apps", component: Building },
    { path: "/files", component: Files },
    { path: "/wallet", component: Wallet },
    { path: "/send", component: Send },
    { path: "/sendNFT", component: SendNFT },
    { path: "/ide", component: IDE },
    { path: "/support", component: Support },
    { path: "/community", component: Community },
];

export default dashboardRoutes;
