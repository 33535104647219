import React, { Component } from "react";
import FormNew from "components/forms/FormNew";
import Api from "api/api";
import { toast } from "react-toastify";
import { injectIntl } from "react-intl";
import Loading from "components/common/Loading";

class ProjectItem extends Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      project: {
        id: '',
        name: '',
        description: '',
        alertEmail: "",
        billingEmail: ""
      }
    }
  }

  async componentDidMount() {
    const id = this.props.match.params.id
    const req = await Api.get(`projects/${id}`);
    if (!req.error) {
      this.setState({
        project: req.data,
        loading: false
      })
    }
  }

  render() {
    if (this.state.loading) {
      return <Loading loading={true} />
    }
    const service = 'project.title';

    const form = {
      title: 'project.title',
      description: '',
      initialValues: this.state.project,
      saveBTN: async (inputsForm) => {
        const req = await Api.patch(`projects/${this.state.project.id}`, {
          name: inputsForm.name,
          description: inputsForm.description,
          alertEmail: inputsForm.alertEmail,
          billingEmail: inputsForm.billingEmail,
        });
        if (!req.error) {
          toast.success(`Success`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          this.props.history.goBack();
        }
      },
      deleteBTN: async () => {
        const req = await Api.del(`projects/${this.state.project.id}`);
        if (!req.error) {
          toast.success(`Success`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          this.props.history.goBack();
        }
      },
      form: [
        {
          title: 'project.name',
          description: '',
          validation: (value = '') => value.trim().length === 0 ? 'invalid_field' : '',
          type: 'text',
          id: 'name'
        },
        {
          title: 'project.description',
          description: '',
          validation: (value = '') => value.trim().length === 0 ? 'invalid_field' : '',
          type: 'textarea',
          id: 'description'
        },
        {
          title: 'project.alertEmail',
          description: '',
          validation: (value = '') => value.trim().length === 0 ? 'invalid_field' : '',
          type: 'text',
          id: 'alertEmail'
        },
        {
          title: 'project.billingEmail',
          description: '',
          validation: (value = '') => value.trim().length === 0 ? 'invalid_field' : '',
          type: 'text',
          id: 'billingEmail'
        },
      ]
    }

    return (<FormNew service={service} form={form} />);
  }
}

export default injectIntl(ProjectItem);
