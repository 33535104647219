import React from "react";
import { injectIntl } from "react-intl";
import Loading from "components/common/Loading";
import IntlMessages from "util/intlMessages";
import PageTitle from "components/common/PageTitle";
import Session from "util/Session";
import { TxType } from "@bywise/web3";
import { toast } from "react-toastify";
import Contract from "components/common/Contract";
import SweetAlert from "react-bootstrap-sweetalert";
import IPFSIcon from "components/files/IPFSIcon";
import DataTable from "react-data-table-component";
import PublishTransaction from "components/transaction/PublishTransaction";

const columns = [
  {
    name: 'Index',
    selector: row => row.index,
    sortable: true,
  },
  {
    name: 'Address',
    selector: row => <a href={`${Session.getInfoChain().explorer}/nft/${row.address}/${row.index}`} target="_blank" rel="noopener noreferrer">...explorer.bywise.org/nft/{row.address.substring(0, 5)}...{row.address.substring(row.address.length - 5)}/{row.index}</a>,
    grow: 2,
    sortable: true,
  },
];

class NFTItem extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      name: '',
      address: '',
      totalSupply: 0,
      nfts: [],
      contract: {
        address: '',
        abi: [],
      },
      alertShow: false,
      alert: {
        title: '',
        content: ''
      }
    }
  }

  componentDidMount = () => {
    this.updateContract();
  }

  updateContract = async () => {
    this.setState({ loading: true });

    try {
      const address = this.props.match.params.id;

      const web3 = await Session.getWeb3();
      const infoChain = await Session.getInfoChain();
      const contractOutput = await web3.contracts.getContractByAddress(infoChain.chain, address);
      if (!contractOutput) {
        toast.error('Contract not found');
        return;
      }

      const name = (await web3.contracts.readContract(infoChain.chain, address, 'name', [])).output;
      const totalSupply = (await web3.contracts.readContract(infoChain.chain, address, 'totalSupply', [])).output;

      let nfts = [];
      for (let i = 0; i < totalSupply; i++) {
        nfts.push({
          id: i,
          index: i,
          address: address,
        });
      }

      const contract = {
        address: contractOutput.output.contractAddress,
        abi: contractOutput.output.abi,
      }

      await this.setState({
        address,
        name,
        totalSupply,
        nfts,
        contract
      });
    } catch (err) {
      toast.error(err.message);
      return;
    }

    await this.setState({ loading: false });
  }

  deploySend = async (contractAddress, name, ...values) => {
    try {
      const web3 = await Session.getWeb3();
      const wallet = Session.getWallet();
      const infoChain = await Session.getInfoChain();

      const tx = await web3.transactions.buildSimpleTx(
        wallet,
        infoChain.chain,
        contractAddress,
        '0',
        TxType.TX_CONTRACT_EXE,
        [{
          method: name,
          inputs: values,
        }]
      );

      this.publishTransaction.publish(tx, {
        type: 'normal',
      }, (async (tx, output) => {

      }))
    } catch (err) {
      toast.error(err.message);
    }
  }

  deployRead = async (contractAddress, name, ...values) => {
    const infoChain = await Session.getInfoChain();
    this.publishTransaction.read(
      infoChain.chain,
      contractAddress,
      name,
      values,
    );
  }

  render() {
    if (this.state.loading) {
      return <Loading loading={true} />
    }
    return (<div className="page-padding">

      <SweetAlert
        show={this.state.alertShow}
        title={this.state.alert.title}
        onConfirm={() => this.setState({ alertShow: false })}
        confirmBtnCssClass="sweet-alert-confirm-button"
      >{this.state.alert.content}</SweetAlert>

      <PageTitle title={"nfts.title"} />

      <div className="plr-15">
        <div className="roe-card-style">
          <div className="roe-card-header">
            <span className="hash"># </span>
            <span><IntlMessages id="nfts.title" /></span>
          </div>
          <div className="roe-card-body">
            <div className="form-group row">
              {this.state.icon && <div className="col-sm-2">
                <IPFSIcon className="img-fluid" icon={this.state.icon} />
              </div>}
              <div className="col">
                <label className="col col-form-label hidden-overflow">
                  {"Name: " + this.state.name}
                </label>
                <br />
                <label className="col col-form-label hidden-overflow">
                  {"Address: " + this.state.address}
                </label>
                <br />
                <label className="col col-form-label hidden-overflow">
                  {"Total Supply: " + this.state.totalSupply}
                </label>
                <br />
                <label className="col col-form-label hidden-overflow">
                  {"Explorer: "}
                </label>
                <DataTable
                  columns={columns}
                  data={this.state.nfts}
                  defaultSortFieldId={1}
                  pagination
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="plr-15 mt-4">
        <div className="roe-card-style">
          <div className="roe-card-header">
            <span className="hash"># </span>
            <span><IntlMessages id="contract" /></span>
          </div>
          <div className="roe-card-body">
            <Contract
              address={this.state.contract.address}
              abi={this.state.contract.abi}
              send={this.deploySend}
              read={this.deployRead} />
          </div>
        </div>
      </div>
      <PublishTransaction ref={ref => this.publishTransaction = ref} />
    </div>);
  }
}

export default injectIntl(NFTItem);
