import React from "react";
import { injectIntl } from "react-intl";
import Loading from "components/common/Loading";
import IntlMessages from "util/intlMessages";
import PageTitle from "components/common/PageTitle";
import { toast } from "react-toastify";
import Api from "api/api";
import BlockchainStatus from "components/blockchainStatus/BlockchainStatus";
import NodeStatisticsWidget from "components/nodeStatistics/NodeStatisticsWidget";

class NodesItem extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      usage: [],
      node: {
        id: "",
        url: "",
        chain: "",
        address: "",
        location: "",
        status: "",
        plan: "",
        expire: "",
        created: ""
      }
    }
  }

  componentDidMount = () => {
    this.updateContract();
  }

  updateContract = async () => {
    this.setState({ loading: true });

    try {
      const id = this.props.match.params.id;

      let req = await Api.get(`nodes/item/${id}`);
      if (req.error) {
        return;
      }
      const node = req.data;

      let usage = [];

      if (node.status === 'running') {
        req = await Api.get(`nodes/usage/${id}`);
        if (req.error) {
          return;
        }
        usage = req.data;
      }

      await this.setState({
        node,
        usage,
        loading: false
      });
      return;

    } catch (err) {
      toast.error(err.message);
      return;
    }
  }

  render() {
    if (this.state.loading) {
      return <Loading loading={true} />
    }
    return (<div className="page-padding">
      <PageTitle title={"dashboard.my_nodes"} />

      <div className="plr-15">
        <div className="roe-card-style">
          <div className="roe-card-header">
            <span className="hash"># </span>
            <span><IntlMessages id="nodes.title" /></span>
          </div>
          <div className="roe-card-body">
            <div className="form-group row">
              <div className="col">
                <label className="col col-form-label hidden-overflow">
                  {"Chain ID: " + this.state.node.chain}
                </label>
                <br />
                <label className="col col-form-label hidden-overflow">
                  {"Address: " + this.state.node.address}
                </label>
                <br />
                <label className="col col-form-label hidden-overflow">
                  {"Status: "}
                  <BlockchainStatus status={this.state.node.status} />
                </label>
                <br />
                <label className="col col-form-label hidden-overflow">
                  {"Node: "}
                  <a href={`${this.state.node.url}`} target="_blank" rel="noopener noreferrer">
                    {`${this.state.node.url}`}
                  </a>
                </label>
                <br />
                <label className="col col-form-label hidden-overflow">
                  {"Expire: " + (new Date(this.state.node.expire)).toLocaleDateString()}
                </label>
                <br />
                <label className="col col-form-label hidden-overflow">
                  {"Location: " + this.state.node.location}
                </label>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>

      {this.state.node.status === 'running' && <>
        <NodeStatisticsWidget title="CPU" max={100} values={this.state.usage.map(u => u.cpu > 100 ? 100 : u.cpu)} timestamps={this.state.usage.map(u => u.timestamp)} />
        <NodeStatisticsWidget title="Memory (MB)" max={2000} values={this.state.usage.map(u => u.memory)} timestamps={this.state.usage.map(u => u.timestamp)} />
        <NodeStatisticsWidget title="Storage (MB)" max={1000} values={this.state.usage.map(u => Math.floor(u.size / (1024 * 1024)))} timestamps={this.state.usage.map(u => u.timestamp)} />
      </>}
    </div>);
  }
}

export default injectIntl(NodesItem);