import React from "react";
import { TableWrapper } from "./DefaultTable.style";

const DefaultTable = ({ id, data, title, actions, emptyWarn }) => {
  if (!id) throw new Error('set id propert')
  return (
    <div className="card roe-shadow-2 pa-25 table-responsive">
      <div className="row fs-18 header">
        <h4 className="col mb-0 fs-18 header">
          {title}
        </h4>
        {actions && <div className="col-sm col-sm-auto">{actions}</div>}
      </div>
      {data.rowData.length > 0 && <div className="overflow-auto">
        <TableWrapper className="table table-hover">
          <thead>
            <tr>
              {data.headerFields.map((field, i) => (
                <th key={`${id}-header-${i}`}>{field}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.rowData.map((row, i) => (
              <tr key={`${id}-row-${i}`}>
                {row.map((col, j) => <td key={`${id}-row-${i}-${j}`}>{col}</td>)}
              </tr>
            ))}
          </tbody>
        </TableWrapper>
      </div>}
      {data.rowData.length === 0 && <div>{emptyWarn}</div>}
    </div>
  );
};

export default DefaultTable;
