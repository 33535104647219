import React, { useState } from "react";
import { injectIntl } from "react-intl";
import { loginBack, bywiseIcon, ForgotIcon } from "helper/constant";
import { connect } from "react-redux";
import { compose } from "redux";
import AuthActions from "redux/auth/actions";
import { withRouter } from "react-router-dom";
import IntlMessages from "util/intlMessages";
import config, { getCurrentLanguage, setCurrentLanguage } from "settings/languageConfig";
import Api from "api/api";
import Auth from "api/auth";

const { login } = AuthActions;

const loginContainer = {
  backgroundImage: `url(${loginBack})`,
  backgroundPosition: "center center",
  backgroundSize: "cover",
  position: "fixed",
  overflow: "auto",
  top: 0,
  bottom: 0
};

const Signin = props => {
  const data = Auth.getPass();
  const [tab, setTab] = useState(0);
  const [email, setEmail] = useState(data ? data.email : '');
  const [selectLang, setSelectLang] = useState(false);
  const [remember, setRemember] = useState(data ? true : false);
  const [password, setPassword] = useState(data ? data.password : '');
  const [code, setCode] = useState("");
  const [error, setError] = useState({});

  const setLang = async (locale) => {
    setCurrentLanguage(locale);
    window.location.reload(false);
  }

  const isValid = () => {
    if (!code.match(/[0-9]{6}/) && tab === 1) {
      setError({
        code: <IntlMessages id="signin.invalid_code" />
      });
      return false;
    }
    setError({});
    return true;
  };

  const signin = async (token) => {
    Auth.saveToken(token);

    // get user
    let req = await Api.get(`auth/me`);
    if (req.error) {
      return;
    }
    const user = req.data.user;

    const locale = getCurrentLanguage().locale;
    await Api.post(`auth/lang`, {
      value: locale,
    });
    
    req = await Api.get(`addresses`);
    if (req.error) {
      return;
    }
    const addresses = req.data;
    
    let defaultAddress;
    addresses.forEach(addr => {
      if (addr.id === user.addressId) {
        defaultAddress = addr;
      }
    })

    req = await Api.get(`blockchains`);
    if (req.error) {
      return;
    }
    const chains = req.data;

    Auth.saveInfo({
      user,
      addresses,
      defaultAddress,
      chains,
    });

    props.history.push("/dashboard");
  }

  const handleRegister = async (e) => {
    e.preventDefault();
    let req;
    if (isValid()) {
      if (tab === 0) {
        if (remember) {
          Auth.rememberPass({
            email: email,
            password: password
          })
        } else {
          Auth.removePass();
        }
        req = await Api.post(`auth/signin`, {
          email: email,
          password: password
        });
        if (req.error) {
          setError({
            password: <IntlMessages id="signin.invalid_user_password" />
          });
          setError({
            email: <IntlMessages id="signin.invalid_user_password" />
          });
        } else {
          if (req.data.token) {
            signin(req.data.token);
          } else {
            setTab(1);
          }
        }
      } else if (tab === 1) {
        req = await Api.post(`auth/signin`, {
          email: email,
          twoFactor: code,
          password: password
        }, false);
        if (req.error) {
          setError({
            code: <IntlMessages id="signin.invalid_code" />
          });
        } else {
          signin(req.data.token);
        }
      }
    }
  };

  const Error = props => {
    const field1 = props.field;
    if (error[field1]) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {error[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  return (
    <div className="container-fluid" style={loginContainer}>
      <div
        className="page-home-lang-button"
        onClick={() => setSelectLang(true)}
      >
        {!selectLang && <img src={getCurrentLanguage().icon} width={40} height={40} alt='language icon' />}
        {selectLang && config.options.map(lang => <div
          key={lang.languageId}
          className="page-home-lang-list"
          onClick={() => setLang(lang.locale)}
        >
          <div className="d-flex">
            <img src={lang.icon} width={40} height={40} alt={lang.text} />
          </div>
        </div>)}
      </div>
      <div style={{
        marginTop: 60
      }}>
        {tab === 0 && (
          <>
            <div className="form-container">
              <div className="login-icon">
                <img src={bywiseIcon} alt="icon" height="100px" />
              </div>
              <div className="login-title mb-2">
                <IntlMessages id="signin.title" />
              </div>
              <form className="pa-24">
                <div className="form-group">
                  <label>
                    <IntlMessages id="signin.email" />
                  </label>
                  <input
                    type="email"
                    className="form-control react-form-input"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                  />
                  <Error field="email" />
                </div>

                <div className="form-group">
                  <div className="row">
                    <div className="col">
                      <label>
                        <IntlMessages id="signin.password" />
                      </label>
                    </div>
                    <div className="col text-right link-label" onClick={() => props.history.push("/recover")} >
                      <IntlMessages id="signin.recover" />
                    </div>
                  </div>
                  <input
                    type="password"
                    className="form-control react-form-input"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                  />
                  <Error field="password" />
                </div>

                <div className="form-check mtb-16">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="exampleCheck1"
                    checked={remember}
                    onChange={() => setRemember(!remember)}
                  />
                  <label
                    className="form-check-label register-privacy-text"
                    htmlFor="exampleCheck1"
                  >
                    <IntlMessages id="signin.remember" />
                  </label>
                </div>

                <button className="btn form-button mt-16" onClick={handleRegister}>
                  <IntlMessages id="connect" />
                </button>

                <div
                  className="btn form-button c-info mt-16"
                  onClick={() => props.history.push("/signup")}
                >
                  <IntlMessages id="signin.signup" />
                </div>
                <div className="row mt-5 pt-5">
                  <div className="col text-center">
                    <small>
                      <IntlMessages id="signup.termsDescSignin" values={{
                        termsOfService: <a href="/terms-of-service"><IntlMessages id="signup.termsOfService" /></a>,
                        privacyPolicy: <a href="/privacy-policy"><IntlMessages id="signup.privacyPolicy" /></a>,
                      }} />
                    </small>
                  </div>
                </div>
              </form>
            </div>
          </>
        )}
        {tab === 1 && (
          <>
            <div className="form-container">
              <form className="pa-24">
                <button
                  type="submit"
                  className="btn form-back p-3"
                  onClick={() => setTab(0)}
                >
                  <i className="fas fa-arrow-left"></i>
                </button>

                <div className="login-icon lock-screen-profile mb-4">
                  <img src={ForgotIcon} alt="icon" height="100px" />
                </div>

                <div className="login-title mb-4">2FA</div>

                <div className="form-info-text mt-16">
                  <IntlMessages id="signin.2fa_text" />
                </div>

                <div className="form-group mt-16">
                  <label>
                    <IntlMessages id="signin.code" />
                  </label>
                  <input
                    type="text"
                    className="form-control react-form-input"
                    value={code}
                    placeholder="000000"
                    onChange={e => setCode(e.target.value)}
                  />
                  <Error field="code" />
                </div>

                <button className="btn form-button" onClick={handleRegister}>
                  <IntlMessages id="connect" />
                </button>
              </form>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default compose(
  injectIntl,
  withRouter,
  connect(null, { login })
)(Signin);
