import React from "react";
import { Line } from "react-chartjs-2";
import customTooltip from "components/common/chartTooltip";

const NodeStatisticsWidget = ({ title, timestamps, values, max }) => {

  return (
    <div className="plr-15 mt-20">
      <div className="roe-card-style roe-shadow-2">
        <div className="roe-card-style">
          <div className="roe-card-header">
            <span>{title}</span>
          </div>
          <div className="roe-card-body">
            <div className="analytics">
              <Line data={{
                labels: timestamps.map(timestamp => (new Date(timestamp * 1000)).toLocaleTimeString()),
                datasets: [{
                  label: title,
                  fill: true,
                  data: values,
                  backgroundColor: "rgba(92, 37, 141, 0.06)",
                  borderColor: "#5C258D",
                  shadowColor: "rgba(0, 196, 134, 0.5)",
                  shadowOffsetX: 3,
                  shadowOffsetY: 3,
                  shadowBlur: 10,
                  pointRadius: 0,
                  lineTension: 0.3,
                }]
              }} height={240} options={{
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                  display: false
                },
                hover: {
                  mode: "nearest",
                  intersect: true
                },
                spanGaps: true,
                tooltips: {
                  enabled: false,
                  custom: customTooltip
                },
                scales: {
                  xAxes: [
                    {
                      display: true,
                      scaleLabel: {
                        display: false,
                        labelString: "Month"
                      },
                      ticks: {
                        display: true,
                        fontColor: "#6c757d",
                        color: "#6c757d",
                        zeroLineColor: "#6c757d",
                        fontStyle: "bold"
                      },
                      gridLines: {
                        display: true,
                        color: "rgba(0,0,0, 0.1)",
                        zeroLineColor: "#6c757d"
                      }
                    }
                  ],
                  yAxes: [
                    {
                      display: true,
                      scaleLabel: {
                        display: false,
                        labelString: "Value"
                      },
                      ticks: {
                        beginAtZero: true,
                        max: max,
                        min: 0,
                        display: true,
                        fontColor: "#6c757d",
                        fontStyle: "bold"
                      },
                      gridLines: {
                        display: false,
                        color: "rgba(0,0,0, 0.1)",
                        zeroLineColor: "#6c757d"
                      }
                    }
                  ]
                }
              }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NodeStatisticsWidget;
