import React from "react";
import { locakscreenBack } from "helper/constant";

const Privacy = props => {

  const loginContainer = {
    backgroundImage: `url(${locakscreenBack})`,
    backgroundPosition: "center center",
    backgroundSize: "cover",
    position: "fixed",
    overflow: "auto",
    top: 0,
    bottom: 0
  };

  return (
    <div className="container-fluid pricing-page" style={loginContainer}>
      <div className="Pricing-title text-center">
        <span className="mr-4 pr-4">
          Política de Privacidade
        </span>
      </div>
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="terms">

              <div class="w-richtext">
                <p><strong>Versão:</strong> 1.0 <br/><strong>Revisão em:</strong> 10 de agosto de 2023</p>
                <p>Esta política de privacidade (“<strong>Política</strong>”) descreve como a Bywise (“<strong>Bywise</strong>” “<strong>Empresa</strong>”, “<strong>nosso</strong>” ou “<strong>nós</strong>”) coleta, usa, compartilha e armazena informações pessoais dos usuários destes sites, <a href="https://bywise.org/">https://bywise.org/</a> e <a href="https://dashboard.bywise.org">https://dashboard.bywise.org</a> (os “ <strong>Sites</strong> ”). Esta Política se aplica aos Sites, aplicativos, produtos e serviços (coletivamente, “ <strong>Serviços</strong> ”) nos quais é publicada, vinculada ou referenciada.</p>
                <p>Ao utilizar os Serviços, você aceita os termos desta Política e nossos Termos de Uso e concorda com nossa coleta, uso, divulgação e retenção de suas informações conforme descrito nesta Política. Se ainda não o fez, revise também nossos termos de uso. Os termos de uso contêm disposições que limitam nossa responsabilidade para com você e exigem que você resolva qualquer disputa conosco individualmente e não como parte de qualquer ação coletiva ou representativa. SE VOCÊ NÃO CONCORDA COM QUALQUER PARTE DESTA POLÍTICA DE PRIVACIDADE OU NOSSOS TERMOS DE USO, NÃO USE NENHUM DOS SERVIÇOS.</p>
                <p>Observe que esta Política não se aplica a informações coletadas por meio de sites ou serviços de terceiros que você possa acessar por meio dos Serviços ou que você nos envie por e-mail, mensagem de texto ou outra mensagem eletrônica ou offline.</p>
                <p>Se você estiver visitando esses Sites a partir da União Europeia (UE), consulte nosso Aviso aos Titulares de Dados da UE para conhecer nossas bases jurídicas para processamento e transferência de seus dados.</p>
                <h2>O que coletamos</h2>
                <p>Obtemos informações sobre você de diversas maneiras.</p>
                <h5>Informações que você nos fornece</h5>
                <p><strong>‍ As informações</strong> que coletamos de você incluem:</p>
                <ul>
                  <li><strong>Seu endereço de email;</strong></li>
                  <li><strong>Seu número de telefone;</strong></li>
                  <li>‍<strong>Sua região geográfica padrão preferida.</strong></li>
                  <li>Informações de perfil, como seus interesses, preferências, feedback e respostas a pesquisas</li>
                  <li>Feedback e correspondência, como informações que você fornece em suas respostas a pesquisas, quando participa de atividades de pesquisa de mercado, relata um problema com o Serviço, recebe suporte ao cliente ou se corresponde de outra forma conosco</li>
                  <li>Informações de uso, como informações sobre como você usa o Serviço e interage conosco</li>
                  <li>Informações de marketing, como suas preferências para receber comunicações de marketing e detalhes sobre como você interage com elas.</li>
                </ul>
                <h5>Informações que obtemos de terceiros</h5>
                <p><strong>‍ Podemos</strong> obter informações sobre você de outras fontes de terceiros e podemos adicioná-las às informações que obtemos pelo seu uso dos Serviços.</p>
                <h5>Informações coletadas automaticamente</h5>
                <p><strong>‍ Podemos</strong> registrar automaticamente certas informações sobre como você usa nossos Sites ou Serviços (nos referimos a essas informações como “Dados de Registro“). Os Dados de Registro podem incluir informações como o endereço de Protocolo de Internet (IP) de um usuário, tipo de dispositivo e navegador, sistema operacional, as páginas ou recursos de nossos Sites nos quais o usuário navegou e o tempo gasto nessas páginas ou recursos, a frequência com que os Sites são usados ​​por um usuário, os termos de pesquisa, os links em nossos Sites nos quais um usuário clicou ou usou e outras estatísticas. Usamos essas informações para administrar o Serviço e analisamos (e podemos contratar terceiros para analisar) essas informações para melhorar e aprimorar o Serviço, expandindo seus recursos e funcionalidades e adaptando-o às necessidades e preferências de nossos usuários.</p>
                <p>Poderemos utilizar cookies ou tecnologias semelhantes para analisar tendências, administrar o website, rastrear os movimentos dos utilizadores no website e recolher informações demográficas sobre a nossa base de utilizadores como um todo. Os usuários podem controlar o uso de cookies no nível do navegador individual. Para mais informações, consulte a seção intitulada “Política de Cookies” abaixo.</p>
                <h2>Uso de informações pessoais</h2>
                <h5>Para fornecer nosso serviço</h5>
                <p>Usaremos suas informações pessoais das seguintes maneiras:</p>
                <ul>
                  <li>Para permitir que você acesse e use os Serviços</li>
                  <li>Para fornecer e entregar produtos e serviços que você possa solicitar.</li>
                  <li>Para enviar informações, incluindo confirmações, avisos técnicos, atualizações, alertas de segurança e mensagens administrativas e de suporte.</li>
                </ul>
                <h5>Para cumprir a lei</h5>
                <p>Usamos suas informações pessoais conforme acreditamos ser necessário ou apropriado para cumprir as leis aplicáveis, solicitações legais e processos legais, como para responder a intimações ou solicitações de autoridades governamentais. &nbsp;</p>
                <h5>Para se comunicar com você</h5>
                <p>Usamos suas informações pessoais para comunicar sobre promoções, eventos futuros e outras novidades sobre produtos e serviços oferecidos por nós e por nossos parceiros selecionados.</p>
                <h5>Para otimizar nossa plataforma</h5>
                <p>Para otimizar sua experiência de usuário, poderemos usar suas informações pessoais para operar, manter e melhorar nossos Serviços. Também poderemos usar suas informações para responder aos seus comentários e perguntas sobre os Serviços e para fornecer a você e a outros usuários atendimento geral ao cliente.</p>
                <h5>Com o seu consentimento</h5>
                <p>Poderemos usar ou compartilhar suas informações pessoais com o seu consentimento, como quando você nos instrui a tomar uma ação específica com relação às suas informações pessoais ou quando você opta por receber comunicações de marketing de terceiros.</p>
                <h5>Para conformidade, prevenção de fraude e segurança</h5>
                <p>Podemos usar suas informações pessoais para proteger, investigar e impedir atividades fraudulentas, não autorizadas ou ilegais.</p>
                <h2>Compartilhamento de informações pessoais</h2>
                <p>Não compartilhamos nem vendemos as informações pessoais que você nos fornece a outras organizações sem o seu consentimento expresso, exceto conforme descrito nesta Política de Privacidade. Divulgamos informações pessoais a terceiros nas seguintes circunstâncias:</p>
                <ul>
                  <li><strong>Afiliados. </strong>Poderemos divulgar suas informações pessoais às nossas subsidiárias e afiliadas corporativas para fins consistentes com esta Política de Privacidade.</li>
                  <li><strong>Transferências Empresariais. </strong>Podemos compartilhar informações pessoais quando fazemos um acordo comercial, ou negociamos um acordo comercial, envolvendo a venda ou transferência de todo ou parte de nossos negócios ou ativos. Esses negócios podem incluir qualquer transação ou processo de fusão, financiamento, aquisição ou falência.</li>
                  <li><strong>Cumprimento das Leis e Aplicação da Lei; Proteção e Segurança. </strong>Podemos compartilhar informações pessoais para fins legais, de proteção e de segurança.</li>
                  <li>Podemos compartilhar informações para cumprir as leis.</li>
                  <li>Podemos compartilhar informações para responder a solicitações legais e processos legais.</li>
                  <li>Poderemos compartilhar informações para proteger os direitos e a propriedade da Empresa, de nossos agentes, clientes e outros. Isso inclui fazer cumprir nossos acordos, políticas e termos de uso.</li>
                  <li>Podemos compartilhar informações em caso de emergência. Isso inclui proteger a segurança de nossos funcionários e agentes, de nossos clientes ou de qualquer pessoa.</li>
                  <li><strong>Consultores profissionais e prestadores de serviços. </strong>Podemos compartilhar informações com aqueles que precisam delas para trabalhar para nós. Esses destinatários podem incluir empresas e indivíduos terceirizados para administrar e fornecer o Serviço em nosso nome (como suporte ao cliente, hospedagem, entrega de e-mail e serviços de gerenciamento de banco de dados), bem como advogados, banqueiros, auditores e seguradoras.</li>
                  <li><strong>Outro. </strong>Você pode nos permitir compartilhar suas informações pessoais com outras empresas ou entidades de sua escolha. Essas utilizações estarão sujeitas às políticas de privacidade da entidade ou entidades destinatárias.</li>
                </ul>
                <p>Também poderemos compartilhar dados agregados e/ou anonimizados com terceiros para uso próprio.</p>
                <h2>Transferência internacional</h2>
                <p>A Empresa possui escritórios fora da UE e possui afiliadas e prestadores de serviços nos Estados Unidos e em outros países. Suas informações pessoais podem ser transferidas de ou para os Estados Unidos ou outros locais fora de seu estado, província, país ou outra jurisdição governamental, onde as leis de privacidade podem não ser tão protetoras quanto as de sua jurisdição.</p>
                <p>Os utilizadores da UE devem ler as informações importantes fornecidas abaixo sobre a transferência de informações pessoais para fora do Espaço Económico Europeu (EEE).</p>
                <h2>Como as informações são protegidas</h2>
                <p>Retemos as informações que coletamos enquanto forem necessárias e relevantes para cumprir os propósitos descritos nesta política de privacidade. Além disso, retemos informações pessoais para cumprir a lei aplicável quando necessário, prevenir fraudes, resolver disputas, solucionar problemas, ajudar em qualquer investigação, fazer cumprir nossos Termos de Uso e outras ações permitidas por lei. Para determinar o período de retenção apropriado para informações pessoais, consideramos a quantidade, natureza e sensibilidade das informações pessoais, o risco potencial de danos decorrentes do uso ou divulgação não autorizada de suas informações pessoais, os propósitos para os quais processamos suas informações pessoais e se podemos atingir esses objetivos por outros meios e pelos requisitos legais aplicáveis.</p>
                <p>Em algumas circunstâncias, poderemos anonimizar as suas informações pessoais (para que não possam mais ser associadas a você), caso em que poderemos usar essas informações indefinidamente sem aviso prévio.</p>
                <p>Empregamos medidas de segurança padrão da indústria projetadas para proteger a segurança de todas as informações enviadas por meio dos Serviços. No entanto, a segurança das informações transmitidas pela Internet nunca pode ser garantida. Não nos responsabilizamos por qualquer intercepção ou interrupção de quaisquer comunicações através da Internet ou por alterações ou perdas de dados. Os usuários dos Serviços são responsáveis ​​por manter a segurança de qualquer senha, biometria, ID de usuário ou outra forma de autenticação envolvida na obtenção de acesso a áreas seguras ou protegidas por senha de qualquer um de nossos serviços digitais. Para proteger você e seus dados, poderemos suspender o uso de qualquer um dos Serviços, sem aviso prévio, enquanto se aguarda uma investigação, se houver suspeita de qualquer violação de segurança.</p>
                <h2>Escolhas e mudanças de informações</h2>
                <h5>Acessando, atualizando, corrigindo e excluindo suas informações</h5>
                <p>Você pode acessar informações que forneceu voluntariamente por meio de sua conta nos Serviços e revisá-las, corrigi-las ou excluí-las enviando uma solicitação para support@bywise.org. Você pode solicitar a alteração das opções de contato, cancelar nosso compartilhamento com outras pessoas e atualizar suas informações e preferências pessoais. A lei aplicável pode exigir ou permitir-nos recusar o seu pedido.</p>
                <h5>Tecnologias de rastreamento em geral</h5>
                <p>Os cookies regulares podem geralmente ser desativados ou removidos por ferramentas disponíveis na maioria dos navegadores comerciais e, em alguns casos, bloqueados no futuro através da seleção de determinadas configurações. Para mais informações, consulte a seção intitulada “Política de Cookies” abaixo.</p>
                <h5> <strong>Informações de contato</strong></h5>
                <p><strong>‍ Agradecemos</strong> seus comentários ou perguntas sobre esta Política e você pode entrar em contato conosco em: <a href="mailto:support@bywise.org">support@bywise.org</a>.</p>
                <h5>Alterações nesta Política de Privacidade</h5>
                <p><strong>‍ Podemos</strong> alterar esta política de privacidade a qualquer momento. Recomendamos que você revise periodicamente esta página para obter as informações mais recentes sobre nossas práticas de privacidade. Se fizermos alguma alteração, alteraremos a data da última atualização acima.</p>
                <p>Quaisquer modificações nesta Política de Privacidade entrarão em vigor após a publicação dos novos termos e/ou após a implementação das alterações nos Sites (ou conforme indicado de outra forma no momento da publicação). Em todos os casos, o uso continuado dos Sites ou Serviços após a publicação de qualquer Política de Privacidade modificada indica sua aceitação dos termos da Política de Privacidade modificada.</p>
                <h2>Elegibilidade</h2>
                <p>Se você for menor de idade em sua jurisdição de residência, poderá usar os Serviços somente com o consentimento ou sob a supervisão de seus pais ou responsável legal. De acordo com os requisitos da Lei de Proteção à Privacidade Online das Crianças (COPPA), se soubermos que recebemos qualquer informação diretamente de uma criança menor de 13 anos sem primeiro receber o consentimento verificado de seus pais, usaremos essas informações apenas para responder diretamente a essa criança (ou a seus pais ou responsável legal) para informá-la de que ela não pode usar os Sites e, posteriormente, excluiremos essas informações.</p>
                <h2>Aviso aos titulares de dados da Brasil</h2>
                <h5>Informações pessoais</h5>
                <p>No que diz respeito aos titulares de dados da Brasil, “informações pessoais”, conforme utilizadas nesta Política de Privacidade, são equivalentes a <br/>“dados pessoais”, conforme definido na (LGPD).</p>
                <h5>Dados sensíveis</h5>
                <p>Algumas das informações que você nos fornece podem constituir dados confidenciais conforme definido no LGPD (também chamados de categorias especiais de dados pessoais), incluindo a identificação de sua raça ou etnia em documentos de identificação emitidos pelo governo.</p>
                <h5>Bases Legais para Processamento</h5>
                <p>Só usamos suas informações pessoais conforme permitido por lei. Somos obrigados a informá-lo sobre as bases legais do processamento de suas informações pessoais, descritas na tabela abaixo. Se você tiver dúvidas sobre as bases legais sob as quais processamos suas informações pessoais, entre em contato conosco pelo e-mail support@bywise.org.</p>
                <ul>
                  <li>Para se comunicar com você</li>
                  <li>Para otimizar nossa plataforma</li>
                  <li>Para conformidade, prevenção de fraudes e segurança</li>
                  <li>Para fornecer nosso serviço</li>
                </ul>
                <p>Estas atividades de processamento constituem os nossos interesses legítimos. Garantimos que consideramos e equilibramos quaisquer impactos potenciais sobre você (tanto positivos quanto negativos) e seus direitos antes de processarmos suas informações pessoais para nossos interesses legítimos. Não usamos suas informações pessoais para atividades em que nossos interesses sejam anulados por qualquer impacto adverso sobre você (a menos que tenhamos seu consentimento ou sejamos exigidos ou permitidos por lei). Para cumprir a lei Usamos suas informações pessoais para cumprir as leis aplicáveis. e nossas obrigações legais.Com o seu consentimentoQuando o uso de suas informações pessoais é baseado no seu consentimento, você tem o direito de retirá-lo a qualquer momento da maneira indicada no Serviço ou entrando em contato conosco pelo e-mail support@bywise.org.</p>
                <h5>Use para novos propósitos</h5>
                <p>Poderemos usar suas informações pessoais por motivos não descritos nesta Política de Privacidade, quando formos permitidos por lei para fazê-lo e quando o motivo for compatível com a finalidade para a qual as coletamos. Se necessitarmos de utilizar as suas informações pessoais para uma finalidade não relacionada, iremos notificá-lo e explicar-lhe-emos a base legal aplicável para essa utilização. Se tivermos contado com o seu consentimento para uma utilização específica das suas informações pessoais, solicitaremos o seu consentimento para qualquer finalidade não relacionada.</p>
                <h5>Seus direitos</h5>
                <p>De acordo com o LGPD, você tem certos direitos em relação às suas informações pessoais. Você pode nos solicitar as seguintes ações em relação às suas informações pessoais que mantemos:</p>
                <ul>
                  <li><strong>Desativar</strong> . Pare de lhe enviar comunicações de marketing direto que você consentiu anteriormente em receber. Poderemos continuar a enviar-lhe comunicações relacionadas com o Serviço e outras comunicações não relacionadas com marketing.</li>
                  <li><strong>Acesso</strong> . Fornecer-lhe informações sobre o nosso processamento das suas informações pessoais e dar-lhe acesso às suas informações pessoais.</li>
                  <li><strong>Correto</strong> . Atualizar ou corrigir imprecisões em suas informações pessoais.</li>
                  <li><strong>Excluir</strong> . Exclua suas informações pessoais.</li>
                  <li><strong>Transferência</strong> . Transferir uma cópia legível por máquina de suas informações pessoais para você ou para um terceiro de sua escolha.</li>
                  <li><strong>Restringir</strong> . Restringir o processamento de suas informações pessoais.</li>
                  <li><strong>Objeto</strong> . Oponha-se à nossa confiança em nossos interesses legítimos como base para o processamento de suas informações pessoais que impactam seus direitos.</li>
                </ul>
                <p>Você pode enviar essas solicitações por e-mail para support@bywise.org. Poderemos solicitar informações específicas suas para nos ajudar a confirmar sua identidade e processar sua solicitação. A lei aplicável pode exigir ou permitir-nos recusar o seu pedido. Se recusarmos o seu pedido, informaremos o motivo, sujeito a restrições legais. Se desejar enviar uma reclamação sobre o uso de suas informações pessoais ou responder às suas solicitações relacionadas às suas informações pessoais, você pode entrar em contato conosco pelo e-mail support@bywise.org ou enviar uma reclamação ao regulador de proteção de dados em sua jurisdição. Você pode encontrar o seu regulador de proteção de dados <a href="http://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm">aqui</a> .</p>
                <h5>Transferência de dados transfronteiriça</h5>
                <p>Esteja ciente de que seus dados pessoais podem ser transferidos, processados ​​e armazenados nos Estados Unidos ou em outro país diferente do seu país de residência. As leis de proteção de dados nos EUA ou em outro país podem ser diferentes daquelas do seu país de residência. Você concorda com a transferência de suas informações, incluindo informações pessoais, para os EUA ou outro país, conforme estabelecido nesta Política de Privacidade, visitando nosso site ou usando nosso serviço.</p>
                <p>Sempre que transferirmos as suas informações pessoais para fora do EEE para os EUA ou países que não sejam considerados pela Comissão Europeia como fornecendo um nível adequado de proteção de informações pessoais, a transferência será baseada num mecanismo de transferência de dados reconhecido pela Comissão Europeia como fornecendo proteção adequada para informações pessoais.</p>
                <p>Entre em contato conosco se desejar mais informações sobre o mecanismo específico usado por nós ao transferir suas informações pessoais para fora do EEE.</p>
                <h2>Política de Cookies</h2>
                <p>Entendemos que a sua privacidade é importante para você e estamos comprometidos em ser transparentes sobre as tecnologias que utilizamos. Num espírito de transparência, esta política fornece informações detalhadas sobre como e quando utilizamos cookies no nosso Site.</p>
                <h5>Usamos Cookies?</h5>
                <p>Sim. Nós e nossos parceiros de marketing, afiliados e provedores de análises ou serviços usamos cookies, web beacons ou pixels e outras tecnologias para garantir que todos que usam os Sites tenham a melhor experiência possível.</p>
                <h5>O que é um cookies?</h5>
                <p>Um cookie (“Cookie”) é um pequeno arquivo de texto colocado no seu disco rígido por um servidor de páginas da web. Os cookies contêm informações que podem ser lidas posteriormente por um servidor web no domínio que emitiu o cookie para você. Alguns dos cookies só serão utilizados se utilizar determinadas funcionalidades ou selecionar determinadas preferências, e alguns cookies serão sempre utilizados. Você pode descobrir mais sobre cada cookie visualizando nossa lista atual de cookies abaixo. Atualizamos esta lista periodicamente, portanto pode haver cookies adicionais que ainda não estão listados. Web beacons, tags e scripts podem ser usados ​​no Site ou em e-mails para nos ajudar a entregar cookies, contar visitas, entender o uso e a eficácia da campanha e determinar se um e-mail foi aberto e executado.</p>
                <h5>Por que usamos cookies?</h5>
                <p>Geralmente utilizamos Cookies para os seguintes fins:</p>
                <ul>
                  <li>Para reconhecer clientes novos ou anteriores.</li>
                  <li>Para armazenar sua senha caso você esteja cadastrado em nosso Site.</li>
                  <li>Para melhorar nossos Sites e entender melhor suas visitas em nossas plataformas e Site.</li>
                  <li>Para atendê-lo com publicidade direcionada ou baseada em interesses.</li>
                  <li>Para observar seus comportamentos e atividades de navegação ao longo do tempo em vários sites ou outras plataformas.</li>
                  <li>Para compreender melhor os interesses dos nossos clientes e dos visitantes do nosso site.</li>
                </ul>
                <p>Alguns Cookies são necessários para determinados usos do Site e, sem esses Cookies, não seríamos capazes de fornecer muitos serviços necessários para o uso adequado do Site. Esses Cookies, por exemplo, nos permitem operar nossos Sites para que você possa acessá-los conforme solicitado e nos permitem reconhecer que você criou uma conta e fez login nessa conta para acessar o conteúdo do Site. Eles também incluem Cookies que nos permitem lembrar suas ações anteriores na mesma sessão de navegação e proteger nossos Sites. &nbsp;</p>
                <p>Também utilizamos Cookies funcionais e Cookies de terceiros para fins de análise e marketing. Os Cookies Funcionais permitem que certas partes do site funcionem corretamente e que as suas preferências de utilizador permaneçam conhecidas. Os Cookies de Análise, entre outras coisas, recolhem informações sobre como os visitantes utilizam o nosso Site, o conteúdo e os produtos que os utilizadores visualizam com mais frequência e a eficácia da nossa publicidade de terceiros. Os cookies de publicidade ajudam a entregar anúncios a públicos relevantes e a fazer com que os nossos anúncios apareçam no topo dos resultados de pesquisa. Os cookies são cookies de “sessão” que são excluídos quando você encerra a sessão do navegador, ou “persistentes”, que permanecem até serem excluídos por você (discutido abaixo) ou pela parte que serviu o cookie. Detalhes completos sobre todos os Cookies usados ​​no Site estão disponíveis em nossa tabela de Divulgação de Cookies abaixo.</p>
                <h5>Como desativar cookies</h5>
                <p>Geralmente, você pode ativar ou desativar posteriormente o uso de cookies através de uma funcionalidade integrada no seu navegador. Para saber mais sobre como controlar as configurações de cookies através do seu navegador:</p>
                <ul>
                  <li>Clique <a href="https://support.mozilla.org/en-US/kb/block-websites-storing-cookies-site-data-firefox">aqui</a> para saber mais sobre a configuração de “Navegação Privada” e como gerenciar configurações de cookies no Firefox;</li>
                  <li>Clique <a href="https://support.google.com/chrome/answer/95647?hl=en">aqui</a> para saber mais sobre “Incógnito” e gerenciamento de configurações de cookies no Chrome;</li>
                  <li>Clique <a href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies">aqui</a> para saber mais sobre o “InPrivate” e como gerenciar configurações de cookies no Internet Explorer; ou</li>
                  <li>Clique <a href="https://support.apple.com/en-us/HT201265">aqui</a> para saber mais sobre “Navegação Privada” e gerenciamento de configurações de cookies no Safari.</li>
                </ul>
                <p>Se quiser saber mais sobre cookies ou como controlá-los, desativá-los ou excluí-los, visite <a href="https://www.aboutcookies.org/">https://www.aboutcookies.org</a> para obter orientações detalhadas. Além disso, certas redes de publicidade de terceiros, incluindo o Google, permitem que os usuários optem por não participar ou personalizem preferências associadas à sua navegação na Internet. Para saber mais sobre esse recurso do Google, clique <a href="https://adssettings.google.com/u/0/authenticated?hl=en">aqui</a> .</p>
                <p>Para controlar os cookies flash, que podemos usar em nossos Sites de tempos em tempos, você pode acessar este <a href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html">link</a> porque os cookies Flash não podem ser controlados através das configurações do seu navegador. Tenha em atenção que se recusar a utilização de Cookies, algumas funções dos websites poderão ficar indisponíveis e não poderemos apresentar-lhe conteúdos e anúncios personalizados.</p>
                <p>Poderemos vincular as informações coletadas pelos Cookies com outras informações que coletamos de você de acordo com esta Política de Privacidade e usar as informações combinadas conforme estabelecido aqui. Da mesma forma, terceiros que disponibilizam cookies em nossos Sites podem vincular seu nome ou endereço de e-mail a outras informações coletadas, que podem incluir compras anteriores feitas off-line ou on-line, ou suas informações de uso on-line. Se estiver localizado no Espaço Económico Europeu, tem determinados direitos descritos acima no título “Aviso aos titulares de dados da UE”, incluindo o direito de inspecionar e corrigir ou eliminar os dados que temos sobre si.</p>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default Privacy;
