import React, { useState } from "react";
import { connect } from "react-redux";
import PageTitle from "components/common/PageTitle";
import IntlMessages from "util/intlMessages";
import { Spinner } from "reactstrap";
import Loading from "components/common/Loading";
import Api from "api/api";
import Auth from "api/auth";
import { toast } from "react-toastify";

const buttonBack = {
  backgroundColor: '#563c91',
  color: 'white'
};

const Support = (props) => {
  const [subject, setSubject] = useState("");
  const [text, setText] = useState("");
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);

  const isValid = () => {
    if (subject.trim().length === 0) {
      setError({
        subject: <IntlMessages id="invalid_field" />
      });
      return false;
    }
    if (text.trim().length === 0) {
      setError({
        text: <IntlMessages id="invalid_field" />
      });
      return false;
    }
    setError({});
    return true;
  };

  const handleSend = async (e) => {
    setLoading(true);
    e.preventDefault();
    if (isValid()) {
      const infoUser = Auth.getInfo();
      const req = await Api.post(`support/ticket`, {
        email: infoUser.user.email,
        text: `Subject: ${subject}\nContent: ${text}`
      });
      console.log(infoUser);
      if (!req.error) {
        toast.success(`Success`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
    setLoading(false);
  };

  const Error = props => {
    const field1 = props.field;
    if (error[field1]) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {error[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  return (
    <div className="page-padding">
      <Loading />
      <PageTitle title="support" />
      <div className="plr-15">
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6">

            <div className="roe-card-style">
              <div className="roe-card-header">
                <span className="hash"># </span>
                <IntlMessages id="support.title" />
              </div>
              <div className="roe-card-body">
                <form>
                  <div className="form-group">
                    <IntlMessages id="support.subject" />
                    <input
                      type="text"
                      className="form-control react-form-input"
                      value={subject}
                      onChange={e => setSubject(e.target.value)}
                    />
                    <Error field="subject" />
                  </div>

                  <div className="form-group">
                    <IntlMessages id="support.text" />
                    <textarea
                      rows="5"
                      className="form-control react-form-input"
                      value={text}
                      onChange={e => setText(e.target.value)}
                    />
                    <Error field="text" />
                  </div>

                  <button
                    disabled={loading}
                    style={buttonBack}
                    type="submit"
                    className="btn form-button"
                    onClick={handleSend}
                  >
                    {loading && <Spinner color="primary" />}
                    {!loading && <IntlMessages id="send" />}
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div className="col-md-3"></div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default connect(mapStateToProps, null)(Support);
