import { toast } from 'react-toastify';
import Auth from "./auth"
import axios from 'axios';

const API = process.env.REACT_APP_URL;

const makeRequest = async (endpoint, params, showError) => {
    const response = {
        error: 'Connection fail',
        data: 'Connection fail'
    };
    try {
        let req = await fetch(API + '/api/' + endpoint, params);
        let text = await req.text();
        let json = text !== '' ? JSON.parse(text) : {};
        response.data = json;
        if (req.status < 200 || req.status > 210) {
            response.error = json.error.message
            if (req.status === 401) {
                Auth.logout();
            }
        } else {
            response.error = ''
        }
    } catch (err) {
        console.error(err);
    }
    if (response.error && showError) {
        toast.error(`Error - ${response.error}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    return response;
}

export default class Api {
    static postFiles = async (
        endpoint = '',
        files = [],
        parameters = {},
        onUploadProgress = (p) => { },
    ) => {
        let formData = new FormData();
        files.forEach((file, i) => {
            formData.append(`file-${i + 1}`, file, file.name);
        });
        Object.entries(parameters).forEach(([key, value]) => {
            formData.append(key, value)
        })

        const req = await axios.post(API + '/api/' + endpoint, formData, {
            headers: {
                'Authorization': `Bearer ${Auth.getToken()}`,
                "Content-Type": "multipart/form-data",
            },
            onUploadProgress: data => {
                onUploadProgress(Math.round((100 * data.loaded) / data.total));
            },
        });
        if (req.status < 200 || req.status > 210) {
            throw new Error(req.statusText);
        } else {
            return req.data;
        }
    }

    static post = async (endpoint, params, showError = true) => {
        return await makeRequest(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${Auth.getToken()}`
            },
            body: JSON.stringify(params)
        }, showError);
    }

    static get = async (endpoint, params = {}, showError = true) => {
        let query = '';
        if (params) {
            let entries = Object.entries(params);
            entries = entries.map(([key, value]) => {
                if (typeof value === 'string' || typeof value === 'number') {
                    return `${key}=${value}`
                } else {
                    return `${key}=${JSON.stringify(value)}`
                }
            })
            query = '?' + encodeURI(entries.join('&'));
        }
        return await makeRequest(endpoint + query, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${Auth.getToken()}`
            },
        }, showError);
    }

    static patch = async (endpoint, params, showError = true) => {
        return await makeRequest(endpoint, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${Auth.getToken()}`
            },
            body: JSON.stringify(params)
        }, showError);
    }

    static del = async (endpoint, showError = true) => {
        return await makeRequest(endpoint, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${Auth.getToken()}`
            },
        }, showError);
    }

    static getURL = () => API

    /*static signin = async (email, password, savePassword) => {
        let req = await Api.post(`auth/signin`, {
            email: email,
            password: password
        });
        if (req.error) {
            return;
        }
        Auth.saveToken(req.data.token);

        // get user
        req = await Api.get(`auth/me`);
        if (req.error) {
            props.history.push("/signin");
        }
        const user = req.data.user;

        req = await Api.get(`addresses`);
        if (req.error) {
            props.history.push("/signin");
        }
        const addresses = req.data;

        let defaultAddress;
        addresses.forEach(addr => {
            if (addr.id === user.addressId) {
                defaultAddress = addr;
            }
        })

        req = await Api.get(`blockchains`);
        if (req.error) {
            props.history.push("/signin");
        }
        const chains = req.data;

        req = await Api.get(`watch-blockchains`);
        if (req.error) {
            props.history.push("/signin");
        }
        const watchBlockchains = req.data;

        Auth.saveUser({
            user,
            addresses,
            defaultAddress,
            chains,
            watchBlockchains,
            email: savePassword ? email : '',
            password: savePassword ? password : ''
        });

        props.history.push("/dashboard");
    }*/
}