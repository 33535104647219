
import React, { Component } from "react";
import { injectIntl } from "react-intl";
import Session from "util/Session";
import base64 from 'base-64';
import { BywiseHelper, Tx } from "@bywise/web3";
import IntlMessages from "util/intlMessages";
import { bywiseIcon } from "helper/constant";
import Loading from "components/common/Loading";
import { convertBWSToUSD } from "helper/methods";
import { Button, Spinner } from "reactstrap";
import { toast } from "react-toastify";

class ConfirmTransaction extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      tx: new Tx()
    }
  }

  componentDidMount = async () => {
    if (!window.opener) {
      toast.error('invalid opener');
    }
    const parans = (new URLSearchParams(this.props.location.search));
    if (!parans.has('tx')) {
      toast.error('tx no found');
      return;
    }
    try {
      const txString = parans.get('tx').replace(/ /g, '+');
      const txReceived = new Tx(JSON.parse(base64.decode(txString)));

      const web3 = await Session.getWeb3();
      const wallet = Session.getWallet();

      const tx = await web3.transactions.buildSimpleTx(
        wallet,
        txReceived.chain,
        txReceived.to,
        txReceived.amount,
        txReceived.type,
        txReceived.data
      )

      console.log(tx)
      this.setState({ loading: false, tx })
    } catch (err) {
      toast.error(err.message);
    }
  }

  formatAddress = (address = '') => {
    return `${address.substring(0, 12)}...${address.substring(address.length - 10)}`
  }

  send = async () => {
    this.setState({ loading: true });
    const web3 = await Session.getWeb3();
    try {
      const output = await web3.transactions.sendTransactionSync(this.state.tx);
      if (output.error) {
        toast.error(output.error);
      } else {
        window.opener.window.postMessage(JSON.stringify({
          message: 'success',
          tx: this.state.tx,
          output: output,
        }), '*');
        window.close();
      }
    } catch (err) {
      toast.error(err.message);
    }
    this.setState({ loading: false });
  }

  cancel = () => {
    window.opener.window.postMessage(JSON.stringify({
      message: 'canceled'
    }), '*');
    window.close();
  }

  render() {
    if (this.state.loading) {
      return <Loading loading={true} />
    }
    return (<div style={{
      width: '100%',
      position: "fixed",
      overflow: "auto",
      top: 0,
      bottom: 0
    }}>
      <div className="card-header">
        <img src={bywiseIcon} width={30} alt="Bywise" />
        {" "}
        <span><IntlMessages id="tx.transaction" /></span>
      </div>
      <div className="card-body">
        <div className="mb-2">
          <IntlMessages id="tx.transfer" />
        </div>
        {this.state.tx.to.map((addr, i) => <div key={`to-${i}`} className="card p-2 mb-2">
          <div className="flex-x">
            <div className="mr-2" style={{ fontSize: 30 }}>
              <i className="fas fa-arrow-up"></i>
            </div>
            <div className="flex-1">
              <div className="flex-x">
                <strong className="mr-auto"><IntlMessages id="send" /> BWS</strong>
                <strong>{this.state.tx.amount[i]} BWS</strong>
              </div>
              <div className="flex-x">
                <small className="mr-auto hidden-overflow">
                  <IntlMessages id="tx.to" />
                  {" "}
                  <a href={`${Session.getInfoChain().explorer}/address/${addr}`} target="_blank" rel="noopener noreferrer">
                    {this.formatAddress(addr)}
                  </a>
                </small>
                {convertBWSToUSD(this.state.tx.amount[i])}
              </div>
            </div>
          </div>
        </div>)}
        <div className="card p-2 mb-2">
          <div className="flex-x">
            <div className="mr-2" style={{ fontSize: 30 }}>
              <i className="fas fa-coins"></i>
            </div>
            <div className="flex-1">
              <div className="flex-x">
                <strong className="mr-auto"><IntlMessages id="fee" /></strong>
                <strong>{this.state.tx.fee} BWS</strong>
              </div>
              <div className="flex-x">
                <small className="mr-auto hidden-overflow">
                  <IntlMessages id="tx.to" />
                  {" "}
                  {this.formatAddress(BywiseHelper.ZERO_ADDRESS)}
                </small>
                {convertBWSToUSD(this.state.tx.fee)}
              </div>
            </div>
          </div>
        </div>
        <div className="mb-2">
          <IntlMessages id="tx.type" />
          {" "}
          <strong>{this.state.tx.type}</strong>
        </div>
        {this.state.tx.data && Array.isArray(this.state.tx.data) && this.state.tx.data.map((item, i) => <div key={`data-${i}`} className="card p-2 mb-2">
          <div className="flex-x">
            <div className="mr-2" style={{ fontSize: 30 }}>
              <i className="fas fa-scroll"></i>
            </div>
            <div className="flex-1">
              <div className="flex-x">
                <strong className="mr-auto">{item.method}</strong>
              </div>
              {item.inputs.map((value, j) => <div key={`${j}`} className="flex-x">
                <small className="c-text-alternate ml-4">{value}</small>
              </div>)}
            </div>
          </div>
        </div>)}
        {this.state.tx.data && !Array.isArray(this.state.tx.data) && <>
          <div className="card p-2 mb-2">
            <div className="flex-x">
              <div className="mr-2" style={{ fontSize: 30 }}>
                <i className="fas fa-scroll"></i>
              </div>
              <div className="flex-1">
                <div className="flex-x">
                  <strong className="mr-auto">Data:</strong>
                </div>
                <div className="flex-x">
                  <textarea
                    type="text"
                    rows={10}
                    disabled
                    className="form-control react-form-input"
                    value={JSON.stringify(this.state.tx.data, null, 2)}
                  />
                </div>
              </div>
            </div>
          </div>
        </>}
      </div>
      <div className="card-footer">
        <Button className="c-secondary" onClick={this.cancel}>
          {this.state.loading && <Spinner color="primary" />}
          {!this.state.loading && <span><IntlMessages id="cancel" /></span>}
        </Button>
        {" "}
        <Button className="c-primary" onClick={this.send}>
          {this.state.loading && <Spinner color="primary" />}
          {!this.state.loading && <span><IntlMessages id="send" /></span>}
        </Button>
      </div>
    </div>);
  }
}

export default injectIntl(ConfirmTransaction);