import React, { useState } from "react";
import { locakscreenBack } from "helper/constant";
import IntlMessages from "util/intlMessages";
import Api from "api/api";
import SweetAlert from 'react-bootstrap-sweetalert';
import { injectIntl } from "react-intl";
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import config, { getCurrentLanguage, setCurrentLanguage } from "settings/languageConfig";
import Auth from "api/auth";

const paypalClientId = process.env.REACT_APP_PAYPAL_CLIENT_ID;
if (!paypalClientId) throw new Error(`REACT_APP_PAYPAL_CLIENT_ID not defined`)

const initialOptions = {
  'client-id': paypalClientId,
  'vault': 'true',
  'intent': 'subscription',
};

const Buy = props => {
  const [form, setForm] = useState(false);
  const [packName, setPackName] = useState('');
  const [selectLang, setSelectLang] = useState(false);

  const loginContainer = {
    backgroundImage: `url(${locakscreenBack})`,
    backgroundPosition: "center center",
    backgroundSize: "cover",
    position: "fixed",
    overflow: "auto",
    top: 0,
    bottom: 0
  };

  const setLang = async (locale) => {
    setCurrentLanguage(locale);
    window.location.reload(false);
  }

  const showForm = async (planName) => {
    if(!Auth.isLogged()) {
      props.history.push("/signin")
    }
    console.log('planName', planName)
    setPackName(planName);
    setForm(true);
  }

  return (
    <div className="container-fluid pricing-page" style={loginContainer}>
      <SweetAlert
        showConfirm={false}
        showCancel={false}
        showCloseButton={true}
        title=""
        show={form}
        onConfirm={() => {}}
        onCancel={() => setForm(false)} >
        <div style={{
          marginTop: '30px'
        }}>
          <PayPalScriptProvider options={initialOptions}>
            <PayPalButtons
              style={{
                shape: 'rect',
                layout: 'vertical',
                label: 'subscribe'
              }}
              createSubscription={async (data, actions) => {
                const req = await Api.post('sales', {
                  plan: packName,
                })
                return req.data.value;
              }}
              onApprove={() => {
                props.history.goBack()
              }}
            />
          </PayPalScriptProvider>
        </div>
      </SweetAlert>
      <button
        className="page-home-button"
        onClick={() => props.history.goBack()}
      ><i className="fas fa-arrow-left"></i></button>
      <div
        className="page-home-lang-button"
        onClick={() => setSelectLang(true)}
      >
        {!selectLang && <img src={getCurrentLanguage().icon} width={50} height={50} alt='language icon' />}
        {selectLang && config.options.map(lang => <div
          key={lang.languageId}
          className="page-home-lang-list"
          onClick={() => setLang(lang.locale)}
        >
          <div className="d-flex">
            <img src={lang.icon} width={40} height={40} alt={lang.text} />
            <span className="pl-4">{lang.text}</span>
          </div>
        </div>)}
      </div>
      <div className="Pricing-title text-center">
        <span className="mr-4 pr-4">
          <IntlMessages id="wallet.buy" />
        </span>
      </div>
      <div className="pricing-detail">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <div className="pricing-card ptb-24">
                <div className="fs-34">Free</div>
                <div className="pink-highlight mb-20">
                  <span className="fs-34"> <IntlMessages id="buy.price_plan1" /> </span>
                  <IntlMessages id="blockchains.perMonth" />
                </div>
                <div className="list fs-14">
                  <div className="mb-16">
                    {"10 "}
                    <IntlMessages id="credits" />
                    {" "}
                    <IntlMessages id="blockchains.perMonth" />
                  </div>
                  <div><IntlMessages id="buy.email_suport" /></div>
                  <div className="mb-16"><IntlMessages id="buy.non_cumulative" /></div>
                </div>
                <div>
                  <button disabled={true} className="pricing-button-disabled">
                    <IntlMessages id="buy.get_started" />
                  </button>
                </div>
              </div>
            </div>

            <div className="col-lg-3">
              <div className="pricing-card ptb-24">
                <div className="fs-34">Starter</div>
                <div className="fs-16 mb-16"><IntlMessages id="buy.starter_desc" /></div>
                <div className="pink-highlight mb-20">
                  <span className="fs-34"> <IntlMessages id="buy.price_plan2" /> </span>
                  <IntlMessages id="blockchains.perMonth" />
                </div>
                <div className="list fs-14">
                  <div className="mb-16">
                    {"100 "}
                    <IntlMessages id="credits" />
                    {" "}
                    <IntlMessages id="blockchains.perMonth" />
                  </div>
                  <div><IntlMessages id="buy.email_suport" /></div>
                  <div className="mb-16"><IntlMessages id="buy.non_cumulative" /></div>
                </div>
                <div>
                  <button className="pricing-button" onClick={() => showForm(props.intl.formatMessage({ id: 'buy.name_plan2' }))}>
                    <IntlMessages id="buy.get_started" />
                  </button>
                </div>
              </div>
            </div>

            <div className="col-lg-3">
              <div className="pricing-white-card ptb-24">
                <div className="fs-34">Business</div>
                <div className="fs-16 mb-16"><IntlMessages id="buy.business_desc" /></div>
                <div className="pink-highlight mb-20">
                  <span className="fs-34"> <IntlMessages id="buy.price_plan3" /> </span>
                  <IntlMessages id="blockchains.perMonth" />
                  <br />
                  <span>10% OFF</span>
                </div>

                <div className="list fs-14">
                  <div className="mb-16">
                    {"500 "}
                    <IntlMessages id="credits" />
                    {" "}
                    <IntlMessages id="blockchains.perMonth" />
                  </div>
                  <div>
                    {"✅ "}
                    <IntlMessages id="buy.premium_suport" />
                  </div>
                  <div className="mb-16"><IntlMessages id="buy.non_cumulative" /></div>
                </div>
                <div>
                  <button className="pricing-button" onClick={() => showForm(props.intl.formatMessage({ id: 'buy.name_plan3' }))}>
                    <IntlMessages id="buy.get_started" />
                  </button>
                </div>
                <div className="trial-info mt-7"><IntlMessages id="buy.recommended" /></div>
              </div>
            </div>

            <div className="col-lg-3">
              <div className="pricing-card ptb-24">
                <div className="fs-34">Enterprise</div>
                <div className="fs-16 mb-16"><IntlMessages id="buy.enterprise_desc" /></div>
                <div className="pink-highlight mb-20">
                  <span className="fs-34"> <IntlMessages id="buy.price_plan4" /> </span>
                </div>
                <div className="list fs-14 mb-20">
                  <div>
                    {"✅ "}
                    <IntlMessages id="buy.premium_suport" />
                  </div>
                  <div>
                    {"✅ "}
                    <IntlMessages id="buy.custom_project" />
                  </div>
                </div>
                <div>
                  <button className="pricing-button" onClick={() => props.history.push('/support')}>
                    <IntlMessages id="buy.contact" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(Buy);
