import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Spinner } from "reactstrap";
import { injectIntl } from "react-intl";
import Select from "react-select";
import IntlMessages from "util/intlMessages";
import { toast } from "react-toastify";
import Api from "api/api";
import Session from "util/Session";
import DataTable from "react-data-table-component";
import PublishTransaction from "components/transaction/PublishTransaction";
import { BywiseHelper, TxType } from "@bywise/web3";
import { calcIPFSPrice } from "helper/methods";

const columns = [
    {
        name: 'Name',
        selector: row => row.name,
        sortable: true,
    },
    {
        name: 'Public',
        selector: row => row.isPublic ? "Yes" : "No",
        sortable: true,
    },
    {
        name: 'Created',
        selector: row => (new Date(row.created)).toLocaleDateString(),
        sortable: true,
    },
    {
        name: 'Select',
        selector: row => row.select,
        sortable: false,
    },
];

class UploadFiles extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
            uploadedFiles: [],
            files: [],
            isPublic: false,
            isPublicForce: false,
            isDefaultChain: false,
            name: '',
            type: '',
            expire: { value: 1, label: `1 ${this.props.intl.formatMessage({ id: `years` })}` },
            size: 0,
            total: '0',
        }
    }

    onFileChange = async (event) => {
        let files = [];
        for (let i = 0; i < event.target.files.length; i++) {
            files.push(event.target.files[`${i}`])
        }
        let state = this.state;
        try {
            state[event.target.name] = files;
            if (files.length > 0) {
                let file = files[0];
                let filenameSplit = file.name.split('.');
                state.type = "." + filenameSplit.pop();
                state.name = filenameSplit.join('.');
                state.size = file.size
                state.total = calcIPFSPrice(state.expire.value, state.size);
            }
            await this.setState(state);
        } catch (err) {
            toast.error(err.message);
            state[event.target.name] = [];
            await this.setState(state);
        }
    }

    cancel = (event) => {
        if (event) {
            event.preventDefault();
        }
        this.setState({
            loading: false,
            visible: false,
            isPublic: false,
            isDefaultChain: true,
            files: [],
            name: '',
            type: '',
            expire: { value: 1, label: `1 ${this.props.intl.formatMessage({ id: `years` })}` },
            size: 0,
            total: '0',
        });
    }

    uploadFiles = async (event) => {
        event.preventDefault();
        this.setState({ loading: true });
        try {
            const web3 = await Session.getWeb3();
            const infoChain = Session.getInfoChain();
            const wallet = Session.getWallet();

            if (this.state.files.length === 0) {
                toast.error('Select File');
                this.setState({ loading: false });
                return;
            }
            let data = await Api.postFiles('files/upload', this.state.files, {
                isPublic: this.state.isPublic,
                chain: infoChain.chain,
                name: this.state.name + this.state.type,
            });
            let file = data[0];

            if (file.expire) {
                this.setState({ loading: false });
                this.cancel();
                this.props.onUpload(file);
                return;
            }

            if (!this.state.isDefaultChain) {
                const tx = await web3.transactions.buildSimpleTx(
                    wallet,
                    infoChain.chain,
                    BywiseHelper.ZERO_ADDRESS,
                    '0',
                    TxType.TX_JSON,
                    {
                        ipfs: file.ipfs,
                    }
                );

                this.publishTransaction.publish(tx, {
                    type: 'normal',
                }, (async (tx, output) => {
                    const req = await Api.patch(`files/item/${file.id}`, {
                        txId: tx.hash,
                    });
                    file.txId = tx.hash;

                    if (req.error) {
                        toast.error(req.error)
                        return;
                    }
                    this.cancel();
                    this.props.onUpload(file);
                }))
                return;
            }

            const payAmount = calcIPFSPrice(this.state.expire.value, file.size);

            const tx = await web3.transactions.buildSimpleTx(
                wallet,
                Session.getDefaultChain(),
                BywiseHelper.ZERO_ADDRESS,
                payAmount,
                TxType.TX_JSON,
                {
                    ipfs: file.ipfs,
                    expire: this.state.expire.value,
                }
            );

            this.publishTransaction.publish(tx, {
                type: 'pay',
                amount: payAmount,
                service: this.props.intl.formatMessage({ id: `tx.payIPFS` }),
            }, (async (tx, output) => {
                const req = await Api.patch(`files/item/${file.id}`, {
                    txId: tx.hash,
                });
                file.txId = tx.hash;

                if (req.error) {
                    toast.error(req.error)
                    return;
                }
                this.cancel();
                this.props.onUpload(file);
            }))
        } catch (err) {
            toast.error(err.message);
        }
        this.setState({ loading: false });
    }

    show = async (e) => {
        e.preventDefault();

        const defaultChain = Session.getDefaultChain();
        const infoChain = Session.getInfoChain();

        const isDefaultChain = defaultChain === infoChain.chain;

        const req = await Api.get(`files`);
        if (req.error) {
            return;
        }
        let rows = req.data;
        let values = [];
        rows = rows.sort((a, b) => (new Date(b.created).getTime()) - (new Date(a.created).getTime()))
        rows.forEach((row, i) => {
            if (((!this.props.isPublic) || (this.props.isPublic && row.isPublic)) && row.chain === infoChain.chain) {
                values.push({
                    id: i,
                    name: row.name,
                    isPublic: row.isPublic,
                    created: row.created,
                    select: <Button className="btn-primary" onClick={() => {
                        this.cancel();
                        this.props.onUpload(row);
                    }}><IntlMessages id="select" /></Button>,
                });
            }

        });
        let isPublic = !(!this.props.isPublic);
        await this.setState({ visible: true, loading: false, isDefaultChain, isPublic: isPublic, isPublicForce: isPublic, uploadedFiles: values.map(v => v) });
    }

    render() {
        return (
            <>
                <Button className="c-primary" onClick={this.show}>
                    <i className="fas fa-cloud-upload-alt"></i>
                    {" "}
                    {this.props.text ? this.props.text : <IntlMessages id="files.uploadFile" />}
                </Button>
                <Modal
                    size="lg"
                    isOpen={this.state.visible}
                    toggle={this.cancel}
                >
                    <ModalHeader toggle={this.cancel}>
                        <IntlMessages id="files.uploadFile" />
                    </ModalHeader>
                    <ModalBody>
                        <form>
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label">
                                    <IntlMessages id="files.uploadFile" />
                                </label>
                                <div className="col-sm-8">
                                    <input className="form-control-file" type="file" name="files" onChange={this.onFileChange} />
                                </div>
                            </div>
                            {this.state.files.length > 0 && <>
                                <div className="form-group row">
                                    <label className="col-sm-4 col-form-label">
                                        <IntlMessages id="name" />
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="input-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={this.state.name}
                                                onChange={(e) => this.setState({ name: e.target.value })}
                                            />
                                            <div className="input-group-append">
                                                <span className="input-group-text">
                                                    {this.state.type}
                                                </span>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col">
                                        <div className="pretty p-svg p-curve">
                                            <input
                                                type="checkbox"
                                                checked={this.state.isPublic}
                                                onChange={() => {
                                                    if (!this.state.isPublicForce) {
                                                        this.setState({ isPublic: !this.state.isPublic })
                                                    } else {
                                                        toast.info(`This image needs to be public to view`)
                                                    }
                                                }} />
                                            <div className="state p-success">
                                                {/* <!-- svg path --> */}
                                                <svg className="svg svg-icon" viewBox="0 0 20 20">
                                                    <path
                                                        d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                                                        style={{ stroke: "white", fill: "white" }}
                                                    ></path>
                                                </svg>
                                                <label><IntlMessages id={this.state.isPublic ? "files.publicFileYes" : "files.publicFileNo"} /></label>
                                            </div>
                                        </div>
                                        <p>
                                            <label>
                                                <small className="c-text-alternate"><IntlMessages id={this.state.isPublic ? "files.publicFileYesDesc" : "files.publicFileNoDesc"} /></small>
                                            </label>
                                        </p>
                                    </div>
                                </div>
                                {this.state.isDefaultChain && <>
                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">
                                            <IntlMessages id="expire" />
                                            <p><small className="c-text-alternate"><IntlMessages id="files.expireLabel" /></small></p>
                                        </label>
                                        <div className="col-sm-8">
                                            <Select
                                                defaultValue={this.state.expire}
                                                onChange={(newValue) => {
                                                    this.setState({ expire: newValue, total: calcIPFSPrice(newValue.value, this.state.size) })
                                                }}
                                                options={[
                                                    { value: 1, label: `1 ${this.props.intl.formatMessage({ id: `years` })}` },
                                                    { value: 2, label: `2 ${this.props.intl.formatMessage({ id: `years` })}` },
                                                    { value: 5, label: `5 ${this.props.intl.formatMessage({ id: `years` })}` },
                                                ]}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">
                                            <IntlMessages id="fee" />
                                        </label>
                                        <div className="col-sm-8">
                                            <span>{this.state.total} BWS</span>
                                        </div>
                                    </div>
                                </>}
                            </>}
                            {this.state.files.length === 0 && <>
                                <hr />
                                <div className="form-group row">
                                    <label className="col col-form-label">
                                        <IntlMessages id="select" />
                                    </label>
                                </div>
                                <DataTable
                                    columns={columns}
                                    data={this.state.uploadedFiles}
                                    paginationPerPage={5}
                                    pagination
                                />
                            </>}
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="c-secondary" onClick={this.cancel}>
                            <IntlMessages id="cancel" />
                        </Button>
                        {" "}
                        <Button className="c-primary" onClick={this.uploadFiles}>
                            {this.state.loading && <Spinner color="primary" />}
                            {!this.state.loading && <span>Upload</span>}
                        </Button>
                    </ModalFooter>
                </Modal>
                <PublishTransaction ref={ref => this.publishTransaction = ref} />
            </>
        );
    }
}

export default injectIntl(UploadFiles);
