export const sidebarData = [
  {
    name: "dashboard.title",
    routepath: "/dashboard",
    iconClass: "fas fa-fire"
  },
  {
    name: "wallet.title",
    routepath: "/wallet",
    iconClass: "fas fa-wallet"
  },
  {
    name: "web3",
    iconClass: "fas fa-object-group",
    child: [
      {
        name: "dashboard.traceability",
        iconClass: "fas fa-route",
        routepath: "/traceability",
      },
      {
        name: "tokens",
        routepath: "/tokens",
        iconClass: "fas fa-coins",
      },
      {
        name: "nfts",
        routepath: "/nfts",
        iconClass: "fas fa-trophy",
      },
      {
        name: "ide.title",
        routepath: "/ide",
        iconClass: "fas fa-chalkboard",
      },
      {
        name: "files.title",
        routepath: "/files",
        iconClass: "fas fa-folder",
      },
    ]
  },
  {
    name: "blockchains",
    iconClass: "fas fa-link",
    child: [
      {
        name: "dashboard.my_networks",
        routepath: "/blockchains",
        iconClass: "fas fa-dice-d6",
      },
      {
        name: "nodes",
        routepath: "/nodes",
        iconClass: "fas fa-server",
      },
      {
        name: "project.title",
        routepath: "/projects",
        iconClass: "fas fa-project-diagram",
      },
    ]
  },
  {
    name: "dashboard.apps",
    iconClass: "fas fa-shapes",
    child: [
      {
        name: "dashboard.crosschain",
        routepath: "/crosschain",
        iconClass: "fas fa-sitemap",
      },
      {
        name: "dashboard.dex",
        routepath: "/dex",
        iconClass: "fas fa-chart-line",
      },
      {
        name: "dashboard.NFT_marketplace",
        routepath: "/marketplace",
        iconClass: "fas fa-store",
      },
      {
        name: "dashboard.community_apps",
        routepath: "/community_apps",
        iconClass: "fas fa-user-friends",
      },
    ]
  },
  {
    name: "community",
    routepath: "/community",
    iconClass: "fa fa-heart"
  },
  {
    name: "help_and_support",
    routepath: "/support",
    iconClass: "fa fa-question"
  },
];

export const HorizontalSidebarData = [
];