import React, { useState } from "react";
import { Card, CardBody, Button, Spinner, Badge } from 'reactstrap';

const Method = props => {
  const { method, address } = props;
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({});

  const updateForm = (newValue, name) => {
    form[name] = newValue;
    setForm(form);
  }

  const send = async () => {
    setLoading(true);
    const values = [];
    method.parameters.forEach(p => {
      values.push(form[p.name]);
    })
    await props.send(address, method.name, ...values);
    setLoading(false);
  }

  const read = async () => {
    setLoading(true);
    const values = [];
    method.parameters.forEach(p => {
      values.push(form[p.name]);
    })
    await props.read(address, method.name, ...values);
    setLoading(false);
  }

  return (<Card className="mb-3">
    <CardBody>
      <h5 className="c-text-primary mb-3">{method.name}</h5>
      <form>
        {method.parameters.map((p, i) => <div key={`${address}-${method.name}-${i}`} className="form-group">
          <label>
            {p.name}
          </label>
          <input
            type="text"
            className="form-control react-form-input"
            onChange={e => updateForm(e.target.value, p.name)}
          />
        </div>)}

        {method.view && <Button className="c-info mt-3 ml-3" onClick={read} disabled={loading}>
          {loading && <Spinner color="primary" />}
          {!loading && <span>Read</span>}
        </Button>}
        {!method.view && <Button className="c-danger mt-3 ml-3" onClick={send} disabled={loading}>
          {loading && <Spinner color="primary" />}
          {!loading && <span>Write</span>}
        </Button>}
      </form>
    </CardBody>
  </Card >)
}

const Contract = props => {
  return (<div className="hidden-overflow">
    <Badge className="pa-10 c-primary mb-3" pill>{props.address}</Badge>
    {props.abi && props.abi.map((method, i) => <Method key={`${props.address}-${i}`} address={props.address} method={method} send={props.send} read={props.read} />)}
  </div>)
}

export default Contract;