import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Spinner } from "reactstrap";
import IntlMessages from "util/intlMessages";
import { BywiseHelper, Tx } from "@bywise/web3";
import { toast } from "react-toastify";
import Session from "util/Session";
import { convertBWSToUSD } from "helper/methods";
import BigNumber from "bignumber.js";
import SweetAlert from "react-bootstrap-sweetalert";

/*
// Normal full transaction
this.publish(tx, {
    type: 'normal'
}, (hash => console.log('hash', hash)))

// Transfer Token
this.publish(tx, {
    type: 'transfer',
    amount: '1000',
    symbol: 'BRL',
    to: 'BWS1MU2E16b138f24d4472D5b31c752C6B599403bC5EDD66c',
}, (hash => console.log('hash', hash)))

// Deploy
this.publish(tx, {
    type: 'deploy',
    code: 'code',
    contractAddress: 'BWS1MU2E16b138f24d4472D5b31c752C6B599403bC5EDD66c',
}, (hash => console.log('hash', hash)))

// Pay Service
this.publish(tx, {
    type: 'pay',
    amount: '1000',
    service: 'Store IPFS File',
}, (hash => console.log('hash', hash)))
*/

class PublishTransaction extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            show: false,
            type: {
                type: '',
                amount: '',
                symbol: '',
                to: '',
                code: '',
                contractAddress: '',
                service: '',
            },
            total: '0',
            hash: '',
            output: null,
            tx: new Tx(),
            callback: async (tx, output) => { },
        }
    }

    publish = (tx = new Tx(), type = {
        type: '',
        amount: '',
        symbol: '',
        to: '',
        code: '',
        contractAddress: '',
        service: '',
    }, callback = async (tx, output) => { }) => {
        if(type.type === 'pay' && tx.chain !== Session.getDefaultChain()) {
            toast.error(`Select "${Session.getDefaultChain()}" network`);
            return;
        }
        let total = new BigNumber(tx.fee);
        for (let i = 0; i < tx.amount.length; i++) {
            const amount = tx.amount[i];
            total = total.plus(new BigNumber(amount));
        }
        this.setState({ show: true, tx, type, hash: tx.hash, callback, total: total.toFixed(), output: null })
    }

    read = async (chain = '', contractAddress = '', method = '', inputs = ['']) => {
        try {
            const web3 = await Session.getWeb3();
            const output = await web3.contracts.readContract(
                chain,
                contractAddress,
                method,
                inputs,
            );
            if (!output) {
                toast.error('Failed read');
                return;
            } else {
                this.setState({ output: output });
            }
        } catch (err) {
            toast.error(err.message);
        }
    }

    send = async () => {
        this.setState({ loading: true });
        const web3 = await Session.getWeb3();
        try {
            const output = await web3.transactions.sendTransactionSync(this.state.tx);
            if (output.error) {
                toast.error(output.error);
            } else {
                this.setState({ show: false, output: output });
            }
        } catch (err) {
            toast.error(err.message);
        }
        this.setState({ loading: false });
    }

    cancel = async () => {
        this.setState({ show: false, output: null, hash: '' })
    }

    render() {
        return (
            <>
                <SweetAlert
                    show={this.state.output !== null}
                    title="Success"
                    onConfirm={async () => {
                        await this.state.callback(this.state.tx, this.state.output);
                        await this.cancel();
                    }}
                    onCancel={async () => {
                        await this.state.callback(this.state.tx, this.state.output);
                        await this.cancel();
                    }}
                    confirmBtnCssClass="sweet-alert-confirm-button">
                    {this.state.output !== null && <>
                        {this.state.hash && <>
                            <h4>TxId</h4>
                            <a href={`${Session.getInfoChain().explorer}/tx/${this.state.hash}`} target="_blank" rel="noopener noreferrer">{this.state.hash}</a>
                            <br />
                        </>}
                        {this.state.output.output && <>
                            <h4>Result</h4>
                            <textarea
                                type="text"
                                rows={5}
                                disabled
                                className="form-control react-form-input"
                                value={JSON.stringify(this.state.output.output, null, 4)}
                            />
                            <br />
                        </>}
                        {this.state.output.logs.length > 0 && <>
                            <h4>Logs</h4>
                            {this.state.output.logs.map((e, i) => <p key={`log-${i}`}>{e}<br /></p>)}
                            <br />
                        </>}
                        {this.state.output.events.length > 0 && <>
                            <h4>Events</h4>
                            <textarea
                                type="text"
                                rows={5}
                                disabled
                                className="form-control react-form-input"
                                value={JSON.stringify(this.state.output.events.map(e => {
                                    return {
                                        ...e,
                                        data: JSON.parse(e.data)
                                    };
                                }), null, 4)}
                            />
                            <br />
                        </>}
                    </>}
                </SweetAlert>
                <Modal
                    size="lg"
                    isOpen={this.state.show}
                    toggle={this.cancel}
                >
                    <ModalHeader toggle={this.cancel}>
                        <IntlMessages id="tx.transaction" />
                    </ModalHeader>
                    <ModalBody>
                        {this.state.type.type === 'normal' && <>
                            <div className="mb-2">
                                <IntlMessages id="tx.transfer" />
                            </div>
                            {this.state.tx.to.map((addr, i) => <div key={`to-${i}`} className="card p-2 mb-2">
                                <div className="flex-x">
                                    <div className="mr-2" style={{ fontSize: 30 }}>
                                        <i className="fas fa-arrow-up"></i>
                                    </div>
                                    <div className="flex-1">
                                        <div className="flex-x">
                                            <strong className="mr-auto"><IntlMessages id="send" /> BWS</strong>
                                            <strong>{this.state.tx.amount[i]} BWS</strong>
                                        </div>
                                        <div className="flex-x">
                                            <small className="mr-auto hidden-overflow">
                                                <IntlMessages id="tx.to" />
                                                {" "}
                                                <a href={`${Session.getInfoChain().explorer}/address/${addr}`} target="_blank" rel="noopener noreferrer">
                                                    {addr}
                                                </a>
                                            </small>
                                            {convertBWSToUSD(this.state.tx.amount[i])}
                                        </div>
                                    </div>
                                </div>
                            </div>)}
                            <div className="card p-2 mb-2">
                                <div className="flex-x">
                                    <div className="mr-2" style={{ fontSize: 30 }}>
                                        <i className="fas fa-coins"></i>
                                    </div>
                                    <div className="flex-1">
                                        <div className="flex-x">
                                            <strong className="mr-auto"><IntlMessages id="fee" /></strong>
                                            <strong>{this.state.tx.fee} BWS</strong>
                                        </div>
                                        <div className="flex-x">
                                            <small className="mr-auto hidden-overflow">
                                                <IntlMessages id="tx.to" />
                                                {" "}
                                                {BywiseHelper.ZERO_ADDRESS}
                                            </small>
                                            {convertBWSToUSD(this.state.tx.fee)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-2">
                                <IntlMessages id="tx.type" />
                                {" "}
                                <strong>{this.state.tx.type}</strong>
                            </div>
                            {this.state.tx.data && Array.isArray(this.state.tx.data) && this.state.tx.data.map((item, i) => <div key={`data-${i}`} className="card p-2 mb-2">
                                <div className="flex-x">
                                    <div className="mr-2" style={{ fontSize: 30 }}>
                                        <i className="fas fa-scroll"></i>
                                    </div>
                                    <div className="flex-1">
                                        <div className="flex-x">
                                            <strong className="mr-auto">{item.method}</strong>
                                        </div>
                                        {item.inputs.map((value, j) => <div key={`${j}`} className="flex-x">
                                            <small className="c-text-alternate ml-4">{value}</small>
                                        </div>)}
                                    </div>
                                </div>
                            </div>)}
                            {this.state.tx.data && !Array.isArray(this.state.tx.data) && <>
                                <div className="card p-2 mb-2">
                                    <div className="flex-x">
                                        <div className="mr-2" style={{ fontSize: 30 }}>
                                            <i className="fas fa-scroll"></i>
                                        </div>
                                        <div className="flex-1">
                                            <div className="flex-x">
                                                <strong className="mr-auto">Data:</strong>
                                            </div>
                                            <div className="flex-x">
                                                <textarea
                                                    type="text"
                                                    rows={10}
                                                    disabled
                                                    className="form-control react-form-input"
                                                    value={JSON.stringify(this.state.tx.data, null, 2)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>}
                        </>}
                        {this.state.type.type === 'transfer' && <>
                            <div className="card p-2 mb-2">
                                <div className="flex-x">
                                    <div className="mr-2" style={{ fontSize: 30 }}>
                                        <i className="fas fa-arrow-up"></i>
                                    </div>
                                    <div className="flex-1">
                                        <div className="flex-x">
                                            <strong className="mr-auto"><IntlMessages id="send" /> {this.state.type.symbol}</strong>
                                            <strong>{this.state.type.amount} {this.state.type.symbol}</strong>
                                        </div>
                                        <div className="flex-x">
                                            <small className="mr-auto hidden-overflow">
                                                <IntlMessages id="tx.to" />
                                                {" "}
                                                <a href={`${Session.getInfoChain().explorer}/address/${this.state.type.to}`} target="_blank" rel="noopener noreferrer">
                                                    {this.state.type.to}
                                                </a>
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card p-2 mb-2">
                                <div className="flex-x">
                                    <div className="mr-2" style={{ fontSize: 30 }}>
                                        <i className="fas fa-coins"></i>
                                    </div>
                                    <div className="flex-1">
                                        <div className="flex-x">
                                            <strong className="mr-auto"><IntlMessages id="fee" /></strong>
                                            <strong>{this.state.tx.fee} BWS</strong>
                                        </div>
                                        <div className="flex-x">
                                            <small className="mr-auto hidden-overflow">
                                                <IntlMessages id="tx.to" />
                                                {" "}
                                                {BywiseHelper.ZERO_ADDRESS}
                                            </small>
                                            {convertBWSToUSD(this.state.tx.fee)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>}
                        {this.state.type.type === 'deploy' && <>
                            <div className="card p-2 mb-2">
                                <div className="flex-x">
                                    <div className="mr-2" style={{ fontSize: 30 }}>
                                        <i className="fas fa-robot"></i>
                                    </div>
                                    <div className="flex-1">
                                        <div className="flex-x">
                                            <strong className="mr-auto">Deploy Smartcontract</strong>
                                        </div>
                                        <div className="flex-x">
                                            <strong className="mr-auto hidden-overflow">Address: {this.state.type.contractAddress}</strong>
                                        </div>
                                        <div className="flex-x">
                                            <strong className="mr-auto">Code:</strong>
                                        </div>
                                        <div className="flex-x">
                                            <textarea
                                                type="text"
                                                rows={20}
                                                disabled
                                                className="form-control react-form-input"
                                                value={this.state.type.code}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card p-2 mb-2">
                                <div className="flex-x">
                                    <div className="mr-2" style={{ fontSize: 30 }}>
                                        <i className="fas fa-coins"></i>
                                    </div>
                                    <div className="flex-1">
                                        <div className="flex-x">
                                            <strong className="mr-auto"><IntlMessages id="fee" /></strong>
                                            <strong>{this.state.tx.fee} BWS</strong>
                                        </div>
                                        <div className="flex-x">
                                            <small className="mr-auto hidden-overflow">
                                                <IntlMessages id="tx.to" />
                                                {" "}
                                                {BywiseHelper.ZERO_ADDRESS}
                                            </small>
                                            {convertBWSToUSD(this.state.tx.fee)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>}
                        {this.state.type.type === 'pay' && <>
                            <div className="card p-2 mb-2">
                                <div className="flex-x">
                                    <div className="mr-2" style={{ fontSize: 30 }}>
                                        <i className="fas fa-shopping-cart"></i>
                                    </div>
                                    <div className="flex-1">
                                        <div className="flex-x">
                                            <strong className="mr-auto">{this.state.type.service}</strong>
                                            <strong>{this.state.type.amount} BWS</strong>
                                        </div>
                                        <div className="flex-x">
                                            <small className="mr-auto hidden-overflow">
                                                <IntlMessages id="tx.to" />
                                                {" "}
                                                {BywiseHelper.ZERO_ADDRESS}
                                            </small>
                                            {convertBWSToUSD(this.state.type.amount)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card p-2 mb-2">
                                <div className="flex-x">
                                    <div className="mr-2" style={{ fontSize: 30 }}>
                                        <i className="fas fa-coins"></i>
                                    </div>
                                    <div className="flex-1">
                                        <div className="flex-x">
                                            <strong className="mr-auto"><IntlMessages id="fee" /></strong>
                                            <strong>{this.state.tx.fee} BWS</strong>
                                        </div>
                                        <div className="flex-x">
                                            <small className="mr-auto hidden-overflow">
                                                <IntlMessages id="tx.to" />
                                                {" "}
                                                {BywiseHelper.ZERO_ADDRESS}
                                            </small>
                                            {convertBWSToUSD(this.state.tx.fee)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>}
                        <hr />
                        <div className="mb-2">
                            <IntlMessages id="tx.total" />
                            {" "}
                            <strong>{this.state.total} BWS</strong>
                            {" "}
                            {convertBWSToUSD(this.state.total)}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="c-secondary" onClick={this.cancel}>
                            {this.state.loading && <Spinner color="primary" />}
                            {!this.state.loading && <span><IntlMessages id="cancel" /></span>}
                        </Button>
                        {" "}
                        <Button className="c-primary" onClick={this.send}>
                            {this.state.loading && <Spinner color="primary" />}
                            {!this.state.loading && <span><IntlMessages id="send" /></span>}
                        </Button>
                    </ModalFooter>
                </Modal>
            </>
        );
    }
}

export default PublishTransaction;
