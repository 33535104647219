import React from "react";

const BlockchainStatus = ({ status }) => {
  if (status === 'running') {
    return <strong className="c-text-success">Online</strong>
  } else if (status === 'building') {
    return <strong className="c-text-warning">Building</strong>
  } else {
    return <strong className="c-text-danger">Offline</strong>
  }
}

export default BlockchainStatus;
