
import React, { Component } from "react";
import { injectIntl } from "react-intl";
import IntlMessages from "util/intlMessages";
import { bywiseIcon, loginBack } from "helper/constant";
import Loading from "components/common/Loading";
import { toast } from "react-toastify";
import Auth from "api/auth";
import Api from "api/api";

const loginContainer = {
  backgroundImage: `url(${loginBack})`,
  backgroundPosition: "center center",
  backgroundSize: "cover",
  position: "fixed",
  overflow: "auto",
  top: 0,
  bottom: 0
};

class ProviderSignin extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    }
  }

  componentDidMount = async () => {
    if (!window.opener) {
      toast.error('invalid opener');
    }
  }

  handleConnect = async () => {
    this.setState({ loading: true });
    if (!Auth.isLogged()) {
      this.signin();
      return;
    }
    let req = await Api.get(`auth/me`, {}, false);
    if (req.error) {
      this.signin();
      return;
    }
    const user = req.data.user;

    req = await Api.get(`addresses`);
    if (req.error) {
      this.signin();
      return;
    }
    const addresses = req.data;

    let defaultAddress;
    addresses.forEach(addr => {
      if (addr.id === user.addressId) {
        defaultAddress = addr;
      }
    })

    req = await Api.get(`blockchains`);
    if (req.error) {
      this.signin();
      return;
    }
    const chains = req.data;

    window.opener.window.postMessage(JSON.stringify({
      message: 'success',
      address: defaultAddress.address,
      chains: chains,
    }), '*');
    window.close();
  }

  cancel = () => {
    window.opener.window.postMessage(JSON.stringify({
      message: 'canceled'
    }), '*');
    window.close();
  }

  signin = () => {
    window.open(`https://dashboard.bywise.org/signin`, '_blank', 'noreferrer');
    this.cancel();
  }

  render() {
    if (this.state.loading) {
      return <Loading loading={true} />
    }
    return (<div className="container-fluid" style={loginContainer}>
      <div className="form-container">
        <div className="login-icon">
          <img src={bywiseIcon} alt="icon" height="100px" />
        </div>
        <div className="login-title mb-2">
          <IntlMessages id="signin.connect" />
        </div>
        <form className="pa-24 flex">
          <button className="btn c-info form-button mt-16 mr-2" onClick={this.cancel}>
            <IntlMessages id="cancel" />
          </button>
          <button className="btn form-button mt-16 ml-2" onClick={this.handleConnect}>
            <IntlMessages id="connect" />
          </button>
        </form>
      </div>
    </div>);
  }
}

export default injectIntl(ProviderSignin);