import React from "react";
import { locakscreenBack } from "helper/constant";

const AUP = props => {

  const loginContainer = {
    backgroundImage: `url(${locakscreenBack})`,
    backgroundPosition: "center center",
    backgroundSize: "cover",
    position: "fixed",
    overflow: "auto",
    top: 0,
    bottom: 0
  };

  return (
    <div className="container-fluid pricing-page" style={loginContainer}>
      <div className="Pricing-title text-center">
        <span className="mr-4 pr-4">
          Política de Utilização Aceitável
        </span>
      </div>
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="terms">

              <p><strong>Versão:</strong> 1.0 <br /><strong>Revisão em:</strong> 10 de agosto de 2023</p>

              <p>Esta Política de Uso Aceitável (esta “Política”) descreve usos proibidos dos sites da Bywise, Inc. (“Bywise” ou “nós”), incluindo, sem limitação, os sites localizados em <a href="https://bywise.org/">https://bywise.org/</a> e <a href="https://dashboard.bywise.org">https://dashboard.bywise.org</a>, e quaisquer ofertas de serviços fornecidas nesses sites, incluindo, sem limitação, a plataforma Bywise (os sites e tais ofertas de serviços são coletivamente referidos como “Serviços”). Dúvidas sobre esta política devem ser direcionadas para support@bywise.org.</p>

              <p><strong>Nenhum uso ou conteúdo ilegal, prejudicial ou ofensivo</strong></p>
              <p>Você não pode usar, encorajar, promover, facilitar ou instruir terceiros a usar os Serviços para qualquer uso ilegal, prejudicial, fraudulento, infrator ou ofensivo, ou para transmitir, armazenar, exibir, distribuir ou de outra forma disponibilizar conteúdo que seja ilegal, prejudicial, fraudulento, infrator ou ofensivo. Atividades ou conteúdos proibidos incluem:</p>
              <li>Atividades ilegais, prejudiciais, fraudulentas ou de alto risco. Quaisquer atividades que sejam ilegais, que violem os direitos de terceiros, que possam ser prejudiciais aos Serviços, a terceiros, às nossas operações ou reputação, incluindo a disseminação, promoção ou facilitação de pornografia infantil, oferta ou divulgação de bens, serviços, esquemas ou promoções fraudulentos, esquemas de ganhar dinheiro rápido, esquemas Ponzi e pirâmide, phishing ou pharming, ou quaisquer atividades em que a falha dos Serviços possa levar à morte ou lesões corporais graves de qualquer pessoa, ou a danos físicos ou ambientais graves.</li>
              <li>Conteúdo infrator. Conteúdo que infringe ou se apropria indevidamente da propriedade intelectual ou dos direitos de propriedade de terceiros.</li>
              <li>Conteúdo ofensivo. Conteúdo difamatório, obsceno, abusivo, invasivo à privacidade ou de outra forma censurável, incluindo conteúdo que constitua pornografia infantil, relacionado à bestialidade ou que retrate atos sexuais não consensuais.</li>
              <li>Conteúdo prejudicial. Conteúdo ou outra tecnologia de computador que possa danificar, interferir, interceptar sub-repticiamente ou expropriar qualquer sistema, programa ou dados, incluindo malware, vírus, cavalos de Tróia, worms, bombas-relógio ou cancelbots.</li>

              <p><strong>Sem violações de segurança</strong></p>
              <p>Você não poderá usar os Serviços para violar a segurança ou integridade de qualquer rede, computador ou sistema de comunicação, aplicativo de software ou rede ou dispositivo de computação (cada um, um “Sistema”). As atividades proibidas incluem:</p>
              <li>Acesso não autorizado. Acessar ou usar qualquer Sistema sem permissão, incluindo tentativa de sondar, escanear ou testar a vulnerabilidade de um Sistema ou violar quaisquer medidas de segurança ou autenticação usadas por um Sistema.</li>
              <li>Interceptação. Monitoramento de dados ou tráfego em um Sistema sem permissão.</li>
              <li>Falsificação de Origem. Forjar cabeçalhos de pacotes TCP-IP, cabeçalhos de e-mail ou qualquer parte de uma mensagem descrevendo sua origem ou rota. O uso legítimo de pseudônimos e repostadores anônimos não é proibido por esta disposição.</li>

              <p><strong>Sem abuso de rede</strong></p>
              <p>Você não pode fazer conexões de rede com usuários, hosts ou redes, a menos que tenha permissão para se comunicar com eles. As atividades proibidas incluem:</p>
              <li>Monitoramento ou rastreamento. Monitoramento ou rastreamento de um Sistema que prejudica ou interrompe o Sistema que está sendo monitorado ou rastreado.</li>
              <li>Negação de serviço (DoS). Inundar um alvo com solicitações de comunicação para que o alvo não possa responder ao tráfego legítimo ou responda tão lentamente que se torne ineficaz.</li>
              <li>Interferência intencional. Interferir no funcionamento adequado de qualquer Sistema, incluindo qualquer tentativa deliberada de sobrecarregar um sistema através de mail bombing, news bombing, ataques de transmissão ou técnicas de inundação.</li>
              <li>Operação de Certos Serviços de Rede. Operar serviços de rede como proxies abertos, retransmissões de correio abertas ou servidores de nomes de domínio recursivos abertos.</li>
              <li>Evitando restrições do sistema. Utilizar meios manuais ou eletrônicos para evitar quaisquer limitações de uso impostas a um Sistema, como restrições de acesso e armazenamento.</li>

              <p><strong>Nenhum e-mail ou outro abuso de mensagens</strong></p>
              <p>Você não distribuirá, publicará, enviará ou facilitará o envio de e-mails em massa não solicitados ou outras mensagens, promoções, publicidade ou solicitações (como “spam”), incluindo publicidade comercial e anúncios informativos. Você não alterará ou ocultará cabeçalhos de e-mail nem assumirá a identidade de um remetente sem a permissão explícita do remetente. Você não coletará respostas a mensagens enviadas de outro provedor de serviços de Internet se essas mensagens violarem esta Política ou a política de uso aceitável desse provedor.</p>

              <p><strong>Sem assistência ou incentivo</strong></p>
              <p>Você não ajudará ou incentivará ninguém a fazer nenhuma das ações acima.</p>

              <p><strong>Nosso monitoramento e fiscalização</strong></p>
              <p>Reservamo-nos o direito, mas não assumimos a obrigação, de investigar qualquer violação desta Política ou uso indevido dos Serviços. Podemos:</p>
              <li>investigar violações desta Política ou uso indevido dos Serviços; ou</li>
              <li>remover, desativar o acesso ou modificar qualquer conteúdo ou recurso que viole esta Política ou qualquer outro acordo que tenhamos com você para uso dos Serviços.</li>

              <p>Poderemos denunciar qualquer atividade que suspeitemos que viole qualquer lei ou regulamento aos responsáveis ​​pela aplicação da lei, reguladores ou outros terceiros apropriados. Nossos relatórios podem incluir a divulgação de informações apropriadas do cliente. Também poderemos cooperar com agências de aplicação da lei, reguladores ou outros terceiros apropriados para ajudar na investigação e processo de conduta ilegal, fornecendo informações de rede e sistemas relacionadas a supostas violações desta Política.</p>

              <p><strong>Denúncia de violações desta política</strong></p>
              <p>Se você tomar conhecimento de qualquer violação desta Política, você concorda em nos notificar imediatamente e nos fornecer assistência, conforme solicitado, para interromper ou remediar a violação. Para denunciar qualquer violação desta Política, entre em contato conosco pelo e-mail support@bywise.org.</p>
              <p>Poderemos, de tempos em tempos, alterar esta Política para detalhar ou descrever restrições razoáveis ​​ao seu uso dos Serviços, publicando uma versão revisada desta Política, e a Política revisada entrará em vigor na data de tal publicação.</p>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default AUP;
