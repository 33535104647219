import Api from 'api/api';
import React from 'react';

export default class IPFSIcon extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            icon: ''
        }
    }

    componentDidMount = async () => {
        let icon = this.props.icon;
        if (icon) {
            if (!icon.startsWith('http') && !icon.startsWith('data:image') && !icon.startsWith('/static/media')) {
                const resp = await fetch(`${Api.getURL()}/api/files/ipfs/${icon}`);
                icon = resp.url
            }
        }
        this.setState({
            loading: false,
            icon
        })
    }

    render() {
        if (this.state.loading) {
            return <div className={this.props.className}>
                <div className="gear-coming-soon">
                    <i className="fas fa-cog fa-spin"></i>
                </div>
            </div>
        }
        if (!this.state.icon) {
            return <div className={this.props.className}>
            </div>
        }
        if (this.state.icon.toLowerCase().endsWith('.mp4')) {
            return <video className={this.props.className} autoPlay="autoplay" loop="loop" muted defaultmuted="true" playsInline onContextMenu={() => false} preload="auto">
                <source src={this.state.icon} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        }
        return <img src={this.state.icon} className={this.props.className} alt='ipfs file' />
    }
}