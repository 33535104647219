import { flagEN, flagES, flagPT } from "helper/constant";

const config = {
  options: [
    {
      languageId: "english",
      locale: "en",
      text: "English",
      icon: flagEN
    },
    {
      languageId: "portuguese",
      locale: "pt",
      text: "Português",
      icon: flagPT
    },
    {
      languageId: 'spanish',
      locale: 'es',
      text: 'Español',
      icon: flagES,
    },
  ]
};



const getLocale = () => {
  let locale = localStorage.getItem('locale');
  if (!locale) {
    locale = navigator.language || navigator.userLanguage;
    if (locale) {
      locale = `${locale}`.substring(0, 2);
    } else {
      locale = 'en'
    }
  }
  return locale;
}

export const setCurrentLanguage = (locale) => {
  localStorage.setItem('locale', locale);
}

export function getCurrentLanguage() {
  let locale = getLocale();
  let selecetedLanguage = config.options[0];
  config.options.forEach(language => {
    if (language.locale === locale) {
      selecetedLanguage = language;
    }
  });
  return selecetedLanguage;
}

export default config;
