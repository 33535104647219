import React, { useRef } from "react";
import { injectIntl } from "react-intl";
import Api from "api/api";
import Session from "util/Session";
import { toast } from "react-toastify";
import { BywiseHelper, TxType } from "@bywise/web3";
import FormNew from "components/forms/FormNew";
import PublishTransaction from "components/transaction/PublishTransaction";

const webdoc = `
import BywiseUtils, { StorageValue, StorageMap } from 'bywise-utils.js';

const TOKEN_NAME = "<name>";
const AMOUNT = <amount>;
const URI = "<uri>";
const INFO_JSON = <info>;

class BST721 { // Bywise Fungible Standard Token 721

    _name = TOKEN_NAME;
    _owner;
    _info = INFO_JSON;
    _uri = URI;
    _totalSupply = new StorageValue(0);
    _balances = new StorageMap([]);
    _owners = new StorageMap('BWS000000000000000000000000000000000000000000000');
    _operators = new StorageMap();

    constructor() {
        this._owner = new StorageValue(BywiseUtils.getTxSender());

        for(let i = 0;i<AMOUNT;i++) {
            this._mint(BywiseUtils.getTxSender());
        }
    }

    name() {  // @view
        return this._name;
    }

    owner() {  // @view
        return this._owner.get();
    }

    ownerOf(tokenId) {  // @view
        this._isValidInteger(tokenId);
        return this._owners.get(tokenId);
    }

    info(tokenId) {  // @view
        return this._info;
    }

    uri(tokenId) {  // @view
        return this._uri;
    }

    totalSupply() {  // @view
        return this._totalSupply.get();
    }

    balanceOf(account) { // @view
        this._isValidAddress(account);

        return this._balances.get(account).length;
    }
    
    balanceOfList(account) { // @view
        this._isValidAddress(account);

        return this._balances.get(account);
    }

    isOperator(owner, spender) { // @view
        this._isValidAddress(owner);
        this._isValidAddress(spender);

        if (this._operators.has(owner)) {
            return this._operators.getStorageMap(owner).get(spender);
        }
        return false;
    }

    transfer(recipient, tokenId) {
        let sender = BywiseUtils.getTxSender();

        this._transfer(sender, recipient, tokenId);
        return true;
    }

    transferFrom(from, to, tokenId) {
        let spender = BywiseUtils.getTxSender();

        if (!this.isOperator(from, spender)) throw new Error('BST721: Not is operator')
        this._transfer(from, to, tokenId);
        return true;
    }

    changeOwner(newOwner) {
        this._isOwner();
        this._isValidAddress(newOwner);

        const oldOwner = this._owner.get();
        this._owner.set(newOwner);

        BywiseUtils.emit('New Owner', {
            oldOwner: oldOwner,
            newOwner: newOwner,
        });
    }

    setOperator(spender, enable) {
        this._isValidAddress(spender);
        this._isValidBoolean(enable);

        let owner = BywiseUtils.getTxSender();

        if (!this._operators.has(owner)) {
            this._operators.set(owner, new StorageMap(false));
        }
        this._operators.getStorageMap(owner).set(spender, enable === 'true');

        BywiseUtils.emit('Set Operator', { spender, enable });
    }

    _isOwner() { // @private
        if (BywiseUtils.getTxSender() !== this._owner.get()) throw new Error('BST721: Only Owner');
    }

    _transfer(from, to, tokenId) { // @private
        this._isValidAddress(from);
        this._isValidAddress(to);
        this._isValidInteger(tokenId);

        if (this._owners.get(tokenId) !== from) throw new Error('BST721: Not owner')

        let fromBalance = this._balances.get(from);
        fromBalance = fromBalance.filter(value => value !== tokenId);
        this._balances.set(from, fromBalance);

        let toBalance = this._balances.get(to);
        toBalance.push(tokenId);
        this._balances.set(to, toBalance);
        this._owners.set(tokenId, to);

        BywiseUtils.emit('Transfer', {
            from,
            to,
            tokenId,
        });
    }

    _mint(recipient) { // @private
        this._isValidAddress(recipient);
        let tokenId = this._totalSupply.get();
        this._totalSupply.set(tokenId + 1);
        tokenId = tokenId + '';

        let toBalance = this._balances.get(recipient);
        toBalance.push(tokenId);
        this._balances.set(recipient, toBalance);
        
        this._owners.set(tokenId, recipient);

        BywiseUtils.emit('Mint', {
            recipient
        });
        return tokenId;
    }

    _isValidInteger(value) { // @private
        if (! /^[0-9]{1,36}$/.test(value)) {
            throw new Error(\`BST721: invalid value - \${value}\`);
        }
    }

    _isValidBoolean(value) { // @private
        if (! /^true|false$/.test(value)) {
            throw new Error(\`BST721: invalid booelan - \${value}\`);
        }
    }

    _isValidAddress(value) { // @private
        if (! /^(BWS[0-9A-Z]+[0-9a-fA-F]{0,43})$/.test(value)) {
            throw new Error(\`BST721: invalid address - \${value}\`);
        }
    }
}

BywiseUtils.exportContract(new BST721());
`;

const exempleInfo = `[
    {
        "key": "Some Information",
        "value": "Lorem ipsum dolor sit amet, consectetur adipiscing elit"
    },
    {
        "key": "Some Information 2",
        "value": "Lorem ipsum dolor sit amet, consectetur adipiscing elit"
    },
    {
        "key": "Some Information with link",
        "value": "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        "link": "https://www.lipsum.com/"
    },
    {
        "key": "Web3.0 Traceability Document",
        "value": "BWS1MCcdEdd4F140EDC3037B1339c472597AeB3f24DD25a02",
        "link": "https://explorer.bywise.org/web3-document/BWS1MCcdEdd4F140EDC3037B1339c472597AeB3f24DD25a02"
    }
]`;

const NFTNew = (props) => {
    const publishTransaction = useRef(null);

    const service = 'nfts.title';

    const form = {
        title: 'nfts.new',
        description: '',
        initialValues: {
            info: exempleInfo
        },
        saveBTN: async (inputsForm) => {
            const code = webdoc
                .replace('<name>', inputsForm.name)
                .replace('<amount>', inputsForm.amount)
                .replace('<uri>', inputsForm.uri)
                .replace('<info>', inputsForm.info)

            const web3 = await Session.getWeb3();
            const wallet = Session.getWallet();
            const infoChain = Session.getInfoChain();
            try {
                const contractAddress = BywiseHelper.getBWSAddressContract();

                const tx = await web3.transactions.buildSimpleTx(
                    wallet,
                    infoChain.chain,
                    contractAddress,
                    '0',
                    TxType.TX_CONTRACT,
                    { contractAddress: contractAddress, code: code }
                )

                publishTransaction.current.publish(tx, {
                    type: 'deploy',
                    code: code,
                    contractAddress: contractAddress,
                }, (async (tx, output) => {
                    const req = await Api.post(`contracts`, {
                        chain: tx.chain,
                        address: contractAddress,
                        name: inputsForm.name,
                        isDeploy: true,
                        type: "nft",
                        code: code
                    });

                    if (req.error) {
                        toast.error(`Error`);
                        return;
                    }

                    toast.success(`Success`);
                    props.history.goBack();
                }))
            } catch (err) {
                toast.error(err.message);
            }
        },
        form: [
            {
                title: 'nfts.name',
                description: '',
                setValue: (value = '') => {
                    if (value.length > 100) {
                        return value.substring(0, 100);
                    }
                    return value;
                },
                validation: (value = '') => value.trim().length === 0 ? 'invalid_field' : '',
                type: 'text',
                id: 'name'
            },
            {
                title: 'nfts.initialAmount',
                description: '',
                setValue: (value = '', oldValue = '') => {
                    if (/^[0-9]{0,6}$/.test(value)) {
                        return value;
                    }
                    return oldValue;
                },
                validation: (value = '') => value.trim().length === 0 ? 'invalid_field' : '',
                type: 'number',
                id: 'amount'
            },
            {
                title: 'nfts.uri',
                description: 'nfts.uri_desc',
                isPublic: true,
                validation: (value = '') => value.trim().length === 0 ? 'invalid_field' : '',
                type: 'file',
                id: 'uri',
            },
            {
                title: 'nfts.info',
                description: '',
                validation: (value = '') => value.trim().length === 0 ? 'invalid_field' : '',
                type: 'textarea',
                lines: 10,
                id: 'info'
            },
        ]
    }

    return (<>
        <FormNew service={service} form={form} />
        <PublishTransaction ref={publishTransaction} />
    </>);
}

export default injectIntl(NFTNew);
