import React from "react";
import PageTitle from "components/common/PageTitle";
import IntlMessages from "util/intlMessages";
import { Button, Col, Row } from "reactstrap";
import DataTable from 'react-data-table-component';
import Api from "api/api";
import { injectIntl } from "react-intl";
import UploadFiles from "components/files/UploadFiles";
import { toast } from "react-toastify";
import Session from "util/Session";

const columns = [
  {
    name: 'Name',
    selector: row => row.name,
    grow: 2,
    sortable: true,
  },
  {
    name: 'Public',
    selector: row => row.isPublic ? "Yes" : "No",
    grow: 0,
    sortable: true,
  },
  {
    name: 'IPFS',
    selector: row => row.ipfs,
    grow: 2,
    sortable: true,
  },
  {
    name: 'Created',
    grow: 1,
    selector: row => (new Date(row.created)).toLocaleDateString(),
    sortable: true,
  },
  {
    name: 'See',
    grow: 0,
    selector: row => row.download,
    sortable: false,
  },
];

class FilesList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      filter: '',
      values: [],
      filteredValues: []
    }
  }

  componentDidMount = () => {
    this.updateTable();
  }

  updateTable = async () => {
    await this.setState({ loading: true });
    const infoChain = Session.getInfoChain();

    const req = await Api.get(`files`);
    if (req.error) {
      return;
    }
    let rows = req.data;
    let values = [];
    rows = rows.sort((a, b) => (new Date(b.created).getTime()) - (new Date(a.created).getTime()))
    rows.forEach((row, i) => {
      if (row.chain === infoChain.chain) {
        values.push({
          id: i,
          name: row.name,
          isPublic: row.isPublic,
          ipfs: <a target="_blank" rel="noopener noreferrer" href={`${Api.getURL()}/api/files/ipfs/${row.ipfs}`}>{row.ipfs}</a>,
          created: row.created,
          download: <a className="link-file" href={`${Api.getURL()}/api/files/download/${row.id}`}>download</a>,
          delbutton: <Button className="btn-danger" onClick={async () => {
            const req = await Api.del(`files/item/${row.id}`);
            if (!req.error) {
              toast.success(`Success`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              this.updateTable()
            }
          }}><IntlMessages id="delete" /></Button>,
        });
      }
    });
    await this.setState({ loading: false, values, filteredValues: values.map(v => v) });
  }

  updateSearch = (event) => {
    let filter = event.target.value.trim().toLowerCase();

    let filteredValues = [];

    this.state.values.forEach(row => {
      let add = false;
      if (filter.length === 0 ||
        row.name.toLowerCase().includes(filter)) {
        add = true;
      }
      if (add) {
        filteredValues.push(row);
      }
    })

    this.setState({ filter, filteredValues });
  }

  customSort = (rows, selector, direction) => {
    return rows.sort((rowA, rowB) => {
      const aField = selector(rowA, true, true)
      const bField = selector(rowB, true, true)
      let comparison = 0;
      if (aField > bField) {
        comparison = 1;
      } else if (aField < bField) {
        comparison = -1;
      }
      return direction === 'desc' ? comparison * -1 : comparison;
    });
  };

  render() {
    return (
      <div className="page-padding">
        <PageTitle title="files.title" />
        <div className="plr-15">
          <Row>
            <Col><div className="roe-card-style">
              <div className="roe-card-header flex-x align-center">
                <div className="flex-1 fs-18 header">

                  <span className="hash"># </span>
                  <IntlMessages id="files.title" />
                </div>
                <UploadFiles onUpload={this.updateTable} />
              </div>
              <div className="roe-card-body">
                <div className="form-group row">
                  <div className="col-sm-6"></div>
                  <div className="col-sm-6">
                    <div className="input-group">
                      <input
                        className="form-control"
                        type="text"
                        placeholder={this.props.intl.formatMessage({ id: `search` })}
                        value={this.state.filter}
                        onChange={this.updateSearch} />
                      <div className="input-group-append">
                        <span className="input-group-text">
                          <i className="fas fa-search"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <DataTable
                  columns={columns}
                  data={this.state.filteredValues}
                  defaultSortFieldId={1}
                  sortFunction={this.customSort}
                  pagination
                />
              </div>
            </div></Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default injectIntl(FilesList);
