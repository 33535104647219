import React, { useState } from "react";
import HeaderWrapper from "./header.style";
import { withRouter } from "react-router-dom";
import Session from "util/Session";
import Auth from "api/auth";
import { PopoverBody, Spinner, UncontrolledPopover } from "reactstrap";
import IntlMessages from "util/intlMessages";
import SweetAlert from "react-bootstrap-sweetalert";
import { injectIntl } from "react-intl";
import { compose } from "redux";
import Api from "api/api";
import config, { getCurrentLanguage, setCurrentLanguage } from "settings/languageConfig";

const Header = props => {
  const { drawerMiniMethod, mini, intl } = props;
  const [web3Status, setWeb3Status] = useState('try');
  const [balance, setBalance] = useState('');
  const [inputPrompt, setInputPrompt] = useState(false);
  if (!Auth.isLogged()) {
    return <div></div>;
  }

  const info = Auth.getInfo();
  const infoChain = Session.getInfoChain();

  const logout = () => {
    Session.clear();
    Auth.logout(false);
    props.history.push("/signin")
  }

  const setLang = async (locale) => {
    await Api.post(`auth/lang`, {
      value: locale,
    });
    setCurrentLanguage(locale);
    window.location.reload(false);
  }

  const setChain = async (infoChain) => {
    Session.setInfoChain(infoChain)
    window.location.reload(false);
  }

  const updateBalance = async () => {
    if (web3Status === 'connected') {
      const web3 = await Session.getWeb3();
      const infoChain = Session.getInfoChain();
      const wallet = Session.getWallet();
      const infoWallet = await web3.wallets.getWalletInfo(wallet.address, infoChain.chain);
      setBalance(infoWallet.balance);
    } else {
      setBalance('');
    }
  }

  const addChain = async (chainName) => {
    let req = await Api.post(`watch-blockchains`, {
      chain: chainName,
    });
    if (!req.error) {
      req = await Api.get(`blockchains`);
      if (req.error) {
        return;
      }
      info.chains = req.data;
      Auth.saveInfo(info);
      for (let i = 0; i < info.chains.length; i++) {
        const infoChain = info.chains[i];
        if (infoChain.chain === chainName) {
          setChain(infoChain);
        }
      }
    }
  }

  Session.getWeb3().then(web3 => {
    if (web3.network.isConnected) {
      setWeb3Status('connected');
      updateBalance();
    } else {
      web3.network.tryConnection().then(() => {
        if (web3.network.isConnected) {
          setWeb3Status('connected');
          updateBalance();
        } else {
          setWeb3Status('failed');
        }
      })
    }
  }).catch(() => {
    setWeb3Status('failed');
  })

  const getStatus = (name) => {
    if(infoChain.name !== name) {
      return "";
    }
    return web3Status === 'try' ? "🟠" : (web3Status === 'failed' ? "🔴" : "🟢")
  }

  return (
    <HeaderWrapper {...props}>
      <div className="headerBack">
        <div className="flex-x align-center">
          <div className="drawer-handle-arrow">
            {mini ? (
              <button
                className="top-header-icon"
                onClick={() => drawerMiniMethod()}
              >
                <i className="fas fa-bars"></i>
              </button>
            ) : (
              <button
                className="top-header-icon"
                onClick={() => drawerMiniMethod()}
              >
                <i className="fas fa-bars"></i>
              </button>
            )}
          </div>
          <div
            className="mini-drawer-menu-icon"
            onClick={() => drawerMiniMethod()}
          >
            <i className="fas fa-bars" />{" "}
            <span className="app-name fs-16 bold-text">{"Bywise"}</span>
          </div>
          <div className="pl-10 flex-1">
          </div>
          <div className="pl-10 text-white">
            {balance && <span>{balance} BWS</span>}
            {!balance && <Spinner />}
          </div>
          <div className="pl-10">
            <button id="lang" className="top-header-icon">
              <img src={getCurrentLanguage().icon} width={20} height={20} alt='language icon' />
            </button>
            <UncontrolledPopover
              className="roy-menu"
              innerClassName="roy-inner-content"
              placement="bottom-end"
              target="lang"
              trigger="legacy"
            >
              <PopoverBody>
                {config.options.map(lang => <div
                  key={lang.languageId}
                  className="roy-menu-list"
                  onClick={() => setLang(lang.locale)}
                >
                  <div className="d-flex">
                    <img src={lang.icon} width={35} height={25} alt={lang.text} />
                    <span className="pl-4">{lang.text}</span>
                  </div>
                </div>)}
              </PopoverBody>
            </UncontrolledPopover>
          </div>
          <div className="pl-10">
            <button id="user" className="top-header-icon">
              <i className="fa fa-user"></i>
            </button>
            <UncontrolledPopover
              className="roy-menu"
              innerClassName="roy-inner-content"
              placement="bottom-end"
              target="user"
              trigger="legacy"
            >
              <PopoverBody>
                <select
                  onChange={(e) => {
                    let newValue = null;
                    info.chains.forEach(infoChainItem => {
                      if(e.target.value.trim() === infoChainItem.name) {
                        newValue = infoChainItem;
                      }
                    })
                    setChain(newValue)
                  }}
                  value={`${infoChain.name} ${getStatus(infoChain.name)}`}
                  className="roy-menu-list">
                  {info.chains.map((infoChainItem, i) => (<option
                    key={`chain-${i}`}
                    className="roy-menu-list"
                    onClick={() => setChain(infoChainItem)}
                  >
                    {`${infoChainItem.name} ${getStatus(infoChainItem.name)}`}
                  </option>))}
                </select>
                <div className="roy-menu-list" onClick={() => setInputPrompt(!inputPrompt)} >
                  <IntlMessages id="header.add_chain" />
                </div>
                <div
                  className="roy-menu-list"
                  onClick={() => props.history.push("/wallet")}
                ><IntlMessages id="wallet" /></div>
                <div className="roy-menu-list" onClick={() => props.history.push('/support')} >
                  <IntlMessages id="help_and_support" />
                </div>
                <div
                  className="roy-menu-list"
                  onClick={logout}
                ><IntlMessages id="logout" /></div>
              </PopoverBody>
            </UncontrolledPopover>
          </div>
        </div>
      </div>
      <SweetAlert
        input
        showCancel
        show={inputPrompt}
        cancelBtnBsStyle="default"
        confirmBtnCssClass="sweet-alert-confirm-button"
        cancelBtnCssClass="sweet-alert-cancle-button"
        customClass="sweet-alert-wrapper"
        title={intl.formatMessage({ id: `header.add_chain` })}
        onConfirm={data => {
          addChain(data);
          setInputPrompt(false);
        }}
        onCancel={() =>
          setInputPrompt(false)
        }
        className="input-sweet-alert"
      ><IntlMessages id="header.write_chain_id" /></SweetAlert>
    </HeaderWrapper>
  );
};

export default compose(
  injectIntl,
  withRouter
)(Header);
