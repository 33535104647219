import React, { useRef, useState } from "react";
import { injectIntl } from "react-intl";
import Api from "api/api";
import { toast } from "react-toastify";
import FormNew from "components/forms/FormNew";
import Session from "util/Session";
import IntlMessages from "util/intlMessages";
import { getPlanInfo } from "helper/methods";
import { BywiseHelper, TxType } from "@bywise/web3";
import PublishTransaction from "components/transaction/PublishTransaction";
import Auth from "api/auth";
import Plan from "components/blockchain/Plan";

const NodesNew = (props) => {
    const [plan, setPlan] = useState(2);
    const publishTransaction = useRef(null);

    const service = 'dashboard.new_node';

    const info = Auth.getInfo();
    const chains = info.chains.map(chain => ({ value: chain.chain, label: chain.chain.toUpperCase() }));

    const form = {
        title: 'dashboard.new_node',
        description: '',
        saveBTN: async (inputsForm) => {
            const total = (getPlanInfo(plan).cost).toString();

            const web3 = await Session.getWeb3();
            const infoChain = Session.getInfoChain();
            const wallet = Session.getWallet();

            const tx = await web3.transactions.buildSimpleTx(
                wallet,
                infoChain.chain,
                BywiseHelper.ZERO_ADDRESS,
                total,
                TxType.TX_JSON,
                {
                    chain: inputsForm.chain,
                }
            );

            publishTransaction.current.publish(tx, {
                type: 'pay',
                amount: total,
                service: props.intl.formatMessage({ id: `dashboard.new_node` }),
            }, (async (tx, output) => {
                let req = await Api.post(`nodes`, {
                    txId: tx.hash,
                    chain: inputsForm.chain,
                    plan: plan,
                });

                if (req.error) {
                    toast.error(req.error)
                    return;
                }
                toast.success(`Success`);
                props.history.goBack();
            }))
        },
        form: [
            {
                title: 'nodes.chain',
                description: '',
                validation: (value = '') => value.trim().length === 0 ? 'invalid_field' : '',
                itens: chains,
                type: 'select',
                id: 'chain',
            },
            {
                type: 'custom',
                child: <div>
                    <div className="row">
                        <div className="col">
                            <strong><IntlMessages id="nodes.nodePlan" /></strong>
                        </div>
                    </div>
                    <div className="row pt-3 pb-3">
                        <Plan selected={plan} onClick={setPlan} plan={1} />
                        <Plan selected={plan} onClick={setPlan} plan={2} />
                        <Plan selected={plan} onClick={setPlan} plan={3} />
                    </div>
                </div>,
            },
        ]
    }

    return (<>
        <FormNew service={service} form={form} />
        <PublishTransaction ref={publishTransaction} />
    </>);
}

export default injectIntl(NodesNew);
