import React, { useState, useRef } from "react";
import PageTitle from "components/common/PageTitle";
import IntlMessages from "util/intlMessages";
import { BywiseHelper, TxType } from "@bywise/web3";
import Session from "util/Session";
import { useLocation } from "react-router-dom";
import PublishTransaction from "components/transaction/PublishTransaction";
import IPFSIcon from "components/files/IPFSIcon";
import { toast } from "react-toastify";

const buttonBack = {
  backgroundColor: '#563c91',
  color: 'white'
};

const Send = (props) => {
  const [address, setAddress] = useState("");
  const [error, setError] = useState({});
  const publishTransaction = useRef(null);

  const { search } = useLocation();
  const parans = new URLSearchParams(search);
  let name = '';
  let uri = '';
  let tokenId = '';
  let contractAddress = '';
  if (parans.get('tokenId')) {
    tokenId = parans.get('tokenId');
  }
  if (parans.get('address')) {
    contractAddress = parans.get('address');
  }
  if (parans.get('name')) {
    name = parans.get('name');
  }
  if (parans.get('uri')) {
    uri = parans.get('uri');
  }

  const isValid = () => {
    if (!BywiseHelper.isValidAddress(address)) {
      setError({
        address: <IntlMessages id="invalid_address" />
      });
      return false;
    }
    setError({});
    return true;
  };

  const handleSend = async (e) => {
    e.preventDefault();
    if (isValid()) {

      const web3 = await Session.getWeb3();
      const wallet = Session.getWallet();
      const infoChain = Session.getInfoChain();

      try {
        const tx = await web3.transactions.buildSimpleTx(wallet, infoChain.chain, contractAddress, '0', TxType.TX_CONTRACT_EXE, [
          {
            method: 'transfer',
            inputs: [address, tokenId],
          }
        ]);

        publishTransaction.current.publish(tx, {
          type: 'transfer',
          amount: '1',
          symbol: name,
          to: address,
        }, ((tx, output) => {
          props.history.goBack();
        }))
      } catch (err) {
        toast.error(err.message);
      }
    }
  };

  const Error = props => {
    const field1 = props.field;
    if (error[field1]) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {error[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  return (
    <div className="page-padding">
      <PageTitle title="send"
        breadCrumb={[
          {
            name: "wallet.title"
          },
          {
            name: "send"
          }
        ]} />

      <div className="plr-15">
        <div className="roe-card-style">
          <div className="roe-card-header">
            <span className="hash"># </span>
            <span><IntlMessages id="send" /> {name}</span>
          </div>
          <div className="roe-card-body">
            <form>
              <div className="form-group row">
                <div className="col-sm-2 text-center">
                  <IPFSIcon className="img-fluid" icon={uri} />
                </div>
                <label className="col-sm-2 col-form-label">
                  <IntlMessages id="address" />
                </label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    className="form-control react-form-input"
                    value={address}
                    onChange={e => setAddress(e.target.value)}
                  />
                  <Error field="address" />
                </div>
              </div>

              <button
                style={buttonBack}
                type="submit"
                className="btn form-button"
                onClick={handleSend}
              >
                <IntlMessages id="send" />
              </button>
            </form>
          </div>
        </div>
      </div>
      <PublishTransaction ref={publishTransaction} />
    </div>
  );
}


export default Send;
