import React from "react";
import { injectIntl } from "react-intl";
import Loading from "components/common/Loading";
import IntlMessages from "util/intlMessages";
import PageTitle from "components/common/PageTitle";
import Session from "util/Session";
import { TxType } from "@bywise/web3";
import { toast } from "react-toastify";
import Contract from "components/common/Contract";
import IPFSIcon from "components/files/IPFSIcon";
import PublishTransaction from "components/transaction/PublishTransaction";

class TokensItem extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      name: '',
      symbol: '',
      address: '',
      url: '',
      icon: '',
      contract: {
        address: '',
        abi: [],
      },
    }
  }

  componentDidMount = () => {
    this.updateContract();
  }

  updateContract = async () => {
    this.setState({ loading: true });

    try {
      const address = this.props.match.params.id;

      const web3 = await Session.getWeb3();
      const infoChain = await Session.getInfoChain();
      const contractOutput = await web3.contracts.getContractByAddress(infoChain.chain, address);
      if (!contractOutput) {
        toast.error('Contract not found');
        return;
      }

      const name = (await web3.contracts.readContract(infoChain.chain, address, 'name', [])).output;
      const symbol = (await web3.contracts.readContract(infoChain.chain, address, 'symbol', [])).output;
      const url = (await web3.contracts.readContract(infoChain.chain, address, 'url', [])).output;
      const icon = (await web3.contracts.readContract(infoChain.chain, address, 'icon', [])).output;

      const contract = {
        address: contractOutput.output.contractAddress,
        abi: contractOutput.output.abi,
      }

      await this.setState({
        address,
        symbol,
        name,
        url,
        icon,
        contract
      });
    } catch (err) {
      toast.error(err.message);
      return;
    }

    await this.setState({ loading: false });
  }

  deploySend = async (contractAddress, name, ...values) => {
    try {
      const web3 = await Session.getWeb3();
      const wallet = Session.getWallet();
      const infoChain = await Session.getInfoChain();

      const tx = await web3.transactions.buildSimpleTx(
        wallet,
        infoChain.chain,
        contractAddress,
        '0',
        TxType.TX_CONTRACT_EXE,
        [{
          method: name,
          inputs: values,
        }]
      );

      this.publishTransaction.publish(tx, {
        type: 'normal',
      }, (async (tx, output) => {

      }))
    } catch (err) {
      toast.error(err.message);
    }
  }

  deployRead = async (contractAddress, name, ...values) => {
    const infoChain = await Session.getInfoChain();
    this.publishTransaction.read(
      infoChain.chain,
      contractAddress,
      name,
      values,
    );
  }

  render() {
    if (this.state.loading) {
      return <Loading loading={true} />
    }
    return (<div className="page-padding">
      <PageTitle title={"tokens.title"} />

      <div className="plr-15">
        <div className="roe-card-style">
          <div className="roe-card-header">
            <span className="hash"># </span>
            <span><IntlMessages id="tokens.title" /></span>
          </div>
          <div className="roe-card-body">
            <div className="form-group row">
              {this.state.icon && <div className="col-sm-4">
                <IPFSIcon className="img-fluid" icon={this.state.icon} />
              </div>}
              <div className="col">
                <label className="col col-form-label hidden-overflow">
                  {"Name: " + this.state.name}
                </label>
                <br />
                <label className="col col-form-label hidden-overflow">
                  {"Address: " + this.state.address}
                </label>
                <br />
                <label className="col col-form-label hidden-overflow">
                  {"Symbol: " + this.state.symbol}
                </label>
                <br />
                <label className="col col-form-label hidden-overflow">
                  {"URL: " + this.state.url}
                </label>
                <br />
                <label className="col col-form-label hidden-overflow">
                  {"Explorer: "}
                  <a href={`${Session.getInfoChain().explorer}/token/${this.state.address}`} target="_blank" rel="noopener noreferrer">
                    {`${Session.getInfoChain().explorer}/token/${this.state.address}`}
                  </a>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div> 

      <div className="plr-15 mt-4">
        <div className="roe-card-style">
          <div className="roe-card-header">
            <span className="hash"># </span>
            <span><IntlMessages id="contract" /></span>
          </div>
          <div className="roe-card-body">
            <Contract
              address={this.state.contract.address}
              abi={this.state.contract.abi}
              send={this.deploySend}
              read={this.deployRead} />
          </div>
        </div>
      </div>
      <PublishTransaction ref={ref => this.publishTransaction = ref} />
    </div>);
  }
}

export default injectIntl(TokensItem);
