import React, { Component } from "react";
import { connect } from "react-redux";
import {
  bywiseIcon, loginBack,
} from "helper/constant";
import IntlMessages from "util/intlMessages";
import TransactionsTable from "components/common/TransactionsTable";
import Session from "util/Session";
import Loading from "components/common/Loading";
import { ProfileLockScreen } from "helper/constant";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { Card, CardBody, CardTitle } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import TokenItem from "components/tokenItem/TokenItem";
import Api from "api/api";
import { injectIntl } from "react-intl";
import { toast } from "react-toastify";
import BigNumber from "bignumber.js";
import IPFSIcon from "components/files/IPFSIcon";
import UploadFiles from "components/files/UploadFiles";
import PublishTransaction from "components/transaction/PublishTransaction";
import { convertBWSToUSD } from "helper/methods";


const Back = {
  backgroundImage: `url(${loginBack})`,
  backgroundPosition: "center center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat"
};
const titleStyle = {
  background: "#563c91",
  color: "white",
  fontWeight: 600
};
const activeColor = {
  color: "#563c91"
};
const profileTabLink = {
  borderBottom: `2px solid`,
  borderColor: "#563c91"
};

class Wallet extends Component {

  constructor(props) {
    super(props);
    this.state = {
      inputPrompt: false,
      name: '',
      bio: '',
      url: '',
      photo: '',
      loading: true,
      wallet: true,
      tokens: false,
      nfts: false,
      history: false,
      info: false,
      txs: [],
      tokensList: [],
      nftsList: [],
      addressInfo: {
        name: '',
        address: '',
        balance: '0',
        photo: '',
        url: '',
        bio: '',
        publicKey: '',
        txCount: '',
      },
    }
  }

  componentDidMount = async () => {
    this.updateInfo()
  }

  updateInfo = async () => {
    const web3 = await Session.getWeb3();
    const wallet = Session.getWallet();
    const infoChain = Session.getInfoChain();
    const info = await web3.wallets.getWalletInfo(wallet.address, infoChain.chain);
    const txCount = await web3.transactions.countTxs({
      chain: infoChain.chain,
      find: { searchBy: 'address', value: wallet.address }
    })

    this.setState({
      name: info.name ? info.name : '',
      bio: info.bio ? info.bio : '',
      url: info.url ? info.url : '',
      photo: info.photo ? info.photo : '',
      chain: infoChain.chain,
      loading: false,
      addressInfo: {
        ...info,
        txCount,
      }
    })
  }

  updateHistory = async () => {
    const web3 = await Session.getWeb3();
    const wallet = Session.getWallet();
    const infoChain = Session.getInfoChain();
    let txs = await web3.transactions.getTxs(infoChain.chain, { find: { searchBy: 'address', value: wallet.address } });
    txs = txs.slice(0, 10)
    this.setState({
      txs: txs,
      loading: false,
    })
  }

  updateTokens = async () => {
    const web3 = await Session.getWeb3();
    const infoChain = Session.getInfoChain();
    const wallet = Session.getWallet();
    const req = await Api.get(`contracts`);
    if (req.error) {
      return;
    }
    const contracts = req.data;
    const tks = [];
    for (let i = 0; i < contracts.length; i++) {
      const contract = contracts[i];

      if (contract.type === 'token') {

        const name = (await web3.contracts.readContract(infoChain.chain, contract.address, 'name', [])).output;
        const symbol = (await web3.contracts.readContract(infoChain.chain, contract.address, 'symbol', [])).output;
        const decimals = (await web3.contracts.readContract(infoChain.chain, contract.address, 'decimals', [])).output;
        const icon = (await web3.contracts.readContract(infoChain.chain, contract.address, 'icon', [])).output;
        let balance = (await web3.contracts.readContract(infoChain.chain, contract.address, 'balanceOf', [wallet.address])).output;
        balance = (new BigNumber(balance)).dividedBy(new BigNumber(10 ** parseInt(decimals))).toFixed();

        tks.push({ name: name, symbol: symbol, amount: balance, price: 0, icon: icon, address: contract.address, last24Hrs: 0, decimals: decimals });
      }
    }
    this.setState({
      tokensList: tks,
      loading: false
    })
  }

  updateNFTs = async () => {
    const web3 = await Session.getWeb3();
    const infoChain = Session.getInfoChain();
    const wallet = Session.getWallet();
    const req = await Api.get(`contracts`);
    if (req.error) {
      return;
    }
    const contracts = req.data;
    const nfts = [];
    for (let i = 0; i < contracts.length; i++) {
      const contract = contracts[i];

      if (contract.type === 'nft') {

        const name = (await web3.contracts.readContract(infoChain.chain, contract.address, 'name', [])).output;
        const balanceOfList = (await web3.contracts.readContract(infoChain.chain, contract.address, 'balanceOfList', [wallet.address])).output;
        const uri = (await web3.contracts.readContract(infoChain.chain, contract.address, 'uri', ['0'])).output;
        const info = (await web3.contracts.readContract(infoChain.chain, contract.address, 'info', ['0'])).output;

        for (let j = 0; j < balanceOfList.length; j++) {
          const tokenId = balanceOfList[j];

          nfts.push({ address: contract.address, tokenId, uri, info, name })
        }
      }
    }
    this.setState({
      nftsList: nfts,
      loading: false
    })
  }

  buyAction = (token) => {
    if (token.symbol === 'BWS') {
      this.props.history.push('/pricing');
    } else {
      window.open('https://www.bloco1.com.br/', '_blank', 'noreferrer');
    }
  }

  seeAction = (nft) => {
    window.open(`${Session.getInfoChain().explorer}/nft/${nft.address}/${nft.tokenId}`, '_blank', 'noreferrer');
  }

  sendAction = (token) => {
    if (token.symbol === 'BWS') {
      this.props.history.push('/send');
    } else {
      this.props.history.push(`/send?symbol=${token.symbol}&address=${token.address}&decimals=${token.decimals}`);
    }
  }

  sendNFTAction = (nft) => {
    this.props.history.push(`/sendNFT?address=${nft.address}&tokenId=${nft.tokenId}&uri=${nft.uri}&name=${nft.name}`);
  }

  activePanel = data => {
    if (data === "history") {
      this.setState({
        history: true,
        tokens: false,
        nfts: false,
        info: false,
        wallet: false,
        loading: true
      })
      this.updateHistory();
    } else if (data === "info") {
      this.setState({
        history: false,
        tokens: false,
        nfts: false,
        info: true,
        wallet: false,
        loading: true
      })
      this.updateInfo()
    } else if (data === "wallet") {
      this.setState({
        history: false,
        tokens: false,
        nfts: false,
        info: false,
        wallet: true,
        loading: true
      })
      this.updateInfo()
    } else if (data === "tokens") {
      this.setState({
        history: false,
        tokens: true,
        nfts: false,
        info: false,
        wallet: false,
        loading: true
      })
      this.updateTokens()
    } else if (data === "nfts") {
      this.setState({
        history: false,
        tokens: false,
        nfts: true,
        info: false,
        wallet: false,
        loading: true
      })
      this.updateNFTs()
    }
  };

  saveName = async (event) => {
    event.preventDefault();
    const web3 = await Session.getWeb3();
    const wallet = Session.getWallet();
    const infoChain = Session.getInfoChain();
    const tx = await web3.transactions.buildConfig.setInfoName(wallet, infoChain.chain, this.state.name);
    this.publishTransaction.publish(tx, {
      type: 'normal'
    }, ((tx, output) => {
      this.setState({ loading: true })
      this.updateInfo()
    }))
  }

  saveBio = async (event) => {
    event.preventDefault();
    const web3 = await Session.getWeb3();
    const wallet = Session.getWallet();
    const infoChain = Session.getInfoChain();
    const tx = await web3.transactions.buildConfig.setInfoBio(wallet, infoChain.chain, this.state.bio);
    this.publishTransaction.publish(tx, {
      type: 'normal'
    }, ((tx, output) => {
      this.setState({ loading: true })
      this.updateInfo()
    }))
  }

  importTokenOrNFT = async (address) => {
    this.setState({ loading: true })
    if (this.state.tokens) {
      await this.importToken(address);
    } else {
      await this.importNFT(address);
    }
    this.setState({ loading: false });
  }

  importToken = async (address) => {
    const web3 = await Session.getWeb3();
    const wallet = Session.getWallet();
    const infoChain = Session.getInfoChain();

    try {
      const name = (await web3.contracts.readContract(infoChain.chain, address, 'name', [])).output;
      await web3.contracts.readContract(infoChain.chain, address, 'symbol', []);
      await web3.contracts.readContract(infoChain.chain, address, 'decimals', []);
      await web3.contracts.readContract(infoChain.chain, address, 'icon', []);
      await web3.contracts.readContract(infoChain.chain, address, 'balanceOf', [wallet.address]);

      const req = await Api.post(`contracts`, {
        chain: infoChain.chain,
        address: address,
        name: name,
        isDeploy: true,
        type: "token",
        code: ''
      });

      if (req.error) {
        toast.error(req.error);
        console.log(req.error)
        return;
      }
      await this.updateTokens();
    } catch (err) {
      toast.error(`Invalid Token Address`);
      console.log(err)
    }
  }

  importNFT = async (address) => {
    const web3 = await Session.getWeb3();
    const infoChain = Session.getInfoChain();

    try {
      const name = (await web3.contracts.readContract(infoChain.chain, address, 'name', [])).output;
      await web3.contracts.readContract(infoChain.chain, address, 'uri', ['0']);
      await web3.contracts.readContract(infoChain.chain, address, 'info', ['0']);

      const req = await Api.post(`contracts`, {
        chain: infoChain.chain,
        address: address,
        name: name,
        isDeploy: true,
        type: "nft",
      });

      if (req.error) {
        toast.error(req.error);
        console.log(req.error)
        return;
      }
      await this.updateNFTs();
    } catch (err) {
      toast.error(`Invalid NFT Address`);
      console.log(err)
    }
  }

  saveURL = async (event) => {
    event.preventDefault();
    const web3 = await Session.getWeb3();
    const wallet = Session.getWallet();
    const infoChain = Session.getInfoChain();
    const tx = await web3.transactions.buildConfig.setInfoUrl(wallet, infoChain.chain, this.state.url);
    this.publishTransaction.publish(tx, {
      type: 'normal'
    }, ((tx, output) => {
      this.setState({ loading: true })
      this.updateInfo()
    }))
  }

  savePhoto = async (event) => {
    event.preventDefault();
    const web3 = await Session.getWeb3();
    const wallet = Session.getWallet();
    const infoChain = Session.getInfoChain();
    const tx = await web3.transactions.buildConfig.setInfoPhoto(wallet, infoChain.chain, this.state.photo);
    this.publishTransaction.publish(tx, {
      type: 'normal'
    }, ((tx, output) => {
      this.setState({ loading: true })
      this.updateInfo()
    }))
  }

  render() {
    const { intl } = this.props;

    return (<div className="page-padding">
      <Loading loading={this.state.loading} />
      {!this.state.loading && <div className="Profile-component">
        <div style={Back} className="background" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-lg-4 col-xl-3 profile-left-shade">
              <div>
                <div className="text-center profile-image">
                  <IPFSIcon className="img-fluid" icon={this.state.addressInfo.photo ? this.state.addressInfo.photo : ProfileLockScreen} />
                  <div>
                    <ul className="list-inline pt-20 theme-color">
                      <li className="list-inline-item">
                        <div style={activeColor}>{this.state.addressInfo.txCount}</div>
                        <div>
                          <strong><IntlMessages id="transactions" /></strong>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="pa-10">
                  <div style={activeColor} className="headline">
                    <IntlMessages id="about" />
                  </div>
                  {this.state.addressInfo.name && <div className="theme-color"> <strong><IntlMessages id="name" />: </strong>{this.state.addressInfo.name} </div>}
                  {this.state.addressInfo.bio && <div className="theme-color"> <strong>Bio: </strong>{this.state.addressInfo.bio} </div>}
                  {this.state.addressInfo.url && <div className="theme-color"> <strong>URL: </strong><a href={this.state.addressInfo.url} target="_blank" rel="noopener noreferrer">{this.state.addressInfo.url}</a> </div>}
                </div>
              </div>
            </div>

            <div className="col-md-12 col-lg-8 col-xl-9 profile-right-shade">
              <div className="profile-header-panel pa-10">
                <div className="headline">{this.state.addressInfo.name}</div>
                <div className="hidden-overflow">
                  <strong><IntlMessages id="address" />: </strong>
                  <a href={`${Session.getInfoChain().explorer}/address/${this.state.addressInfo.address}`} target="_blank" rel="noopener noreferrer">{this.state.addressInfo.address}</a>
                </div>
              </div>

              <div className="pl-10">
                <ul className="list-inline theme-color">
                  <li
                    style={this.state.wallet ? profileTabLink : {}}
                    className="list-inline-item profile-list"
                    onClick={() => this.activePanel("wallet")}
                  >
                    <IntlMessages id="wallet.wallet" />
                  </li>
                  <li
                    style={this.state.tokens ? profileTabLink : {}}
                    className="list-inline-item profile-list"
                    onClick={() => this.activePanel("tokens")}
                  >
                    Tokens
                  </li>
                  <li
                    style={this.state.nfts ? profileTabLink : {}}
                    className="list-inline-item profile-list"
                    onClick={() => this.activePanel("nfts")}
                  >
                    NFTs
                  </li>
                  <li
                    style={this.state.history ? profileTabLink : {}}
                    className="list-inline-item profile-list"
                    onClick={() => this.activePanel("history")}
                  >
                    <IntlMessages id="wallet.history" />
                  </li>
                  <li
                    style={this.state.info ? profileTabLink : {}}
                    className="list-inline-item profile-list"
                    onClick={() => this.activePanel("info")}
                  >
                    <IntlMessages id="wallet.info" />
                  </li>
                </ul>
              </div>
              <div>
                {this.state.wallet && <>
                  {[{ name: this.props.intl.formatMessage({ id: `bywise_token` }), symbol: "BWS", amount: this.state.addressInfo.balance, price: convertBWSToUSD(this.state.addressInfo.balance), icon: bywiseIcon, address: "", last24Hrs: 0, decimals: 0 }].map((token, i) => <div key={`token-bws-${i}`}>
                    <div className="flex-x pb-3">
                      <div className="m-auto">
                        <TokenItem token={token} />
                      </div>
                      <div className="flex-1 m-auto">
                        <button className="ml-3 c-btn c-rounded c-success" onClick={() => this.buyAction(token)}><IntlMessages id="buy" /> <i className="fas fa-dollar-sign mr-10" /></button>
                      </div>
                    </div>
                  </div>)}
                </>}
                {this.state.tokens && <>
                  {this.state.tokensList.map((token, i) => <div key={`token-${i}`}>
                    <div className="flex-x">
                      <div className="m-auto">
                        <TokenItem token={token} />
                      </div>
                      <div className="flex-1 m-auto">
                        {(this.state.chain !== 'mainnet' || token.address) ? <button className="ml-3 c-btn c-rounded c-info" onClick={() => this.sendAction(token)}><IntlMessages id="send" /> <i className="fas fa-arrow-right mr-10" /></button> : <></>}
                      </div>
                    </div>
                    <hr />
                  </div>)}
                  <div className="text-center pb-4">
                    <button className="c-btn ma-5 c-rounded c-btn-dashed c-outline-dark" onClick={() => this.setState({ inputPrompt: true })}>
                      <i className="fas fa-plus mr-10" />
                      <IntlMessages id="wallet.import_token" />
                    </button>
                  </div>
                </>}
                {this.state.nfts && <>
                  <div className="row">
                    {this.state.nftsList.map((nft, i) => <div key={`token-${i}`} className="col-sm-4 mb-4">
                      <Card>
                        <IPFSIcon className="img-fluid" icon={nft.uri} />
                        <CardBody>
                          <CardTitle>ID: {nft.tokenId} - {nft.name}</CardTitle>
                          <button className="c-btn c-rounded c-info mt-2" onClick={() => this.seeAction(nft)}><IntlMessages id="see" />
                            {" "}
                            <i className="fas fa-arrow-right mr-10" />
                          </button>
                          {" "}
                          <button className="c-btn c-rounded c-primary mt-2" onClick={() => this.sendNFTAction(nft)}><IntlMessages id="send" />
                            {" "}
                            <i className="fas fa-arrow-right mr-10" />
                          </button>
                        </CardBody>
                      </Card>
                    </div>)}
                  </div>
                  <div className="text-center pb-4">
                    <button className="c-btn ma-5 c-rounded c-btn-dashed c-outline-dark" onClick={() => this.setState({ inputPrompt: true })}>
                      <i className="fas fa-plus mr-10" />
                      <IntlMessages id="wallet.import_nft" />
                    </button>
                  </div>
                </>}
                {this.state.history && <TransactionsTable txs={this.state.txs} />}
                {this.state.info && <div className="work-card">
                  <div className="Work-header" style={titleStyle}>
                    INFO
                  </div>
                  <div className="work-body">
                    <form className="pa-24">
                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label">Photo</label>
                        <div className="col-sm-8">
                          <div className="input-group">
                            <input
                              type="text"
                              className="form-control"
                              disabled
                              value={this.state.photo}
                              onChange={e => this.setState({ photo: e.target.value })}
                            />
                            <div className="input-group-append">
                              <UploadFiles isPublic onUpload={file => this.setState({ photo: file.ipfs })} />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-2">
                          <button className="w-100 mt-3 c-btn c-outline-primary" onClick={this.savePhoto}>
                            <IntlMessages id="save" />
                          </button>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label"><IntlMessages id="name" /></label>
                        <div className="col-sm-8">
                          <input
                            type="text"
                            className="form-control react-form-input"
                            value={this.state.name}
                            onChange={e => this.setState({ name: e.target.value })}
                          />
                        </div>
                        <div className="col-sm-2">
                          <button className="w-100 mt-3 c-btn c-outline-primary" onClick={this.saveName}>
                            <IntlMessages id="save" />
                          </button>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label">URL</label>
                        <div className="col-sm-8">
                          <input
                            type="text"
                            className="form-control react-form-input"
                            value={this.state.url}
                            onChange={e => this.setState({ url: e.target.value })}
                          />
                        </div>
                        <div className="col-sm-2">
                          <button className="w-100 mt-3 c-btn c-outline-primary" onClick={this.saveURL}>
                            <IntlMessages id="save" />
                          </button>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label"><IntlMessages id="about" /></label>
                        <div className="col-sm-8">
                          <input
                            type="text"
                            className="form-control react-form-input"
                            value={this.state.bio}
                            onChange={e => this.setState({ bio: e.target.value })}
                          />
                        </div>
                        <div className="col-sm-2">
                          <button className="w-100 mt-3 c-btn c-outline-primary" onClick={this.saveBio}>
                            <IntlMessages id="save" />
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>}
              </div>
            </div>
          </div>
        </div>
      </div>}
      <SweetAlert
        input
        showCancel
        show={this.state.inputPrompt}
        cancelBtnBsStyle="default"
        confirmBtnCssClass="sweet-alert-confirm-button"
        cancelBtnCssClass="sweet-alert-cancle-button"
        customClass="sweet-alert-wrapper"
        title={intl.formatMessage({ id: `wallet.import_token` })}
        onConfirm={address => {
          this.setState({ inputPrompt: false })
          this.importTokenOrNFT(address);
        }}
        onCancel={() =>
          this.setState({ inputPrompt: false })
        }
        className="input-sweet-alert"
      ><IntlMessages id="wallet.import_token_text" /></SweetAlert>
      <PublishTransaction ref={ref => this.publishTransaction = ref} />
    </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default compose(
  withRouter,
  injectIntl,
  connect(mapStateToProps, null)
)(Wallet);