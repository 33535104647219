import React, { useState } from "react";
import { Spinner } from "reactstrap";
import Select from "react-select";
import PageTitle from "components/common/PageTitle";
import Loading from "components/common/Loading";
import SweetAlert from 'react-bootstrap-sweetalert'
import IntlMessages from "util/intlMessages";
import { toast } from "react-toastify";
import { injectIntl } from "react-intl";
import UploadFiles from "components/files/UploadFiles";

const Error = props => {
  if (props.error) {
    return (
      <span className="error-msg">
        <IntlMessages id={props.error} />
      </span>
    );
  } else {
    return <span />;
  }
};

const InputText = (props = {
  title: '',
  description: '',
  validation: (value = '') => '',
  inputsForm: {},
  setValue: (value = '') => '',
  setInputsForm: (value = '') => '',
  inputsErrors: {},
  setInputsErrors: (value = '') => '',
  type: 'text',
  id: '',
}) => {
  const [value, setValue] = useState(props.inputsForm[props.id]);

  return (<div className="form-group row">
    <label className="col-sm-4 col-form-label">
      <IntlMessages id={props.title} />
      {props.description && <p><small className="c-text-alternate"><IntlMessages id={props.description} /></small></p>}
    </label>
    <div className="col-sm-8">
      <input
        type="text"
        className="form-control react-form-input"
        value={value}
        onChange={e => {
          let value = e.target.value;
          const inputsFormEdited = { ...props.inputsForm };
          const inputsErrorsEdited = { ...props.inputsErrors };

          if (props.setValue) {
            value = props.setValue(value, inputsFormEdited[props.id]);
          }
          setValue(value);
          inputsFormEdited[props.id] = value;
          props.setInputsForm(inputsFormEdited);

          inputsErrorsEdited[props.id] = props.validation(value);
          props.setInputsErrors(inputsErrorsEdited);
        }}
      />
      <Error error={props.inputsErrors[props.id]} />
    </div>
  </div>)
}

const InputNumber = (props = {
  title: '',
  description: '',
  validation: (value = '') => '',
  inputsForm: {},
  setValue: (value = '') => '',
  setInputsForm: (value = '') => '',
  inputsErrors: {},
  setInputsErrors: (value = '') => '',
  type: 'text',
  id: '',
}) => {
  const [value, setValue] = useState(props.inputsForm[props.id]);

  return (<div className="form-group row">
    <label className="col-sm-4 col-form-label">
      <IntlMessages id={props.title} />
      {props.description && <p><small className="c-text-alternate"><IntlMessages id={props.description} /></small></p>}
    </label>
    <div className="col-sm-8">
      <input
        type="number"
        className="form-control react-form-input"
        value={value}
        onChange={e => {
          let value = e.target.value;
          const inputsFormEdited = { ...props.inputsForm };
          const inputsErrorsEdited = { ...props.inputsErrors };

          if (props.setValue) {
            value = props.setValue(value, inputsFormEdited[props.id]);
          }
          setValue(value);
          inputsFormEdited[props.id] = value;
          props.setInputsForm(inputsFormEdited);

          inputsErrorsEdited[props.id] = props.validation(value);
          props.setInputsErrors(inputsErrorsEdited);
        }}
      />
      <Error error={props.inputsErrors[props.id]} />
    </div>
  </div>)
}

const InputTextArea = (props = {
  title: '',
  description: '',
  initialValue: '',
  lines: 10,
  validation: (value = '') => '',
  inputsForm: {},
  setInputsForm: (value = '') => '',
  inputsErrors: {},
  setInputsErrors: (value = '') => '',
  type: 'text',
  id: '',
}) => {
  const [value, setValue] = useState(props.inputsForm[props.id]);

  return (<div className="form-group row">
    <label className="col-sm-4 col-form-label">
      <IntlMessages id={props.title} />
      {props.description && <p><small className="c-text-alternate"><IntlMessages id={props.description} /></small></p>}
    </label>
    <div className="col-sm-8">
      <textarea
        type="text"
        rows={props.lines ? props.lines : 3}
        className="form-control react-form-input"
        value={value}
        onChange={e => {
          const value = e.target.value;
          setValue(value);
          const inputsFormEdited = { ...props.inputsForm };
          inputsFormEdited[props.id] = value;
          props.setInputsForm(inputsFormEdited);

          const inputsErrorsEdited = { ...props.inputsErrors };
          inputsErrorsEdited[props.id] = props.validation(value);
          props.setInputsErrors(inputsErrorsEdited);
        }}
      />
      <Error error={props.inputsErrors[props.id]} />
    </div>
  </div>)
}

const InputSelect = (props = {
  title: '',
  description: '',
  initialValue: '',
  validation: (value = '') => '',
  inputsForm: {},
  setInputsForm: (value = '') => '',
  inputsErrors: {},
  setInputsErrors: (value = '') => '',
  type: 'select',
  itens: [
    { value: "", label: "" }
  ],
  id: '',
}) => {
  const [value, setValue] = useState(props.inputsForm[props.id]);

  return (<div className="form-group row">
    <label className="col-sm-4 col-form-label">
      <IntlMessages id={props.title} />
      {props.description && <p><small className="c-text-alternate"><IntlMessages id={props.description} /></small></p>}
    </label>
    <div className="col-sm-8">
      <Select
        value={value}
        onChange={newValue => {
          setValue(newValue);
          const inputsFormEdited = { ...props.inputsForm };
          inputsFormEdited[props.id] = newValue.value;
          props.setInputsForm(inputsFormEdited);

          const inputsErrorsEdited = { ...props.inputsErrors };
          inputsErrorsEdited[props.id] = props.validation(newValue.value);
          props.setInputsErrors(inputsErrorsEdited);
        }}
        options={props.itens}
      />
      <Error error={props.inputsErrors[props.id]} />
    </div>
  </div>)
}

const InputSelectButton = (props = {
  title: '',
  description: '',
  initialValue: '',
  buttonLabel: '',
  button: (value = '') => '',
  validation: (value = '') => '',
  inputsForm: {},
  setInputsForm: (value = '') => '',
  inputsErrors: {},
  setInputsErrors: (value = '') => '',
  type: 'select',
  itens: [
    { value: "", label: "" }
  ],
  id: '',
}) => {
  const [value, setValue] = useState(props.inputsForm[props.id]);

  return (<div className="form-group row">
    <label className="col-sm-4 col-form-label">
      <IntlMessages id={props.title} />
      {props.description && <p><small className="c-text-alternate"><IntlMessages id={props.description} /></small></p>}
    </label>
    <div className="col-sm-6">
      <Select
        value={value}
        onChange={newValue => {
          setValue(newValue);
          const inputsFormEdited = { ...props.inputsForm };
          inputsFormEdited[props.id] = newValue.value;
          props.setInputsForm(inputsFormEdited);

          const inputsErrorsEdited = { ...props.inputsErrors };
          inputsErrorsEdited[props.id] = props.validation(newValue.value);
          props.setInputsErrors(inputsErrorsEdited);
        }}
        options={props.itens}
      />
      <Error error={props.inputsErrors[props.id]} />
    </div>
    <div className="col-sm-2">
      <button
        style={{
          backgroundColor: '#563c91',
          color: 'white'
        }}
        type="submit"
        className="btn form-button"
        onClick={(e => {
          e.preventDefault();
          props.button();
        })}>
        <IntlMessages id={props.buttonLabel} />
      </button>
    </div>
  </div>)
}

const InputCheckbox = (props = {
  title: '',
  description: '',
  initialValue: '',
  validation: (value = '') => '',
  inputsForm: {},
  setInputsForm: (value = '') => '',
  inputsErrors: {},
  setInputsErrors: (value = '') => '',
  id: '',
}) => {
  const [value, setValue] = useState(false);

  return (<div className="form-group row">
    <div className="col">
      <div className="pretty p-svg p-curve">
        <input type="checkbox" checked={value} onChange={() => {
          const newValue = !value;
          setValue(newValue);
          const inputsFormEdited = { ...props.inputsForm };
          inputsFormEdited[props.id] = newValue;
          props.setInputsForm(inputsFormEdited);

          const inputsErrorsEdited = { ...props.inputsErrors };
          inputsErrorsEdited[props.id] = props.validation(newValue);
          props.setInputsErrors(inputsErrorsEdited);
        }} />
        <div className="state p-success">
          {/* <!-- svg path --> */}
          <svg className="svg svg-icon" viewBox="0 0 20 20">
            <path
              d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
              style={{ stroke: "white", fill: "white" }}
            ></path>
          </svg>
          <label><IntlMessages id={props.title} /></label>
        </div>
      </div>
      {props.description && <p><label>
        <small className="c-text-alternate"><IntlMessages id={props.description} /></small>
      </label></p>}
      <p>
        <Error error={props.inputsErrors[props.id]} />
      </p>
    </div>
  </div>)
}

const InputFile = (props = {
  title: '',
  description: '',
  isPublic: false,
  validation: (value = '') => '',
  inputsForm: {},
  setValue: (value = '') => '',
  setInputsForm: (value = '') => '',
  inputsErrors: {},
  setInputsErrors: (value = '') => '',
  type: 'text',
  id: '',
}) => {
  const [value, setValue] = useState(props.inputsForm[props.id]);

  return (<div className="form-group row">
    <label className="col-sm-4 col-form-label">
      <IntlMessages id={props.title} />
      {props.description && <p><small className="c-text-alternate"><IntlMessages id={props.description} /></small></p>}
    </label>
    <div className="col-sm-8">
      <div className="input-group">
        <input
          type="text"
          disabled
          className="form-control"
          value={value}
        />
        <div className="input-group-append">
          <UploadFiles isPublic={props.isPublic} onUpload={file => {
            let value = file.ipfs;
            const inputsFormEdited = { ...props.inputsForm };
            const inputsErrorsEdited = { ...props.inputsErrors };

            setValue(value);
            inputsFormEdited[props.id] = value;
            props.setInputsForm(inputsFormEdited);

            inputsErrorsEdited[props.id] = props.validation(value);
            props.setInputsErrors(inputsErrorsEdited);
          }} />
        </div>
      </div>
      <Error error={props.inputsErrors[props.id]} />
    </div>
  </div>)
}

const InputForm = (props = {
  title: '',
  description: '',
  initialValue: '',
  validation: (value = '') => '',
  inputsForm: {},
  setInputsForm: (value = '') => '',
  inputsErrors: {},
  setInputsErrors: (value = '') => '',
  type: '',
  id: '',
}) => {
  if (props.type === 'text') {
    return <InputText {...props} />
  } else if (props.type === 'number') {
    return <InputNumber {...props} />
  } else if (props.type === 'file') {
    return <InputFile {...props} />
  } else if (props.type === 'textarea') {
    return <InputTextArea {...props} />
  } else if (props.type === 'select') {
    return <InputSelect {...props} />
  } else if (props.type === 'select-button') {
    return <InputSelectButton {...props} />
  } else if (props.type === 'checkbox') {
    return <InputCheckbox {...props} />
  } else if (props.type === 'custom') {
    return props.child
  } else {
    return <span>INVALID INPUT TYPE {props.type}</span>
  }
}

const FormNew = (props = {
  service: '',
  form: {
    title: '',
    description: '',
    initialValues: {},
    saveBTN: async (inputsForm) => { },
    deleteBTN: async () => { },
    form: [
      {
        title: '',
        description: '',
        validation: (value) => '',
        type: '',
        id: ''
      }
    ]
  }
}) => {

  const initialValues = {};

  props.form.form.forEach(item => {
    const id = item.id;
    if (props.form.initialValues && props.form.initialValues[id]) {
      initialValues[id] = props.form.initialValues[id]
    } else {
      initialValues[id] = ''
    }
  })

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [inputsForm, setInputsForm] = useState(initialValues);
  const [inputsErrors, setInputsErrors] = useState({});

  const service = props.service;
  const form = props.form;

  return (<div className="page-padding">
    <Loading />
    <SweetAlert
      title="Error"
      onConfirm={() => setError('')}
      show={error !== ''}
      confirmBtnCssClass="sweet-alert-confirm-button"
    >{error}</SweetAlert>

    <PageTitle title={service} />

    <div className="plr-15">
      <div className="roe-card-style">
        <div className="roe-card-header">
          <span className="hash"># </span>
          <span><IntlMessages id={form.title} /></span>
          {form.description && <p><small className="c-text-alternate mt-1 mb-2"><IntlMessages id={form.description} /></small></p>}
        </div>
        <div className="roe-card-body">
          <form>
            {form.form.map((item, index) => <InputForm key={`input-index-${index}`}
              inputsForm={inputsForm}
              setInputsForm={setInputsForm}
              inputsErrors={inputsErrors}
              setInputsErrors={setInputsErrors}
              {...item} />)}


            <div className="pt-19 flex-x align-center" style={{
              justifyContent: 'end'
            }}>
              {form.deleteBTN && <button
                disabled={loading}
                className="btn btn-danger mr-3"
                onClick={async (e) => {
                  e.preventDefault();
                  setLoading(true);
                  let response = window.prompt(props.intl.formatMessage({ id: `delete_warning` }));
                  if (response === 'delete') {
                    await form.deleteBTN();
                  }
                  setLoading(false);
                }}
              >
                {loading && <Spinner />}
                {!loading && <IntlMessages id="delete" />}
              </button>}
              <button
                disabled={loading}
                type="submit"
                className="btn btn-primary"
                onClick={async (e) => {
                  e.preventDefault();
                  setLoading(true);
                  try {
                    let success = true;
                    let error = '';
                    const newInputErros = { ...inputsErrors }
                    for (let i = 0; i < form.form.length; i++) {
                      const item = form.form[i];
                      if (item.validation) {
                        const errorMessage = item.validation(inputsForm[item.id]);
                        if (errorMessage) {
                          newInputErros[item.id] = errorMessage;
                          success = false;
                          error = errorMessage;
                        }
                      }
                    }
                    if (success) {
                      await form.saveBTN(inputsForm);
                    } else {
                      setInputsErrors(newInputErros);
                      toast.error(props.intl.formatMessage({ id: error }));
                    }
                  } catch (err) {
                    toast.error(err.message, {
                      position: "top-right",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                  }
                  setLoading(false);
                }}
              >
                {loading && <Spinner />}
                {!loading && <IntlMessages id="save" />}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>);
}

export default injectIntl(FormNew);
