import React from "react";
import { injectIntl } from "react-intl";
import IntlMessages from "util/intlMessages";
import PageTitle from "components/common/PageTitle";
import UploadFiles from "components/files/UploadFiles";
import Session from "util/Session";
import { toast } from "react-toastify";
import { BywiseHelper, TxType } from "@bywise/web3";
import PublishTransaction from "components/transaction/PublishTransaction";
import Api from "api/api";

const webdoc = `
import BigNumber from 'bignumber.js';
import BywiseUtils, { StorageValue, StorageList } from 'bywise-utils.js';

class Web3Document {

    _name = "<name>";
    _version = "<version>";
    _owner;
    _imutable = new StorageValue(<imutable>);
    _publicInfo = new StorageValue(<public>);
    _permissionedInfo = new StorageValue({});
    _privateInfo = new StorageValue({});
    _icon = new StorageValue('<icon>');
    _data = new StorageList();

    constructor() {
        this._owner = new StorageValue(BywiseUtils.getTxSender());
    }

    name() {  // @view
        return this._name;
    }

    version() {  // @view
        return this._version;
    }

    owner() {  // @view
        return this._owner.get();
    }

    getPublicInfo() {  // @view
        return this._publicInfo.get();
    }

    getPermissionedInfo() {  // @view
        return this._permissionedInfo.get();
    }

    getPrivateInfo() {  // @view
        return this._privateInfo.get();
    }

    getIcon() {  // @view
        return this._icon.get();
    }

    isImutable() {  // @view
        return this._imutable.get();
    }

    setImutable(level, key) {
        this._isOwner();
        this._isImutable();

        const lock = this._imutable.get();
        lock[level + key] = true;
        this._imutable.set(lock);

        BywiseUtils.emit('setImutable', {
            level,
            key
        });
    }

    countData() {  // @view
        return this._data.size();
    }

    getData(index, size) {  // @view
        this._isValidInteger(index);
        this._isValidInteger(size);

        if (parseInt(size) > 1000) throw new Error(\`Web3Document: size cannot be greater than 1000\`);
        if (parseInt(size) < 1) throw new Error(\`Web3Document: invalid size\`);

        index = new BigNumber(index);
        let limit = new BigNumber(size).plus(index).minus(1);
        let dataSize = new BigNumber(this._data.size()).minus(1);

        let data = [];
        while (!(index.isGreaterThan(limit) || index.isGreaterThan(dataSize))) {
            const tx = this._data.get(index);
            data.push(tx);
            index = index.plus(1);
        }
        return data;
    }

    addHash(hash) {
        this._isOwner();
        this._isImutable('data', '');
        this._data.push(hash);
        return true;
    }

    setIcon(icon) {
        this._isOwner();
        this._isImutable('icon', '');
        this._icon.set(icon);
        return true;
    }

    setPrivateInfo(key, value) {
        this._isOwner();
        this._isImutable('private', key);
        const info = this._privateInfo.get();
        info[key] = value;
        this._privateInfo.set(info);

        BywiseUtils.emit('Set Private Info', {
            key,
            value
        });
        return true;
    }

    setPermissionedInfo(key, value) {
        this._isOwner();
        this._isImutable('permissioned', key);
        const info = this._permissionedInfo.get();
        info[key] = value;
        this._permissionedInfo.set(info);

        BywiseUtils.emit('Set Permissioned Info', {
            key,
            value
        });
        return true;
    }

    setPublicInfo(key, value) {
        this._isOwner();
        this._isImutable('public', key);
        const info = this._publicInfo.get();
        info[key] = value;
        this._publicInfo.set(info);

        BywiseUtils.emit('Set Public Info', {
            key,
            value
        });
        return true;
    }

    changeOwner(newOwner) {
        this._isOwner();
        this._isValidAddress(newOwner);

        const oldOwner = this._owner.get();
        this._owner.set(newOwner);

        BywiseUtils.emit('New Owner', {
            oldOwner: oldOwner,
            newOwner: newOwner,
        });
    }

    _isImutable(level, key) { // @private
        const lock = this._imutable.get();
        if (lock['all'] === true) throw new Error('Web3Document: Cant change any value');
        if (lock[level] === true) throw new Error('Web3Document: Cant change value');
        if (lock[level + key] === true) throw new Error('Web3Document: Cant change value');
    }

    _isOwner() { // @private
        if (BywiseUtils.getTxSender() !== this._owner.get()) throw new Error('Web3Document: Only Owner');
    }

    _isValidInteger(value) { // @private
        if (! /^[0-9]{1,36}$/.test(value)) {
            throw new Error(\`Web3Document: invalid value - \${value}\`);
        }
    }

    _isValidHash(hash) { // @private
        if (! /^[0-9a-fA-F]{64}$/.test(hash)) {
            throw new Error(\`Web3Document: invalid hash - \${hash}\`);
        }
    }

    _isValidAddress(value) { // @private
        if (! /^(BWS[0-9A-Z]+[0-9a-fA-F]{0,43})$/.test(value)) {
            throw new Error(\`Web3Document: invalid address - \${value}\`);
        }
    }
}

BywiseUtils.exportContract(new Web3Document());
`;

class TraceabilityNew extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      icon: '',
      name: '',
      parameters: [
        { key: '', value: '', locked: true },
      ],
    }
  }

  publish = async () => {
    const publicInfo = {};
    const lock = {};

    if (this.state.name.trim().length === 0) {
      toast.error(`Invalid Name`);
      return;
    }
    if (!this.state.icon) {
      toast.error(`Invalid Icon`);
      return;
    }
    for (let i = 0; i < this.state.parameters.length; i++) {
      const param = this.state.parameters[i];
      if (param.key.trim().length === 0) {
        toast.error(`Invalid Parameter Key ${i + 1}`);
        return;
      }
      if (param.value.trim().length === 0) {
        toast.error(`Invalid Parameter Value ${i + 1}`);
        return;
      }
      publicInfo[param.key] = param.value;
      if (param.locked) {
        lock['public' + param.key] = true;
      }
    }

    const web3Document = webdoc
      .replace('<name>', this.state.name)
      .replace('<icon>', this.state.icon)
      .replace('<version>', '1.1')
      .replace('<public>', JSON.stringify(publicInfo))
      .replace('<imutable>', JSON.stringify(lock));

    const web3 = await Session.getWeb3();
    const wallet = Session.getWallet();
    const infoChain = Session.getInfoChain();

    try {
      const doc = BywiseHelper.getBWSAddressContract();

      const tx = await web3.transactions.buildSimpleTx(
        wallet,
        infoChain.chain,
        doc,
        '0',
        TxType.TX_CONTRACT,
        { contractAddress: doc, code: web3Document }
      )

      this.publishTransaction.publish(tx, {
        type: 'deploy',
        code: web3Document,
        contractAddress: doc,
      }, (async (tx, output) => {
        const req = await Api.post(`contracts`, {
          chain: tx.chain,
          address: doc,
          name: this.state.name,
          isDeploy: true,
          type: "web3-document",
          code: web3Document
        });
        if (req.error) {
          toast.error(req.error);
        }
        this.props.history.goBack();
      }))
    } catch (err) {
      toast.error(err.message);
    }
  }

  render() {
    return (<div className="page-padding">
      <PageTitle title={"traceability.new"} />

      <div className="plr-15">
        <div className="roe-card-style">
          <div className="roe-card-header">
            <span className="hash"># </span>
            <span><IntlMessages id="traceability.new" /></span>
          </div>
          <div className="roe-card-body">
            <hr />

            <div className="form-group row">
              <label className="col col-form-label">
                <IntlMessages id="traceability.public_desc" />
              </label>
            </div>

            <div className="form-group row">
              <label className="col-sm-4 col-form-label">Name</label>
              <div className="col-sm-8">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control react-form-input"
                    onChange={(e) => {
                      this.setState({ name: e.target.value });
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-4 col-form-label">Icon</label>
              <div className="col-sm-8">
                <div className="input-group">
                  <input
                    type="text"
                    disabled
                    className="form-control"
                    value={this.state.icon}
                  />
                  <div className="input-group-append">
                    <UploadFiles isPublic onUpload={file => this.setState({ icon: file.ipfs })} />
                  </div>
                </div>
              </div>
            </div>

            {this.state.parameters.map((item, index) => <div key={`input-index-${index}`}>
              <div className="form-group row">
                <label className="col-sm-4">
                  <div className="row">
                    <label className="col-2">
                      {index + 1}
                    </label>
                    <input
                      type="text"
                      className="col-10 form-control react-form-input"
                      value={this.state.parameters[index].key}
                      onChange={(e) => {
                        let parameters = this.state.parameters;
                        let value = e.target.value;
                        parameters[index].key = value;
                        this.setState({ parameters });
                      }}
                    />
                  </div>
                </label>
                <div className="col-sm-6">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control react-form-input"
                      value={this.state.parameters[index].value}
                      onChange={(e) => {
                        let parameters = this.state.parameters;
                        parameters[index].value = e.target.value;
                        this.setState({ parameters });
                      }}
                    />
                  </div>
                </div>
                <div className="col-sm-2">
                  <button
                    className="btn btn-primary"
                    onClick={async (e) => {
                      e.preventDefault();
                      let parameters = this.state.parameters;
                      parameters[index].locked = !parameters[index].locked;
                      this.setState({ parameters });
                    }}
                  >
                    {this.state.parameters[index].locked ? <i className="fas fa-lock"></i> : <i className="fas fa-lock-open"></i>}
                  </button>
                  {" "}
                  <button
                    className="btn btn-danger"
                    onClick={async (e) => {
                      e.preventDefault();
                      let parameters = this.state.parameters;
                      parameters = parameters.filter((p, i) => i !== index)
                      this.setState({ parameters });
                    }}
                  >
                    <i className="fas fa-trash"></i>
                  </button>
                </div>
              </div>
            </div>)}

            <div className="pt-19 flex-x align-center" style={{
              justifyContent: 'end'
            }}>
              <button
                className="btn btn-primary"
                onClick={async (e) => {
                  e.preventDefault();
                  let parameters = this.state.parameters;
                  parameters.push({ key: '', value: '', locked: true })
                  this.setState({ parameters });
                }}
              >
                <i className="fas fa-plus"></i>
              </button>
            </div>

            <hr />

            <div className="pt-19 flex-x align-center" style={{
              justifyContent: 'end'
            }}>
              <button
                className="btn btn-primary"
                onClick={async (e) => {
                  e.preventDefault();
                  this.publish();
                }}
              >
                <IntlMessages id="publish" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <PublishTransaction ref={ref => this.publishTransaction = ref} />
    </div>);
  }
}

export default injectIntl(TraceabilityNew);
