import React from "react";
import FeedWrapper from "./feed.style";
import IntlMessages from "util/intlMessages";

const docs = [
  { title: "docs.title1", text: "docs.desc1", url: "https://documentation.bywise.org/pt/blockchain/introduction" },
  { title: "docs.title2", text: "docs.desc2", url: "https://documentation.bywise.org/pt/blockchain/proof_of_immutability" },
  { title: "docs.title3", text: "docs.desc3", url: "https://documentation.bywise.org/pt/web3/smartcontracts/introduction" },
  { title: "docs.title4", text: "docs.desc4", url: "https://documentation.bywise.org/pt/web3/dapps/introduction" },
]

const Feed = () => {
  return (
    <FeedWrapper className="fill-height">
      <div className="card roe-shadow-2 fill-height">
        <div className="card-body">
          <div className="flex-x align-center mb-9">
            <h4 className="mb-0 fs-18 header"><IntlMessages id="dashboard.announcements" /></h4>
          </div>
          <hr />
          {docs.map(doc => <a key={doc.title} href={doc.url} target="_blank" rel="noopener noreferrer" className="flex-x ptb-15 border-bottom cursor-pointer c-text-dark">
            <div className="big-icon">
              <i className="far fa-file-alt" />
            </div>
            <div className="mlr-10 flex-1">
              <p className="fs-15 demi-bold-text"><IntlMessages id={doc.title} /></p>
              <small className=""><IntlMessages id={doc.text} /></small>
            </div>
            <div className="m-auto">
              <i className="fa fa-chevron-right" />
            </div>
          </a>)}
        </div>
      </div>
    </FeedWrapper>
  );
};

export default Feed;
