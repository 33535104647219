import React from "react";
import { injectIntl } from "react-intl";
import Loading from "components/common/Loading";
import IntlMessages from "util/intlMessages";
import PageTitle from "components/common/PageTitle";
import Session from "util/Session";
import UploadFiles from "components/files/UploadFiles";
import DataTable from "react-data-table-component";
import { TxType } from "@bywise/web3";
import IPFSIcon from "components/files/IPFSIcon";
import { toast } from "react-toastify";
import PublishTransaction from "components/transaction/PublishTransaction";
import ButtonAPI from "components/connect_api/ButtonAPI";

const columns = [
  {
    name: 'Index',
    selector: row => row.index,
    grow: 0
  },
  {
    name: 'TxID',
    selector: row => <a href={`${Session.getInfoChain().explorer}/tx/${row.txid}`} target="_blank" rel="noopener noreferrer">{row.txid}</a>,
    grow: 2
  },
];

class TraceabilityItem extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      icon: '',
      name: '',
      address: '',
      parameters: [
        { key: '', value: '', valueDefault: '', tmpLock: false, locked: false },
      ],
      data: []
    }
  }

  componentDidMount = () => {
    this.updateContract();
  }

  updateContract = async () => {
    this.setState({ loading: true });
    const address = this.props.match.params.id;
    const web3 = await Session.getWeb3();
    const infoChain = Session.getInfoChain();

    const name = (await web3.contracts.readContract(infoChain.chain, address, 'name', [])).output;
    const icon = (await web3.contracts.readContract(infoChain.chain, address, 'getIcon', [])).output;
    const publicInfo = (await web3.contracts.readContract(infoChain.chain, address, 'getPublicInfo', [])).output;
    const lock = (await web3.contracts.readContract(infoChain.chain, address, 'isImutable', [])).output;
    let data = (await web3.contracts.readContract(infoChain.chain, address, 'getData', [0, 100])).output;
    data = data.map((d, i) => ({ txid: d, index: i }))

    const parameters = [];
    Object.entries(publicInfo).forEach(([key, value]) => {
      let locked = lock['public' + key] === true;
      parameters.push({
        key, value, valueDefault: value, tmpLock: locked, locked: locked
      })
    })

    await this.setState({
      loading: false,
      address,
      name,
      icon,
      parameters,
      data
    });
  }

  publish = async () => {
    const web3 = await Session.getWeb3();
    const wallet = Session.getWallet();
    const infoChain = Session.getInfoChain();

    try {
      
      let data = [];
      let to = [];
      let amount = [];

      for (let i = 0; i < this.state.parameters.length; i++) {
        const param = this.state.parameters[i];
        if (param.valueDefault !== param.value) {
          to.push(this.state.address);
          amount.push('0');
          data.push({ method: 'setPublicInfo', inputs: [param.key, param.value] });
        }

        if (param.tmpLock && !param.locked) {
          to.push(this.state.address);
          amount.push('0');
          data.push({ method: 'setImutable', inputs: ['public', param.key] });
        }
      }

      if (to.length === 0) {
        return;
      }

      const tx = await web3.transactions.buildSimpleTx(
        wallet,
        infoChain.chain,
        to,
        amount,
        TxType.TX_CONTRACT_EXE,
        data
      )

      this.publishTransaction.publish(tx, {
        type: 'normal',
      }, (async (tx, output) => {
        this.updateContract();
      }))

    } catch (err) {
      toast.error(err.message);
    }
  }

  insertData = async (file) => {
    const web3 = await Session.getWeb3();
    const wallet = Session.getWallet();
    const infoChain = Session.getInfoChain();

    let id = /^[a-fA-F0-9]{64}$/.test(`${file.txId}`) ? file.txId : file.ipfs;
    if(!id) {
      toast.error("Please upload file again");
      return;
    }

    try {
      const tx = await web3.transactions.buildSimpleTx(
        wallet,
        infoChain.chain,
        this.state.address,
        '0',
        TxType.TX_CONTRACT_EXE,
        [{ method: 'addHash', inputs: [id] }]
      );

      this.publishTransaction2.publish(tx, {
        type: 'normal',
      }, (async (tx, output) => {
        this.updateContract();
      }))
    } catch (err) {
      toast.error(err.message);
    }
  }

  render() {
    return (<div className="page-padding">
      {this.state.loading && <Loading loading={true} />}

      {!this.state.loading && <>
        <PageTitle title={"traceability.title"} />

        <div className="plr-15">
          <div className="roe-card-style">
            <div className="roe-card-header">
              <span className="hash"># </span>
              <span><IntlMessages id="traceability.title" /></span>
            </div>
            <div className="roe-card-body">
              <div className="form-group row">
                <div className="col-sm-4">
                  {this.state.icon && <IPFSIcon className="img-fluid" icon={this.state.icon} />}
                </div>
                <div className="col">
                  <label className="col col-form-label hidden-overflow">
                    {"Name: " + this.state.name}
                  </label>
                  <br />
                  <label className="col col-form-label hidden-overflow">
                    {"Address: " + this.state.address}
                  </label>
                  <br />
                  <label className="col col-form-label hidden-overflow">
                    {"Explorer: "}
                    <a href={`${Session.getInfoChain().explorer}/web3-document/${this.state.address}`} target="_blank" rel="noopener noreferrer">
                      {`${Session.getInfoChain().explorer}/web3-document/${this.state.address}`}
                    </a>
                  </label>
                </div>
              </div>

              <hr />

              <div className="form-group row">
                <h4 className="col">
                  <IntlMessages id="traceability.parameters" />
                </h4>
              </div>

              {this.state.parameters.map((item, index) => <div key={`input-index-${index}`}>
                <div className="form-group row">
                  <label className="col-sm-4">
                    {index + 1}
                    {" - "}
                    {this.state.parameters[index].key}
                  </label>
                  <div className="col-sm-8">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        disabled={this.state.parameters[index].locked}
                        value={this.state.parameters[index].value}
                        onChange={(e) => {
                          let parameters = this.state.parameters;
                          parameters[index].value = e.target.value;
                          this.setState({ parameters });
                        }}
                      />
                      <div className="input-group-append">
                        {this.state.parameters[index].locked && <span className="input-group-text">
                          <i className="fas fa-lock"></i>
                        </span>}

                        {!this.state.parameters[index].locked && <button
                          className="btn btn-primary"
                          onClick={async (e) => {
                            e.preventDefault();
                            let parameters = this.state.parameters;
                            parameters[index].tmpLock = !parameters[index].tmpLock;
                            this.setState({ parameters });
                          }}
                        >
                          {this.state.parameters[index].tmpLock ? <i className="fas fa-lock"></i> : <i className="fas fa-lock-open"></i>}
                        </button>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>)}

              <div className="pt-19 flex-x align-center" style={{
                justifyContent: 'end'
              }}>
                <ButtonAPI />
                <button
                  disabled={this.state.loading}
                  className="btn btn-primary"
                  onClick={(e) => {
                    e.preventDefault();
                    this.publish();
                  }}
                >
                  <IntlMessages id="save" />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="plr-15 mt-20">
          <div className="roe-card-style">
            <div className="roe-card-header">
            </div>
            <div className="roe-card-body">
              <div className="row align-center">
                <h4 className="col-sm">
                  <IntlMessages id="traceability.title" />
                </h4>
                <div className="col-flex">

                <ButtonAPI />
                <UploadFiles text={<IntlMessages id="traceability.insertData" />} onUpload={(file) => this.insertData(file)} />
                </div>
              </div>

              <DataTable
                columns={columns}
                data={this.state.data}
                defaultSortFieldId={1}
                pagination
              />
            </div>
          </div>
        </div>
      </>}
      <PublishTransaction ref={ref => this.publishTransaction = ref} />
      <PublishTransaction ref={ref => this.publishTransaction2 = ref} />
    </div>);
  }
}

export default injectIntl(TraceabilityItem);